export default {
    'application.drivers[0]': {
        'model': 'Contact',
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
                // dob is excluded due to IsoDate format being 'MM/DD/YYYY'
                // can not use gender as auto-wizard do not use non-binary
                'properties': [ 'firstName', 'lastName', 'phone', 'email' ],
            },
            'fromProfile' : {
                'enable': true,
                'overwrite': false,
                // dob is excluded due to IsoDate format being 'MM/DD/YYYY'
                // can not use gender as auto-wizard do not use non-binary
                'properties': [ 'firstName', 'lastName', 'phone', 'email' ],
            },
            'profilePath': '$',
        },
    },
};
