// functions
export { default as validateV9 } from './functions/validate';
export { default as extractV9Metadata } from './functions/extractMetadata';
export { default as transformInsAutoVersion8To9 } from './functions/migrate';
export { default as migrateFromV8toV9 } from './functions/migrate';

// Base document
export { default as InsAutoDocument } from './models/InsAutoDocument';

// domain models
export { default as AccidentClaim } from './models/AccidentClaim';
export { default as Coverage } from './models/Coverage';
export { default as Driver } from './models/Driver';
export { default as InsAutoApplication } from './models/Application';
export { default as LicenceSuspension } from './models/LicenceSuspension';
export { default as PolicyCancellation } from './models/PolicyCancellation';
export { default as TrafficConviction } from './models/TrafficConviction';
export { default as Vehicle } from './models/Vehicle';
export { Quote } from './definitions/InsCommon';

// definitions
export { default as AccidentClaimTypeValues } from './definitions/AccidentClaimTypeValues';
export { default as CollisionValues } from './definitions/CollisionValues';
export { default as ComprehensiveValues } from './definitions/ComprehensiveValues';
export { default as DailyKMValues } from './definitions/DailyKMValues';
export { default as DriverRelationValues } from './definitions/DriverRelationValues';
export { default as LiabilityValues } from './definitions/LiabilityValues';
export { default as LicenceSuspensionTypeValues } from './definitions/LicenceSuspensionTypeValues';
export {
    LicenceTypeValuesAlberta,
    LicenceTypeValuesOntario,
    LicenceTypeValuesQuebec,
} from './definitions/LicenseTypeValues';
export { default as MaritalStatusValues } from './definitions/MaritalStatusValues';
export { default as OccupationValues } from './definitions/OccupationValues'; // Update in INS-2718
export { default as OwnLeaseValues } from './definitions/OwnLeaseValues';
export { default as ParkedOvernightValues } from './definitions/ParkedOvernightValues';
export { default as PolicyCancellationReasonValues } from './definitions/PolicyCancellationReasonValues';
export { default as SchemaOptions } from './definitions/SchemaOptions';
export { default as SupportedProvinces } from './definitions/SupportedProvinces';
export { default as TrafficConvictionTypeValues } from './definitions/TrafficConvictionTypeValues';
export { default as UserPurposeValues } from './definitions/UserPurposeValues';
export { default as VehicleConditionValues } from './definitions/VehicleConditionValues';
export { default as VehiclePurposeValues } from './definitions/VehiclePurposeValues';
export { default as YearlyKMValues } from './definitions/YearlyKMValues';


// common definitions
export { GENDERS } from './definitions/Common';
export {
    ContactTimes,
    QUOTE_STATUSES,
    SaveStatuses,
    UserIntentValues,
} from './definitions/InsCommon';

export const VERSION = 9;
