/**
 * Parse a fetch response into JSON
 * @param {string} response response
 * @returns {object} Parsed JSON of the fetch response
 */
async function getJSONFromResponse(response) {
    if (response.bodyUsed) {
        throw new Error('[getJSONFromResponse] response.text() can only be called once.');
    }

    // Use .text() to allow us to dump the response to console if it fails parsing as JSON.
    const text = await response.text();

    try {
        return JSON.parse(text);
    } catch {
        throw new InvalidJsonResponseError(`[getJSONFromResponse] Response couldn't be parsed as JSON. Received: ${text}`, text);
    }
}

class InvalidJsonResponseError extends Error {
    constructor(message, rawBody) {
        super(message);
        this.rawBody = rawBody;
    }
}

export { InvalidJsonResponseError };
export default getJSONFromResponse;
