import { types } from 'mobx-state-tree';
import dayjs from 'dayjs';

import isValidDate from '../functions/isValidDate';


const IsoDate = types.custom({
    name: 'IsoDate',
    fromSnapshot(value) {
        if (value != null) {
            return dayjs(value).toISOString(); // ISO 8601 stored in zulu
        }
    },
    toSnapshot(parsedValue) {
        return parsedValue;
    },
    isTargetType(value) {
        return typeof value === 'string' && isValidDate(value);
    },
    getValidationMessage(value) {
        if (isValidDate(value)) return '';
        return `${value} is not a parseable date. Please use a unix timestamp, Date object or ISO 8601 string`;
    },
});

export default IsoDate;
