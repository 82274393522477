import { BORDER_COLOUR_CLASS_MAP } from '../definitions/Colours';

/**
* @param {string} colour colour as a hex value
* @returns {string} .rh-border-color-* colour utility class
*/
function getBorderColourClassName(colour) {
    const matchingColourObject = BORDER_COLOUR_CLASS_MAP
        .find(colourObject => colourObject.value === colour);

    return matchingColourObject.className;
}

export default getBorderColourClassName;
