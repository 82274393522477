import queryString from 'query-string';

import Config from '../definitions/Config';
import getVisitorId from './getVisitorId';
import { Experiments } from './getExperimentSegment';

import { getAllEvents } from './noticeErrorSaveEvent';


/**
 * Eeek! Something bad happened, the sky is falling. Tell everyone: console.error() and newrelic.noticeError()
 * @param {Object} error some error object
 * @param {Object} [moreInfo] key/value object with additional context
 * @returns undefined
 *
 * @example noticeError(new RangeError(`[someFunctionName] -- unexpected value for thingType: “${thingType}”`),
 *      {
 *          documentId: context.activeDocument.id,
 *          email: context.activeDocument.occupant?.email,
 *          exception: ex,
 *      });
 */

function noticeError(error, moreInfo) {
    let extendedInfo = moreInfo;

    if (typeof newrelic !== 'undefined') {
        // Augment with any relevant details before this happened
        extendedInfo = {
            ...extendedInfo,
            noticeErrorSaveEvents: getAllEvents(),
            visitorId: getVisitorId(),
            // Include any experiments enabled on the page + their segments
            //  We’re using .toJSON because it’s an observable map
            ...(Experiments
                ? { experiments: Experiments.toJSON() }
                : {}
            ),
            // Include policy name if our config has it defined
            ...(Config.NR_POLICY_NAME
                ? { newRelicPolicyName: Config.NR_POLICY_NAME }
                : {}
            ),

            ...(typeof window !== 'undefined' && getPropertiesThatNeedWindow()),
        };

        // eslint-disable-next-line jsx-control-statements/jsx-jcs-no-undef
        newrelic?.noticeError(error, extendedInfo);
    }

    // eslint-disable-next-line no-console
    console.error(error, extendedInfo);   // the error object will be .toString()’d
}

/**
 * Gather properties that required a Window object.
 * Note: as of Google Chrome 122 the dev tools _debugger_ gets confused about the contents of the window
 * within this function and may lie to you about it. The code itself performs as expected.
 * @return {Object|undefined}
 */
function getPropertiesThatNeedWindow() {
    if (typeof window !== 'undefined') {
        // __NEXT_DATA.page returns the page like '[[...wordpressCorporateEN]]'
        //  for our uses, we only need the 'wordpressCorporate' piece
        const nextJsPageName = window.__NEXT_DATA__?.page
            ?.replace(/[\W_]+/g, '')   // Remove non-alphabetic characters
            ?.replace(/(EN|FR)$/, ''); // Remove EN or FR at the end of the string

        const queryParams = queryString.parse(window.location.search);

        return {
            nextJsPageName,

            ...(window.heap && {
                heapIdentity: window.heap.identity,
                heapId: window.heap.userId,
            }),

            queryParams,

            pageTimestamp: Config.DOCKER_TIMESTAMP,
            metaTag: Config.DOCKER_TAG,
            gitHash: Config.GIT_COMMIT,
        };
    } else {
        return undefined;
    }
}


export default noticeError;
