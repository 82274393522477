const BASE_UNIT = 'rem';
const BASE_VALUE = 1;


export default {
    SPACING: {
        EIGHTH:                 `${BASE_VALUE*0.125}${BASE_UNIT}`,
        QUARTER:                `${BASE_VALUE*0.25}${BASE_UNIT}`,
        HALF:                   `${BASE_VALUE*0.50}${BASE_UNIT}`,
        THREE_QUARTERS:         `${BASE_VALUE*0.75}${BASE_UNIT}`,
        SEVEN_EIGHTHS:          `${BASE_VALUE*0.875}${BASE_UNIT}`,

        ONE:                    `${BASE_VALUE}${BASE_UNIT}`,

        ONE_AND_AN_EIGHTH:      `${BASE_VALUE*1.125}${BASE_UNIT}`,
        ONE_AND_A_QUARTER:      `${BASE_VALUE*1.25}${BASE_UNIT}`,
        ONE_AND_A_HALF:         `${BASE_VALUE*1.50}${BASE_UNIT}`,
        ONE_AND_THREE_QUARTERS: `${BASE_VALUE*1.75}${BASE_UNIT}`,
        TWO:                    `${BASE_VALUE*2.00}${BASE_UNIT}`,
        TWO_AND_A_HALF:         `${BASE_VALUE*2.50}${BASE_UNIT}`,
        THREE:                  `${BASE_VALUE*3.00}${BASE_UNIT}`,
        FOUR:                   `${BASE_VALUE*4.00}${BASE_UNIT}`,
        FIVE:                   `${BASE_VALUE*5.00}${BASE_UNIT}`,
        SIX:                    `${BASE_VALUE*6.00}${BASE_UNIT}`,
        SEVEN:                  `${BASE_VALUE*7.00}${BASE_UNIT}`,
        EIGHT:                  `${BASE_VALUE*8.00}${BASE_UNIT}`,
        TEN:                    `${BASE_VALUE*10.00}${BASE_UNIT}`,
    },

    FONTS: {
        '3XS': `${BASE_VALUE*0.625}${BASE_UNIT}`,
        '2XS': `${BASE_VALUE*0.75}${BASE_UNIT}`,
        XS: `${BASE_VALUE*0.875}${BASE_UNIT}`,

        S: `${BASE_VALUE}${BASE_UNIT}`,

        M: `${BASE_VALUE*1.125}${BASE_UNIT}`,
        L: `${BASE_VALUE*1.25}${BASE_UNIT}`,
        XL: `${BASE_VALUE*1.5}${BASE_UNIT}`,
        '2XL': `${BASE_VALUE*1.625}${BASE_UNIT}`,
        '3XL': `${BASE_VALUE*2}${BASE_UNIT}`,
        '4XL': `${BASE_VALUE*2.375}${BASE_UNIT}`,
        '5XL': `${BASE_VALUE*3}${BASE_UNIT}`,
        '6XL': `${BASE_VALUE*3.75}${BASE_UNIT}`,
    },

    ICONS: {
        '3XS': `${BASE_VALUE * 0.75}${BASE_UNIT}`,
        '2XS': `${BASE_VALUE}${BASE_UNIT}`,
        XS: `${BASE_VALUE * 1.5}${BASE_UNIT}`,
        S: `${BASE_VALUE * 2}${BASE_UNIT}`,
        M: `${BASE_VALUE * 2.5}${BASE_UNIT}`,
        L: `${BASE_VALUE * 3.0}${BASE_UNIT}`,
        XL: `${BASE_VALUE * 4.0}${BASE_UNIT}`,
        '2XL': `${BASE_VALUE * 5.0}${BASE_UNIT}`,
        '3XL': `${BASE_VALUE * 8.0}${BASE_UNIT}`,
        '4XL': `${BASE_VALUE * 12}${BASE_UNIT}`,
        '5XL': `${BASE_VALUE * 16}${BASE_UNIT}`,
        '6XL': `${BASE_VALUE * 22}${BASE_UNIT}`,
    },

    LINE_HEIGHT: {
        XS: 1,
        S: 1.2,
        M: 1.5,
        L: 1.75,
        XL: 2,
    },
};
