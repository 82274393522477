export { default as validate } from './functions/validate';
export { default as extractMetadata } from './functions/extractMetadata';
export { default as SchemaOptions } from './definitions/SchemaOptions';

// Base Document
export { default as InsCondoDocument } from './models/InsCondoDocument';

// Common definitions
export { STREET_TYPES } from './definitions/Common';

// Common Insurance Definition Values
// Common Definition Values
export {
    FirehallValues,
    FireHydrantValues,
    HeatingValues,
    HowManyMortgagesValues,
    UserIntentValues,
    JobStatusValues,
    OccupyingValues,
    OffenseEditorNameValues,
    PropertyPurposeValues,
    PropertyTypeValues,
    YearsAtMostRecentAddressValues,
    YearsWithActiveInsuranceValues,
} from './definitions/InsCommon';

export { default as SUPPORTED_PROVINCES } from './definitions/SupportedProvinces';

export const VERSION = 1;
