// Functionality for /src/components/Header.jsx
// Provided as a separate file to allow for static rendering.

import { ElementAttributes } from '@ratehub/base-ui';


let isMobile = undefined;
let header = undefined;
const MOBILE_BREAKPOINT = '1023px';
const KEYCODE_ESC = 27;

function initializeHeader() {
    header = document.getElementById(ElementAttributes.HEADER_CONTAINER_ID);

    if (!header) {
        // eslint-disable-next-line no-console
        console.error('Navigation menu error: could not find header menu container in DOM');
        return null;
    }

    // Breakpoint matches min-width used in styles
    const mq = window.matchMedia(`(min-width: ${MOBILE_BREAKPOINT})`);
    handleScreenSizeChange(mq);
    mq.addListener(handleScreenSizeChange);

    function handleScreenSizeChange(mq) {
        isMobile = !mq.matches;
    }

    // Handle closing the menus with esc key
    // NOTE: This solution does not work in Safari since non-inputs do not gain focus
    //  and so the event listener will not fire.
    header.addEventListener('keydown', (e) => {
        if (e.keyCode === KEYCODE_ESC) {
            closeAllMenus();
        }
    });

    // Hamburger button
    const hamburger = header.querySelector(`#${ElementAttributes.PRIMARY_NAV_HAMBURGER_ID}`);
    if (hamburger) {
        hamburger.addEventListener('click', handleClickHamburger);
    }

    //@TODO will likely need to add forEach support for NodeLists for IE
    // see polyfill here: https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach

    // Top-level menu items
    const topLevelItems = header.querySelectorAll('li:not(.landingPageButton) > .headerLink[data-menulevel="1"]');
    topLevelItems.forEach(item => {
        item.addEventListener('click', handleTopLevelItemClick);
    });

    // Second-level menu-items
    const secondLevelItems = header.querySelectorAll('li:not(.landingPageButton) > .headerLink[data-menulevel="2"]');
    secondLevelItems.forEach(item => {
        item.addEventListener('click', handleSecondLevelItemClick);
    });

    // More/less link (Third-level menu items)
    const expandableMenus = header.querySelectorAll('.isExpandable');
    expandableMenus.forEach(list => {
        // View more/less buttons
        const viewMoreButton = list.querySelector('.viewMoreButton');

        if (viewMoreButton) {
            viewMoreButton.addEventListener('click', handleViewMoreButton);
        }
    });

    // Back button
    const backButtons = header.querySelectorAll('.goBack > .backButton');
    backButtons.forEach(item => {
        item.addEventListener('click', handleBack);
    });
}

function closeAllMenus() {
    // @TODO: This really only handles closing the mega menu. We still need
    // support closing the mobile menu, which involves a few things, such as
    // resetting the state of the flyout menu, closing the top-level menu AND
    // resettng the hamburger.

    // Send focus to another link *outside* of the main menu. Logo anchor is
    // just a convenient place to send it because we know it's pretty much
    // always there and it's a good starting point for a user should they
    // wish to start tabbing through the main menu again.
    const logoAnchor = header.querySelector('.logoAnchor');

    if (logoAnchor) {
        logoAnchor.focus();
    }
}

function handleClickHamburger() {
    if (!isMobile) {
        return;
    }

    const navMenu = header.querySelector('nav');
    const userAccountMenuButton = header.querySelector('#userAccountButton');

    if (navMenu) {
        // Only allow opening the mobile menu when scrolled to very top otherwise
        // position: fixed mobile menu will not appear directly under the header :(
        window.scrollTo(0, 0);

        navMenu.classList.toggle('isOpen');

        // Avoid two vertical scrollbars on mobile menu + body. Also makes
        // any vertical scroll action affect the menu only (and not the document)
        // while the menu is open.
        if (navMenu.classList.contains('isOpen')) {
            document.body.style.setProperty('overflow', 'hidden');
        } else {
            document.body.style.removeProperty('overflow');
        }

        if (userAccountMenuButton) {
            userAccountMenuButton.classList.toggle('hidden');
        }
    }
}

// Show / hide "slideover" menu
function handleTopLevelItemClick(e) {
    if (!isMobile) {
        return;
    }

    e.preventDefault();

    const headerNav = header.querySelector('.headerNavigationMenu');
    const parentListItem = e.target.closest('li');
    const subMenu = parentListItem.querySelector('.subMenu');

    if (subMenu) {
        subMenu.classList.toggle('isOpen');

        if (headerNav) {
            // headerNav should not be scrollable when fly-out menu is open.
            // Also resets scroll position of headerNav, as the fly-out menu
            // is positioned against this element and will be positioned
            // weird if headerNav is also scrolled.
            headerNav.scrollTop = 0;
            headerNav.classList.toggle('isMobileScrollable');
        }
    }
    else {
        const parentAnchor = e.target.closest('a');
        window.top.location.assign(parentAnchor.href);
    }
}

// Show/hide "accordion" menu
function handleSecondLevelItemClick(e) {
    // REQUIREMENT: A link element is used on both mobile and desktop for
    // second level items but we don't actually allow following those links.
    // They are links and not plain text in order to provide additional SEO value.
    e.preventDefault();

    if (!isMobile) {
        return;
    }

    const parentListItem = e.target.closest('li');

    if (parentListItem) {
        parentListItem.classList.toggle('isOpen');
    }
}

// Show/hide extra items above max
function handleViewMoreButton(e) {
    const expandableList = e.target.closest('.isExpandable');

    if (expandableList) {
        expandableList.classList.toggle('isOpen');

        const moreLabel = expandableList.querySelector('.viewMoreButton .labelMore');
        const lessLabel = expandableList.querySelector('.viewMoreButton .labelLess');

        if (moreLabel && lessLabel) {
            // @TODO: Element.toggleAttribute() is unavailable in IE11. Should be a
            // simple polyfill for it.
            moreLabel.toggleAttribute('hidden');
            lessLabel.toggleAttribute('hidden');
        }
    }
}

// Hide the current "slideover" menu
function handleBack(e) {
    // Close the slide-over menu if it's open
    e.target.closest('.subMenu').classList.toggle('isOpen');

    const headerNav = header.querySelector('.headerNavigationMenu');
    if (headerNav) {
        headerNav.classList.toggle('isMobileScrollable');
    }
}

export default initializeHeader;
