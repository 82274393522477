/*
    As of Chrome 88 or so (Common Locale Data Repository 38.0) fr-CA data is incorrectly being formatted
    with a trailing non-breaking space and then "CA" after the dollar sign. This monkeypatch removes
    the unwanted suffix.

    https://bugs.chromium.org/p/chromium/issues/detail?id=1169730#c12
    https://unicode-org.atlassian.net/browse/CLDR-14440 -- recognized as a bug to eventually be fixed
 */

function installCustomIntlNumberFormat() {
    if (!testForFrCaCLDRBug()) return;

    const intlNumberFormat = Intl.NumberFormat;

    Intl.NumberFormat = function() {
        if (arguments[0] === 'fr-CA') {
            const result = intlNumberFormat.apply(null, arguments);
            if (result.format) {
                return {
                    get format() {
                        return function() {
                            return result.format.apply(null, arguments).replace(/\$.*CA/, '$');
                        };
                    },
                    formatToParts: result.formatToParts,
                    resolvedOptions: result.resolvedOptions,
                };
            }
            return result;
        } else {
            return intlNumberFormat.apply(null, arguments);
        }
    };

    Intl.NumberFormat.supportedLocalesOf = intlNumberFormat.supportedLocalesOf;
}

function testForFrCaCLDRBug() {
    return typeof Intl !== 'undefined'
        && Intl.NumberFormat
        && Intl.NumberFormat('fr-CA', {
            style:'currency',
            minimumIntegerDigits:1,
            currency: 'CAD',
            currencyDisplay: 'symbol',
        }).format(43) === '43,00 $ CA';
}

export default installCustomIntlNumberFormat;
