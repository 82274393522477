// Functions
export { default as validateV9 } from './functions/validate';
export { default as extractV9Metadata } from './functions/extractMetadata';
export { default as migrateFromV8toV9 } from './functions/migrate';

// Base Document
export { default as InsHomeDocument } from './models/InsHomeDocument';

// Domain Models - To be deprecated in V9
export { default as InsHomeApplication } from './models/Application';
export { default as Applicant } from './models/Applicant';
export { default as Construction } from './models/Construction';
export { default as Occupant } from './models/Occupant';
export { default as Inquiry } from './models/Inquiry';
export { default as OtherInformation } from './models/OtherInformation';
export { default as Property } from './models/Property';
export { default as Quote } from './models/Quote';

export { Address } from './definitions/Common';

export {
    // Common Definitions
    QUOTE_STATUSES,
    SaveStatuses,
    ContactTimes,
    HomeCoverage as Coverage,

    // Common Definitions Values
    BuiltInStoreyValues,
    BurglarAlarmValues,
    CancelRefusalClaimReasonValues,
    ClaimsDeductibleValues,
    ConstructionTypeValues,
    DamageClaimTypeValues,
    ElectricPanelValues,
    ElectricalAmpsValues,
    ElectricalWiringValues,
    ExteriorFinishValues,
    FinishedBasementValues,
    FireAlarmValues,
    FireHydrantValues,
    FirehallValues,
    FuelTypeValues,
    GarageTypeValues,
    HeatingValues,
    HowManyMortgagesValues,
    InquiryTypeValues,
    JobStatusValues,
    NumberOfStories,
    NumFireExtinguisherValues,
    NumSmokeDetectorValues,
    NumberOfUnitsValues,
    OccupyingValues,
    OffenseEditorNameValues,
    PersonalLiabilityValues,
    PipingTypeValues,
    PoolTypeValues,
    PropertyPurposeValues,
    PropertyTypeValues,
    RoofTypeValues,
    UserIntentValues,
    UserPurposeValues,
    WaterTankValues,
    WhichFloorValues,
    YearPlumbingLastUpdatedValues,
    YearRoofLastUpdatedValues,
    YearWiringLastUpdatedValues,
    YearsAtMostRecentAddressValues,
    YearsWithActiveInsuranceValues,
} from './definitions/InsCommon';

export { default as SupportedProvinces } from './definitions/SupportedProvinces';
export { default as SchemaOptions } from './definitions/SchemaOptions';

export const VERSION = 9;
