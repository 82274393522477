import React, { useState } from 'react';
import { observable } from 'mobx';

import { useBannerContext } from '../components/BannerProvider';
import MessageBanner from '../components/MessageBanner';

import { MessageBannerVariants } from '../definitions/MessageBannerDefinitions';


function useMessageBannerContext() {
    const bannerContext = useBannerContext();

    if (!bannerContext) {
        throw new Error('[useMessageBannerContext] BannerProvider is required as a context provider');
    }

    const [ state ] = useState(() => observable({
        isVisible: false,

        get areAnyBannersVisible() {
            return bannerContext.isVisible;
        },

        /**
         * Show a message banner.
         * 
         * @param {object} options
         * @param {string} options.variant - message banner variant is required
         * @param {string} options.message - message to display
         * @param {string} options.callerName - name of the caller is required; so that only the caller can hide the banner; if another caller opens a banner, it will overwrite the current banner however
         * @param {string} [options.iconKey] - optional icon key for the message banner 
         * @param {number} [options.duration] - if not provided, the banner will persist until dismissed
         * @param {boolean} [options.isSticky] - if true, the banner will be sticky
         * @param {boolean} [options.isCloseVisible] - defaults to true; if false, user cannot dismiss the banner
         * @param {function} [options.onHide] - callback when banner is hidden
         */
        show({ variant, message, callerName, iconKey, duration, isSticky, isCloseVisible, onHide, ...otherProps }) {
            if (!Object.values(MessageBannerVariants).includes(variant)) {
                throw new RangeError(`[useMessageBannerContext] variant expected to be one of ${JSON.stringify(Object.values(MessageBannerVariants))} but received "${variant}"`);
            }

            state.isVisible = true;

            bannerContext.show({
                callerName,
                children: (
                    <MessageBanner
                        iconKey={iconKey ?? getIconKey(variant)}
                        variant={variant}
                        message={message}
                        onClose={isCloseVisible ? () => state.hide(callerName) : undefined}
                        {...otherProps}
                    />
                ),
                duration,
                isSticky,
                // Hide can be called when the duration is completed
                onHide: () => {
                    state.message = false;
                    onHide?.();
                },
            });
        },
        /**
         * Hide a message banner.
         * 
         * @param {string} callerName - name of the caller is required; so that only the caller can hide the banner
         */
        hide(callerName) {
            if (bannerContext.callerName === callerName) {
                state.isVisible = false;
                bannerContext.hide(callerName);
            }
        },
    }));

    return state;
}

function getIconKey(variant) {
    switch (variant) {
        case MessageBannerVariants.ERROR:
        case MessageBannerVariants.WARNING:
            return 'exclamation-mark';
        case MessageBannerVariants.SUCCESS:
            return 'checkmark';
        default:
            return null;
    }
}

export default useMessageBannerContext;
