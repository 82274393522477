import SiteSettings from '../src/definitions/SiteSettings';


// Functionality for /src/components/Footer.jsx
// Provided as a separate file to allow for static rendering.

let footer = undefined;

function initializeFooter() {
    footer = document.querySelector(`#${SiteSettings.FOOTER_CONTAINER_ID}`);

    if (!footer) {
        return null;
    }

    const popularContentButton = footer.querySelector(`[data-button-action="${SiteSettings.TOGGLE_BUTTON_POPULAR_CONTENT}"]`);

    if (popularContentButton) {
        popularContentButton.addEventListener('click', handlePopularContentButtonClick);
    }
}

// Show / hide Popular Content menu
function handlePopularContentButtonClick(evt) {
    const popularContentMenuContainer = footer.querySelector(`#${SiteSettings.POPULAR_CONTENT_CONTAINER_ID}`);

    if (popularContentMenuContainer) {
        // Show/hide the menu.
        popularContentMenuContainer.classList.toggle('hidden');

        // Show/hide the dot.
        const popularContentDot = evt.target.previousElementSibling;

        if (popularContentDot) {
            popularContentDot.classList.toggle('hidden');
        }
    }
}

export default initializeFooter;
