import { useContext, createContext } from 'react';


const SessionStoreContext = createContext();

function useSessionStore() {
    return useContext(SessionStoreContext);
}

export default useSessionStore;
export { SessionStoreContext };
