import Config from '../definitions/Config';

/**
 * 
 * @param { string } message
 * @param { { [key: string]: any } } extra any extra data you want to include in log
 * @param { 'info'|'warn'|'error'|'debug'} level info, warn, error, debug
 * @returns { Promise<Response> | null }
 */
async function sendLog(message, extra = {}, level = 'info') {
    try {
        return await fetch(Config.SEND_LOG_INGEST_URL, {
            method: 'POST',
            ...(document?.cookie && { cookies: document.cookie }),
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                message,
                ...extra,
                level,
            }),
        });
    } catch {
        // ignore the error
        return null;
    }
}

export default sendLog;
