export default {
    'application.contact.email' : {
        'read': true,
        'write': true,
        'mutate': true,
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
            },
            'profilePath': 'email',
        },
    },
    'events' : {
        'read' : [ 'admin' ],
        'write' : true,
        'mutable': [ 'admin' ],
    },
};
