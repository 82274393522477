export default {
    'application.occupant': {
        'model': 'Contact',
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
                'properties': [ 'firstName', 'lastName', 'phone', 'email' ],
            },
            'fromProfile' : {
                'enable': true,
                'overwrite': false,
                'properties': [ 'firstName', 'lastName', 'phone', 'email' ],
            },
            'profilePath': '$',
        },
    },
};
