import { PropagationModels } from '../../common/v1';

import convertMstToJsonSchema from './convertMSTToJSONSchema.js';
import isEmptyValue from './isEmptyValue';

/**
 * Generates new snapshot with the addition of profile data
 * Copy profile value(s) and paste value(s) to document
 * schemaOptions provides the mapping of which profile paths to copy value from, which doc paths to paste value to
 * Supports two modes - Non-Model propagation / Model propagation
 * Please find readme.md under document package for explanation/examples
 * Also addProfileDataToDocumentSnapshot.test file under /utilities/test folder for more detailed examples
 *
 * CAUTION
 * - If snapshot doesn't already have path(s) defined in schemaOptions, profile data won't be copied
 * - This is because we can not construct all in-between paths in snapshot without knowing proper values required for them
 *
 * NOTE - considering immutability of original snapshot
 * - original snapshot is shallow-copied
 * - modified paths in new snapshot are newly constructed to avoid the mutation of the original snapshot
 * @param {object} snapshot
 * @param {object} profile
 * @param {object} schemaOptions
 * @returns new snapshot
 */
function addProfileDataToDocumentSnapshot(snapshot, profile, schemaOptions) {
    if (schemaOptions == null || Object.keys(schemaOptions) === 0) {
        return snapshot;
    }

    const propagationDefinitions = getPropagationRules(schemaOptions, !!profile.uid);

    let newSnapshot = snapshot;

    for (const definition of propagationDefinitions) {
        // model propagation
        // - find properties in model and propagate them
        if (definition.model) {
            const { isValueFound, value: sourceUnderProfilePath } = getValueOnPath(profile, definition.profilePath);
            if (isValueFound && isObject(sourceUnderProfilePath)) {
                const filteredSource = {};
                definition.properties.forEach(property => {
                    const value = sourceUnderProfilePath[property];
                    if (!isEmptyValue(value)) { // do not propagate empty string so on
                        filteredSource[property] = value;
                    }
                });

                // because our filteredSource has all fields on root level
                definition.profilePath = '$';

                newSnapshot = getPropagatedSnapshot(definition, filteredSource, newSnapshot);
            }
        } else { // non-model propagation
            newSnapshot = getPropagatedSnapshot(definition, profile, newSnapshot);
        }
    }
    return newSnapshot;
}

/**
 * Get the value on the path in array/object
 * @param {Array | object} arrayOrObject ex) { abc: { edf: [ 1, 2, 3 ]}}
 * @param {string} dotDelimitedPath ex) "abc.edf[0]"
 * @returns {{isValueFound: boolean, value: any}} ex) { isValueFound: true, value: 1 }
 */
function getValueOnPath(arrayOrObject, dotDelimitedPath) {
    if (dotDelimitedPath === '$') { // root notation
        return {
            isValueFound: true,
            value: arrayOrObject,
        };
    }

    if (dotDelimitedPath.startsWith('$.')){
        dotDelimitedPath = dotDelimitedPath.substring(2);
    }

    // a.b.c[0] ==> [ a, b, c, 0 ]
    const profilePathArray = getSubPathList(dotDelimitedPath);
    let value = arrayOrObject;
    let isValueFound = true;
    for (const index in profilePathArray) {
        const path = profilePathArray[index];

        // path in the middle should be array or object
        if (!isArrayOrObject(value)) {
            isValueFound = false;
            break;
        }

        // stop early if we can't step through sub paths
        if (value[path] === undefined) {
            isValueFound = false;
            break;
        }
            
        value = value[path];
    }
    return {
        isValueFound,
        value: isValueFound ? value : null,
    };
}

/**
 * get propagation rules from schemaOptions
 * @param {object} schemaOptions
 * @param {boolean} isVerifiedProfile
 * @returns [{ documentPath, profilePath, shouldOverwrite, model, properties }, ...]
 */
function getPropagationRules(schemaOptions, isVerifiedProfile) {
    const definitions = [];
    for (const [ documentPath, options ] of Object.entries(schemaOptions)) {
        const profilePath = options.profileSync?.profilePath;
        const fromProfileOption = options.profileSync?.fromProfile;
        const properties = options.model
            ? getAllWantedProperties(options.model, fromProfileOption?.properties ?? null)
            : null;

        const isEnabled = fromProfileOption?.enable === true
            || Array.isArray(fromProfileOption?.enable)
                && (isVerifiedProfile
                    ? fromProfileOption?.enable.includes('verified')
                    : fromProfileOption?.enable.includes('guest'));

        const shouldOverwrite = fromProfileOption?.overwrite === true
            || Array.isArray(fromProfileOption?.overwrite)
                && (isVerifiedProfile
                    ? fromProfileOption?.overwrite.includes('verified')
                    : fromProfileOption?.overwrite.includes('guest'));
        
        if (isEnabled) {
            definitions.push({ documentPath, profilePath, shouldOverwrite, model: options.model, properties });
        }
    }
    return definitions;
}

/**
 * return all properties we want to propagate in model
 * @param {string} modelName the name of model that's registered in PropagationModels
 * @param {Array} selectedProperties properties that we want to propagate among those in model
 * @returns {Array} array of properties to propagate
 */
function getAllWantedProperties(modelName, selectedProperties = null) {
    if (!Array.isArray(selectedProperties) && selectedProperties !== null) {
        throw new Error('check properties option in schemaOptions profileSync - it should be array or not defined');
    }

    const foundModel = PropagationModels[modelName];
    if (foundModel == null) {
        throw new Error(`Model is not defined in PropagationModels: ${modelName}`);
    }

    const jsonSchema = convertMstToJsonSchema(foundModel);
    if (jsonSchema.properties == null) {
        throw new Error(`types.model is only supported for model propagation, please check model: ${modelName}`);
    }

    return selectedProperties
        ? Object.keys(jsonSchema.properties).filter(property => selectedProperties.includes(property))
        : Object.keys(jsonSchema.properties);
}

/**
 * @param {object} definition { profilePath, documentPath, shouldOverwrite, model, properties }
 * @param {object} profile
 * @param {object} snapshot
 * @returns new snapshot with propagated values
 */
function getPropagatedSnapshot(definition, profile, snapshot) {
    const { isValueFound, value } = getValueOnPath(profile, definition.profilePath);
    if (isValueFound && !isEmptyValue(value)) {
        snapshot = setValueImmutable(
            snapshot,
            getSubPathList(definition.documentPath),
            value,
            definition.shouldOverwrite,
            definition.properties,
        ) ?? snapshot;
    }
    return snapshot;
}

/**
 * Return new copy of object/array where a designated path gets updated with new value without mutating original object
 * This method uses "structure sharing" with original object for those values unchanged
 * @param {object | Array} arrayOrObject
 * @param {Array} pathList array representation of the path to update
 *      ex) application.contacts[0] --> [ application, contacts, 0 ]
 * @param {any} value new value
 * @param {Array} wantedProperties In model propagation, we are selectively copying and pasting these properties only
 * @returns {object | Array | null} new object/array of which path gets updated with the new value
 *          if path can not be found, return null
 */
function setValueImmutable(arrayOrObject, pathList, value, shouldOverwrite, wantedProperties = null){
    let modifiedNode = value;
    const topMostPath = pathList[0];
    const restOfPath = pathList.slice(1);

    if (restOfPath.length > 0) {
        if (arrayOrObject[topMostPath] === undefined) {
            // can not set value, path does not exist
            return null;
        }

        modifiedNode = setValueImmutable(arrayOrObject[topMostPath], restOfPath, value, shouldOverwrite, wantedProperties);
        if (modifiedNode == null) {
            return null; // path not found
        }
    }

    // shallow copy & update only the path we are to modify
    const copied = Array.isArray(arrayOrObject)
        ? [ ...arrayOrObject ]
        : Object.assign({}, arrayOrObject);

    if (restOfPath.length === 0) {
        // Note: when value is object
        //       copied[topMostPath] could be undefined / null / object
        if (isObject(modifiedNode)) {
            // we don't create path in the middle if it doesn't exist
            if (copied[topMostPath] === undefined) {
                return null;
            }

            // we want to update "empty" values without considering it as overwrite
            // - ex) zero-length string, null value
            // we should remove fields with empty values to get them updated with spread operator
            if (!shouldOverwrite && isObject(copied[topMostPath])) {
                for (const property of wantedProperties ?? Object.keys(copied[topMostPath])) {
                    if (isEmptyValue(copied[topMostPath][property])) {
                        delete copied[topMostPath][property];
                    }
                }
            }

            copied[topMostPath] = shouldOverwrite
                ? { ...copied[topMostPath], ...modifiedNode } // original value will be overwritten
                : { ...modifiedNode, ...copied[topMostPath] }; // original value survives
        } else {
            // we create path on the end if it doesn't exist
            if (isEmptyValue(copied[topMostPath]) || shouldOverwrite) {
                copied[topMostPath] = modifiedNode;
            }
        }
    } else {
        copied[topMostPath] = modifiedNode;
    }

    return copied;
}

/**
 * generate sub-path array from dot-delimited string
 * @param {string} dotDelimitedPath ex) "journey.contacts[0]"
 * @returns {Array} array of sub-paths ex) [ "journey", "contacts", 0 ]
 */
function getSubPathList(dotDelimitedPath) {
    // Regex.exec example
    // ex) [0]       => field: undefined, arrayIndex: "0"
    // ex) abc       => field: "abc",     arrayIndex: undefined
    // ex) abc[0]    => field: "abc",     arrayIndex: "0"
    // ex) abc[0][1] => field: "abc",     arrayIndex: "0"
    const fieldWith1stIndexRegex = /^(?<field>[^[|^\]]+)?(\[(?<arrayIndex>\d+|-\d+)\])?.*$/;

    function generateSubPathList(path) {
        const regexResult = fieldWith1stIndexRegex.exec(path);
        const { field, arrayIndex } = regexResult.groups;
    
        let result = [];
        if (field != null) {
            result.push(field);
        }
        if (arrayIndex != null) {
            result.push(parseInt(arrayIndex));
        }
    
        const arrayIndexLength = arrayIndex?.length ?? 0;
        const bracketsLength = arrayIndexLength > 0 ? 2 : 0;
        // ex) length("myField[0]") = 5("myField") + 1("0") + 2("[]")
        const fieldAndFirstIndexLength = (field?.length ?? 0) + arrayIndexLength + bracketsLength;

        if (fieldAndFirstIndexLength < path.length) {
            // ex) path is abc[0][1] -> path.substring will be [1]
            result = result.concat(generateSubPathList(path.substring(fieldAndFirstIndexLength)));
        }
    
        return result;
    }

    return dotDelimitedPath.split('.').flatMap(subPath => generateSubPathList(subPath));
}

function isArrayOrObject(value) {
    return Array.isArray(value) || isObject(value);
}

function isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
}

export default addProfileDataToDocumentSnapshot;
