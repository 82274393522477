import queryString from 'query-string';

import getVisitorId from './getVisitorId';
import getExperimentHash from './getExperimentHash';


/**
 * @public
 * Calculate which experiment segment to assign the user for an experiment.
 * @param {object} experiment 
 * @returns {(null|number)}
 */
function calculateExperimentSegment(experiment) {
    // Experiments must never be calculated on server
    if (typeof window === 'undefined') {
        return null;
    }

    // SPECIAL CASE: is there an override in our query params?
    const queryParams = queryString.parse(window.location.search);
    if (queryParams[experiment.slug]) {
        return parseInt(queryParams[experiment.slug]);
    }

    // SPECIAL CASE: is there an override specified in the experiment definition?
    // (these typically come from .env settings)
    if (typeof experiment.segmentOverride === 'number') {
        return experiment.segmentOverride;
    }

    return getSegmentFromVisitorId(experiment);
}

/**
 * @private
 * Generate an experiment segment using visitor ID.
 * @param {object} experiment experiment setting to parse
 * @param {string} experiment.slug slug for the experiment
 * @param {number|[number]} experiment.variations how many segments there are
 * @returns {number}
 */
function getSegmentFromVisitorId(experiment) {
    if (typeof experiment.slug !== 'string') {
        throw new Error('getSegmentFromVisitorId: .slug must be a string');
    }
    if (typeof experiment.variations === 'undefined') {
        throw new Error(`getSegmentFromVisitorId: .variations is required (for slug: "${experiment.slug}")`);
    }

    // Compute probability of each variation.
    // REQUIREMENT: if variations is just a #, assume all equal probability.
    const percents = Array.isArray(experiment.variations)
        ? experiment.variations
        : Array(experiment.variations).fill(100 / (experiment.variations+1)); // +1 for control slot

    // REQUIREMENT: cannot have more than 100%.
    if (percents.reduce((sum, value) => sum + value, 0) > 100) {
        throw new Error('Sum of variation probability must be <= 100');
    }

    const visitorId = getVisitorId() ?? '0';

    // REQUIREMENT: each experiment needs to be bucketted consistently, but independently.
    // EXAMPLE: there are 2 experiments, both with 1 variation at 50% (50% control),
    //      it should be possible that one is "in" and the other is "out".
    // SOLUTION: include a numeric hash for each experiment in the bucketting.
    const experimentHash = getExperimentHash(experiment);

    // Assign user into a bucket between 0 and 100
    // NOTE: visitorId is a 64-bit BigInt (a string); only the last 4 digits are relevant for the mod
    let bucket = (experimentHash * Number(visitorId.slice(-4))) % 100;

    // Locate the segment with this bucket.
    // NOTE: +1 because -1+1=0 (control) or index+1=<variant #>
    const segment = 1 + percents.findIndex(max => {
        if (max > bucket) {
            return true;
        }
        else {
            bucket -= max;
            return false;
        }
    });

    // REQUIREMENT: log their assignment in Heap.
    // NOTE: if window is undefined, typeof undefined !== 'undefined' will still be false.
    if (typeof window !== 'undefined' && window.heap) {
        const segmentName = segment === 0
            ? 'Control'
            : `Variant ${segment}`;

        window.heap.addUserProperties({
            [`experiment-${experiment.slug}`]: segmentName,
        });
    }

    return segment;
}

export default calculateExperimentSegment;
