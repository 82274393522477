import React from 'react';
import { FormattedMessage } from 'react-intl';

import DefaultIntlFormatValues from '../definitions/DefaultIntlFormatValues';


/**
 * Convert a message prop to an object representing property values for children or dangerouslySetInnerHTML,
 * suitable for applying to a React component via spread.
 * @param {object} value string, message or descriptor object, or React node to convert to something suitable for rendering
 * @param {object} intl react-intl object to convert  with
 * @param {string|array|FormattedMessage} children
 * @param {object} options
 * @param {boolean} options.shouldLowerCaseMessage Reformats the message to lowercase except for the word 'I'
 * @returns {object} object with either a children or dangerouslySetHTML property
 */
function renderMessage(value, intl, children = undefined, options) {
    if (children) {
        try {
            const element = React.Children.only(children);

            if (element.type === FormattedMessage) {
                const message = getFormattedMessage(element, intl);

                return {
                    children: smartLowerCase(message.children, options?.shouldLowerCaseMessage),
                };
            } else {
                return {
                    children,
                };
            }
        } catch (error) {
            // React.Children.only will throw error in case of multiple children or if they are plain strings
            if (typeof children === 'string') {
                return { children: smartLowerCase(children, options?.shouldLowerCaseMessage) };
            }
            else {
                if (Array.isArray(children)) {
                    return {
                        children: children.map(child => {
                            try {
                                const element = React.Children.only(child);

                                if (element.type === FormattedMessage) {
                                    const message = getFormattedMessage(element, intl);

                                    return smartLowerCase(message.children, options?.shouldLowerCaseMessage);
                                } else {
                                    throw new Error(`Cannot render message from an arbitrary JSX: ${element.type}`);
                                }
                            } catch (error) {
                                // React.Children.only will throw error in case of multiple children
                                return child;
                            }
                        }),
                    };
                }
                else {
                    if (children.type === FormattedMessage) {
                        const message = getFormattedMessage(children, intl);
                        return { children: smartLowerCase(message.children, options?.shouldLowerCaseMessage) };
                    }
                    else {
                        // What even is this at this point?
                        return children;
                    }
                }
            }
        }
    } else if (value && intl && typeof value === 'object' && (value.id || value.descriptor)) {
        const message = intl.formatMessage(value.descriptor || value, {
            ...DefaultIntlFormatValues,
            ...value.values,
        });

        return {
            children: smartLowerCase(message, options?.shouldLowerCaseMessage),
        };
    } else if (React.isValidElement(value)) {
        try {
            const element = React.Children.only(value);

            if (element.type === FormattedMessage) {
                const message = getFormattedMessage(element, intl);

                return { children: smartLowerCase(message.children, options?.shouldLowerCaseMessage) };
            } else {
                throw new Error(`Cannot render message from an arbitrary JSX: ${element.type}`);
            }
        } catch (error) {
            // React.Children.only will throw error in case of multiple children
            return { children: value };
        }
    } else if (value && typeof value === 'string') {
        return { dangerouslySetInnerHTML: { __html: smartLowerCase(value, options?.shouldLowerCaseMessage) } };
    }

    return { children: value };
}


function getFormattedMessage(element, intl) {
    const { values, ...messageDefinition } = element.props;
    return {
        children: intl.formatMessage(messageDefinition, {
            ...DefaultIntlFormatValues,
            ...values,
        }),
    };
}

/**
 * Converts the string or string[] to lower case letters.
 * The regex targets any individual use of the letter 'i' and sets it to uppercase.
 *
 * @param {string|any[]} value
 * @param {boolean} makeLowerCase
 * @return {string|string[]}
 */
function smartLowerCase(value, makeLowerCase) {
    if (Array.isArray(value)) {
        return value.map(v => smartLowerCase(v, makeLowerCase));
    }

    return makeLowerCase && typeof value === 'string'
        ? value.toLocaleLowerCase().replace(/\bi\b/g, 'I')
        : value;
}


export default renderMessage;
