import { defineMessages } from 'react-intl';


const UNIVERSAL_ERROR_MESSAGES = defineMessages({
    RECOVERABLE_BY_REFRESH: {
        id: 'accounts.universalErrorMessage.refresh',
        defaultMessage: 'Oops, something went wrong, please refresh your page and try again',
    },
    RECOVERABLE_BY_LOGOUT: {
        id: 'accounts.universalErrorMessage.logout',
        defaultMessage: 'Something went wrong. Please log out',
    },
    RECOVERABLE_BY_TRY_AGAIN: {
        id: 'accounts.universalErrorMessage.tryAgain',
        defaultMessage: 'Something went wrong. Please try again',
    },
});

export default UNIVERSAL_ERROR_MESSAGES;