import { types } from 'mobx-state-tree';

import LOCALES from './Locales';

// A parallel set of definitions need to be maintained in @ratehub/base-ui for the time being.
// In the unlikely event of any changes, remember to keep in sync.
const LANGUAGES = {
    ENGLISH: LOCALES.ENGLISH.substring(0, 2),
    FRENCH: LOCALES.FRENCH.substring(0, 2),
};

const LanguageEnum = types.enumeration('Language', Object.values(LANGUAGES));

export {
    LanguageEnum,
};
export default LANGUAGES;
