// eslint-disable-next-line import/no-unresolved
import getNextConfig from 'next/config';

import getEnvironment from '../functions/getEnvironment';


// NOTE: This will only run ONCE; the result will be held in the module cache (see NOTE on the export)
function loadConfigFile() {

    // Try to read the next.js config
    const nextConfig = getNextConfig();

    // When running in context of a server (such as exporting),
    // we want to be able to override urls.
    const isServerContext = typeof window === 'undefined';

    // When running in context of next, we must use next's runtime configuration.
    if (typeof nextConfig !== 'undefined') {
        return isServerContext
            ? nextConfig.serverRuntimeConfig
            : nextConfig.publicRuntimeConfig;
    }

    return getEnvironment(isServerContext);
}

// NOTE: we're returning the RESULT of function (not the function itself)!  
export default loadConfigFile();
