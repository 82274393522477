export default {
    number: {
        'CAD0': { style: 'currency', currency: 'CAD', minimumFractionDigits: 0, maximumFractionDigits: 0 },
        'CAD2': { style: 'currency', currency: 'CAD', minimumFractionDigits: 2, maximumFractionDigits: 2 },
        'CAD0Compact': { style: 'currency', currency: 'CAD', minimumFractionDigits: 0, maximumFractionDigits: 0, notation: 'compact' },

        'percent':  { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 },
        'percent0': { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 },
        'percent1': { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 1 },
        'percent2': { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 },

        'decimal0': { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 },
        'decimal1': { style: 'decimal', minimumFractionDigits: 1, maximumFractionDigits: 1 },
        'decimal2': { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
        'decimal3': { style: 'decimal', minimumFractionDigits: 3, maximumFractionDigits: 3 },
        'decimal4': { style: 'decimal', minimumFractionDigits: 4, maximumFractionDigits: 4 },
    },
};
