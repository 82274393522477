import { Sizes } from '@ratehub/base-ui';


const BASE_PADDING_VALUE = '4%';
const WIDE_PADDING_VALUE = '7%';
const MAX_PADDING_SIZE = '90px';

export default {
    // Global consts
    MAIN_CONTENT_ID: 'main-content',
    NARROW_CONTENT_AREA: '82.93%',
    // Layout-type containers use these next two values to have a consistent padding across the site
    // For example: LayoutRow, Footer
    CHROME_BASE_PADDING: BASE_PADDING_VALUE,
    CHROME_EXTENDED_PADDING_QUERY: `
        @media(min-width: 700px) {
            padding-left: min(${WIDE_PADDING_VALUE}, ${MAX_PADDING_SIZE});
            padding-right: min(${WIDE_PADDING_VALUE}, ${MAX_PADDING_SIZE});
        }
    `,

    // Header
    HEADER_MAX_WIDTH: '1440px',
    HEADER_AD_BREAKPOINT: '775px',
    HEADER_AD_CONTAINER_PADDING_TOP: Sizes.SPACING.QUARTER,

    // Sidebar
    SIDEBAR_ID: 'sidebar-nav',

    // Name of an HTML attribute placed top-level CMS blocks; read by the Table of Contents
    TOP_LEVEL_BLOCK_DATA_ATTRIBUTE_NAME: 'data-rh-block',

    FOOTER_CONTAINER_ID: 'footerContainer',

    POPULAR_CONTENT_CONTAINER_ID: 'popularContentMenu',
    LINK_BLOCK_LIST_CONTAINER_CLASSNAME: 'linkBlockList',
    LINK_BLOCK_LIST_EXPANDABLE_CONTAINER_CLASSNAME: 'expandableContainer',
    TOGGLE_BUTTON_POPULAR_CONTENT: 'showPopularLinks',
    TOGGLE_BUTTON_LINK_BLOCK_LIST: 'toggleLinkBlockList',

    LAYOUT_SELECTOR_ID: 'layout-selector',
};
