/**
 * Below values are often default values in MST model
 * - null, empty string, empty object, empty array
 */
function isEmptyValue(value) {
    if (value == null) {
        return true;
    }
    if (typeof value === 'string') {
        return value.length === 0;
    }
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    // We can't use "typeof value === 'object'"
    // - it doesn't know how to distinguish JSON object from general Javascript Object
    // Example:
    //     const date = new Date()
    //     Object.keys(date).length) // 0 - Date object doesn't have key
    //     typeof date // object
    //     Object.prototype.toString.call(date) // [object Date]
    // Caution:
    //     We don't expect we are modifying toString() behaviour, but here's caution
    //     objects can change the behavior of Object.prototype.toString()
    //     by defining a Symbol.toStringTag property, leading to unexpected results.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/toString
    if (Object.prototype.toString.call(value) === '[object Object]') {
        return Object.keys(value).length === 0;
    }
    return false; // any other types considered "not empty"
}

export default isEmptyValue;
