import qs from 'query-string';

import { ElementAttributes } from '@ratehub/base-ui';


/**
 * Begin monitoring for clicks on any language link. 
 * If clicked, update their href with the query parameters of the window.
 */
function initializeLanguageLinks() {
    // Don't run on the server
    if (typeof window === 'undefined') {
        return;
    }

    // NOTE: this relies on the event bubble for an onClick of an anchor to reach the root document.
    // This solution was chosen as it elegantly handles dynamic generation of these links.
    document.addEventListener('click', event => {
        const target = event.target;
        
        if (
            !target 
            // Only alter anchors which are marked as language links
            || target.tagName !== 'A' 
            || !target.classList?.contains(ElementAttributes.LANGUAGE_LINK_CLASS)
            || !target.hasAttribute('href')
        ) {
            return; 
        }

        // Need to pass second argument because target.href is probaby a relative path.
        // As long as the second argument contains the scheme+domain, a relative path will be resolved correctly.
        // If the target.href is an absolute path, it should still parse the URL correctly.
        const targetUrl = new URL(target.getAttribute('href'), window.location.origin);

        // Grab query parameters
        const windowQueryParameters = qs.parse(window.location.search);
        const targetQueryParameters = qs.parse(targetUrl.search);

        // Grab the non-canconical part of the URL
        const canonicalURL = document.querySelector('link[rel=canonical]')?.href
            ? new URL(document.querySelector('link[rel=canonical]').href)
            : null; 
        const nonCanonicalURLParts = canonicalURL
            ? location.pathname.replace(canonicalURL.pathname, '')
            : '';

        // REQUIREMENT: update the href to include the non-canonical parts of the URL & query params on the window.
        targetUrl.pathname = `${targetUrl.pathname}${nonCanonicalURLParts}`;
        targetUrl.search = qs.stringify({
            ...windowQueryParameters,
            ...targetQueryParameters,
        });

        target.setAttribute('href', targetUrl);
    });
}

export default initializeLanguageLinks;
