import queryString from 'query-string';

import AFFILIATE_QUERY_PARAM_KEYS from '../definitions/AffiliateQueryParamKeys';
import getAffiliateData from './getAffiliateData';


/**
 * Gets a URL which is augmented with any affiliate tracking information present in the current `window.location`
 * @param {string} baseURL
 * @returns {string}
 */
export default function getAffiliateDecoratedURL(baseURL) {
    if (!baseURL) {
        return;
    }

    const { url, query } = queryString.parseUrl(baseURL);

    // Pull the affiliate data from window.location
    const affiliateData = getAffiliateData();

    // Dynamically add the affiliate params to our query
    Object.values(AFFILIATE_QUERY_PARAM_KEYS).forEach(key => {
        if (affiliateData?.[key]) {
            query[key] = affiliateData[key];
        }
    });

    return queryString.stringifyUrl({ url, query });
}
