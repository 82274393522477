import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import ALT_ANCHOR_STYLES from '../styles/AlternateAnchorStyles';
import { SECONDARY_STATE_COLOURS as STATE_COLOURS } from '../definitions/ButtonStateColours';
import MessagePropType from '../definitions/MessagePropType';
import renderMessage from '../functions/renderMessage';
import getTokenReplacedURL from '../functions/getTokenReplacedURL';


function AlternateAnchor({
    href,
    message,
    children,
    size = 'large',
    variant = 'dark',
    disabled,
    shouldLowerCaseMessage = true,
    ...otherProps
}) {
    const intl = useIntl();

    return (
        <AlternateAnchor.Anchor
            href={disabled ? undefined : getTokenReplacedURL(href)}
            size={size}
            variant={variant}
            disabled={disabled}
            aria-disabled={disabled}
            {...renderMessage(message, intl, children, { shouldLowerCaseMessage })}
            {...otherProps}
        />
    );
}

AlternateAnchor.propTypes = {
    href: PropTypes.string.isRequired,
    message: MessagePropType,
    children: PropTypes.node,
    size: PropTypes.oneOf([
        'large',
        'medium',
        'small',
    ]),
    variant: PropTypes.oneOf(Object.keys(STATE_COLOURS)),
    disabled: PropTypes.bool,
    shouldLowerCaseMessage: PropTypes.bool,
};

AlternateAnchor.Anchor = styled.a`
    ${ALT_ANCHOR_STYLES}
`;

export default observer(AlternateAnchor);
