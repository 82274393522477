/**
 * Retrieve the display name of a component.
 * Useful for wrapper components (HOC) that need to know the dispayName of the wrapped component.
 * 
 * Falls back to 'Component' if the component has no displayName.
 * 
 * @param Component 
 * @returns {string}
 */
export default function getComponentDisplayName(Component) {
    if (Component == null) {
        return null;
    }

    return Component.displayName ?? Component.name ?? 'Component';
}