import { css } from 'styled-components';

import Colours from '../definitions/Colours';
import { SECONDARY_STATE_COLOURS as STATE_COLOURS } from '../definitions/ButtonStateColours';
import Typography from '../definitions/Typography';
import Sizes from '../definitions/Sizes';


const ALT_ANCHOR_STYLES = css`
    display: flex;
    align-items: center;
    justify-content: center;

    ${props => props.size === 'large' && `
        min-height: ${Sizes.SPACING.FOUR};
        padding: ${Sizes.SPACING.THREE_QUARTERS} ${Sizes.SPACING.ONE_AND_A_HALF};
        font-size: ${Sizes.FONTS.M};
    `}

    ${props => props.size === 'medium' && `
        min-height: ${Sizes.SPACING.THREE};
        padding: ${Sizes.SPACING.THREE_QUARTERS} ${Sizes.SPACING.ONE_AND_A_HALF};
        font-size: ${Sizes.FONTS.S};
    `}

    ${props => props.size === 'small' && `
        min-height: ${Sizes.SPACING.TWO_AND_A_HALF};
        padding: ${Sizes.SPACING.HALF} ${Sizes.SPACING.ONE};
        font-size: ${Sizes.FONTS.S};
    `}

    font-weight: ${Typography.WEIGHTS.MEDIUM};

    width: 100%;
    box-sizing: border-box;

    border: 2px solid ${Colours.TRANSPARENT};
    border-radius: 8px;

    background-color: ${Colours.TRANSPARENT};
    text-decoration: none;

    font-family: ${Typography.FONT_FAMILY_STACK};
    text-align: center;

    outline: none;

    transition:
        color 300ms,
        background-color 300ms,
        border-color 300ms;

    /* Default, Hover/Focus, Active */

    /* NOTE: 'variant' usually controls the variant, but RichTextContent
    uses 'altAnchorVariant' since it needs to specify variant for the primary
    buttons as well, and can't use that name without causing a symbol conflict. */

    ${props => !props.disabled && `
        color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].DEFAULT};
        background-color: ${Colours.TRANSPARENT};
        border-color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].DEFAULT};

        &:hover,
        &:focus {
            color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].HOVER};
            border-color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].HOVER};
        }

        &:active {
            color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].ACTIVE};
            border-color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].ACTIVE};

            box-shadow: none;
        }
    `}

    /* Disabled */

    ${props => props.disabled && `
        cursor: default;

        color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].DISABLED};
        border-color: ${STATE_COLOURS[props.altAnchorVariant ?? props.variant].DISABLED};
    `}
`;

export default ALT_ANCHOR_STYLES;
