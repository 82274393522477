import { Config, fetchJSON } from '@ratehub/base-ui';
import { extractExperiments } from '@ratehub/web-components';


/**
 * Fetch all experiment definitions from the CMS
 * @returns {array}
 */
async function fetchExperimentDefinitions() {
    const response = await fetchJSON(Config.EXPERIMENTS_API_URL, 'fetchExperimentDefinitions');

    return extractExperiments(response);
}

export default fetchExperimentDefinitions;
