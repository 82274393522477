import getJSONFromResponse from './getJSONFromResponse';


/**
 * @private
 * Single-level object which all our cached values
 * KEY: cache key
 * VALUE: cached value OR a Promise semaphore if initial call is still outstanding
 */
const CACHE = {};

/**
 * Invoke an async function and cache their result.
 * Subsequent calls for the same key will return the cached result instead of calling the function.
 * Designed to reduce stress on our external systems
 * @param {string} key unique key for the cached data
 * @param {function} fn function to invoke when the data is not yet cached
 * @returns {*}
 */
async function fetchCachedValue(key, fn) {
    // This prevents an easy-to-introduce error, where you pass an object as the key,
    // which gets converted to '[object Object]' by Javascript and will result in calls clobbering each other unexpectedly
    if (typeof key !== 'string' && typeof key !== 'number') {
        throw new RangeError(`cache key must be a string or number, received ${key}`);
    }

    // Only run the function if it's NOT in our cache.
    if (!(key in CACHE)) {
        // Insert an awaitable semaphore under this key.
        // This notifies other callers looking for this key that the initial request is still pending.
        let resolveSemaphore, rejectSemaphore;
        CACHE[key] = new Promise((resolve, reject) => {
            resolveSemaphore = resolve;
            rejectSemaphore = reject;
        });

        // Run the function to get our value.
        try {
            const result = await fn();
            const jsonResult = await getJSONFromResponse(result);

            // Replace the semaphore with the actual value.
            CACHE[key] = jsonResult;

            // Notify anyone waiting the value is now available.
            resolveSemaphore(jsonResult);
        } catch (error) {
            // Clear-out the semaphore.
            delete CACHE[key];

            // Alert anyone waiting we failed.
            rejectSemaphore(error);

            // Rethrow the error so the caller can handle it.
            throw error;
        }
    }

    // Pull the value from our cache
    const value = CACHE[key];

    // SPECIAL CASE: the initial call is still outstanding; we inserted an awaitable semaphore in place of the value.
    if (typeof value === 'object' && typeof value.then === 'function') {
        // Return a CLONE of the result (incase they mutate it)
        return clone(await value);
    }

    // Return a CLONE of the result (incase they mutate it)
    return clone(value);
}

/**
 * @private
 * Create a clone of a value
 * @param {Object} value
 * @returns {Object}
 */
function clone(value) {
    return JSON.parse(JSON.stringify(value));
}

export { CACHE };    // Allows our tests to reset this
export default fetchCachedValue;
