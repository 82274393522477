import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { desaturate, lighten } from 'polished';

import Colours from '../definitions/Colours';
import { PRIMARY_BUTTON_VARIANTS } from '../definitions/ButtonVariants';
import { PRIMARY_BUTTON_CLASSES } from '../definitions/ButtonClasses';
import Button from './Button';


function PrimaryButton({
    className,
    size = 'large',
    variant = 'blueberry-dark',
    disabled,
    onClick,
    ...otherProps
}) {
    const computedDisabledVariant = disabled ? 'disabled' : 'enabled';

    return (
        <StyledButton
            size={size}
            disabled={disabled}
            onClick={onClick}
            className={classNames(className, variant,
                ...PRIMARY_BUTTON_CLASSES[variant][computedDisabledVariant] ?? [],
                {
                    'rh-opacity-0_5 rh-cursor-default': disabled,
                    'rh-opacity-1': variant === 'coconut' && disabled,
                },
            )}
            {...otherProps}
        />
    );
}

PrimaryButton.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf([
        'large',
        'medium',
        'small',
    ]),
    variant: PropTypes.oneOf(Object.values(PRIMARY_BUTTON_VARIANTS)),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};


const StyledButton = styled(Button)`
    ${props => !props.disabled && `
        /* Default bg colour & border for this variant is custom, so we need
        to put it here, not in utility classes. */
        &.blueberry-dark:not(:hover, :active, :focus) {
            background-color: ${desaturate(.299, lighten(.1647, Colours.BLUEBERRY_DARKEST))};
            border-color: ${desaturate(.299, lighten(.1647, Colours.BLUEBERRY_DARKEST))};
        }

        &:active {
            box-shadow: none;
        }
    `}
`;

export default observer(PrimaryButton);
