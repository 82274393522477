import { types } from 'mobx-state-tree';


const COUNTRIES = {
    CANADA:  'canada',
    UNITED_STATES: 'united-states',
};

const COUNTRY_CODES = {
    [COUNTRIES.CANADA]: 'CA',
    [COUNTRIES.UNITED_STATES]: 'US',
};

const CountryEnum = types.enumeration('Country', Object.values(COUNTRIES));

export {
    COUNTRY_CODES,
    CountryEnum,
};
export default COUNTRIES;
