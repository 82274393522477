import Config from '../definitions/Config';

import getJSONFromResponse, { InvalidJsonResponseError } from './getJSONFromResponse';
import fetchCachedValue from './fetchCachedValue';

import getFormattedFetchUrl from './getFormattedFetchUrl';
import fetchWithErrorHandling, { FetchError } from './fetchWithErrorHandling';
import noticeError from './noticeError';
import noticeErrorSaveEvent from './noticeErrorSaveEvent';
import isServerSide from './isServerSide';
import areFirstPartyCookiesEnabled from './areFirstPartyCookiesEnabled';


const MAX_DEBUG_TEXT_LENGTH = 100;

/**
 * Fetch data from an API with an optional set of params
 * @param {(Object|string)} target Url or object containing url, query params, request options
 * @param {string} target.url Clean API url
 * @param {Object} target.params query parameters to be added to the URL
 * @param {Object} target.options options for request such as method, headers, etc.
 * @param {string} target.arrayFormat How query-string should parse arrays
 * @param {boolean} target.canRetry If a transient error is returned, retry after a timeout
 * @param {boolean} target.canCache if we should attempt to reuse GET responses to the same URLs
 * @param {Object} target.signal an AbortSignal object instance to cancel ongoing fetch request
 * @param {string} callerName name of the caller when generating Error messages
 * @returns {Promise<Object>}
 */
async function fetchJSON(target, callerName = '') {
    // Extract the full URL.
    const apiURL = getFormattedFetchUrl(target);

    if (Config.ENABLE_FETCH_JSON_LOGGING) {
        // eslint-disable-next-line no-console
        console.log(`Fetching data from "${apiURL}" by caller "${callerName}"`);
    }

    const isServer = isServerSide();
    const options = {
        canRetry: true,         // allow retries by default, users of fetchJSON can opt-out via options if desired
        canCache: isServer,     // by default, cache all GET responses when we're doing an export
        ...(target.options),
        headers: {
            Accept: 'application/json',
            ...(apiURL.startsWith(Config.API_GATEWAY_URL) && {
                // This custom header was added to indicate if first-party cookies are enabled or not
                // it is used by the profile service to determine if a user can proceed with modify the document
                'x-has-first-party-cookies': areFirstPartyCookiesEnabled(),
            }),
            ...(target.options?.headers), //! for whatever reason, they might want to override the Accept header
        },
        signal: target.signal,
    };

    // Tracking elapsed time in case we throw an error,
    //  we can then see in NR if the error is related to
    //  a timeout.
    const startTimestamp = Date.now();

    try {
        // On the server, cache all GET requests (an exporter tends to make the same API calls when exporting all their pages).
        const response = options.canCache && isGetRequest(target) && Config.ENABLE_SERVER_FETCH_CACHING
            ? await fetchCachedValue(
                apiURL,                                            // cache by URL
                () => fetchWithErrorHandling(apiURL, options), // this is only run if the cache doesn't have the value already
            )
            : await fetchWithErrorHandling(apiURL, options).then(conclusiveResponse => getJSONFromResponse(conclusiveResponse));

        // Knowing our previous API calls can help debugging.
        noticeErrorSaveEvent(`[fetchJSON] ${callerName} received response from ${apiURL}`, options);

        return response;
    } catch (error) {
        // AbortError is an expected error, so leave it to caller to handle
        if (error?.name !== 'AbortError') {
            noticeError(error, {
                message: `[fetchJSON] Error logged when called by ${callerName}`,
                apiURL,
                params: target.params,
                options,
                error: error?.message,  // this gets serialized as {}; we want the message.
                elapsedTime: Date.now() - startTimestamp,
                ...(error instanceof FetchError && {
                    responseStatusCode: error.response.status,
                    responseBody: await safelyGetJSONFromResponse(error.response),
                }),
                ...(error instanceof InvalidJsonResponseError && {
                    responseBody: error.rawBody?.substring(0, MAX_DEBUG_TEXT_LENGTH),
                }),
            });
        }

        // Re-throw to allow for proper handling.
        throw error;
    }
}

/** If the API returns non-JSON, we don't want to cause an exception- we're only logging response. */
async function safelyGetJSONFromResponse(response) {
    try {
        return await getJSONFromResponse(response);
    } catch (error) {
        return null;
    }
}

/**
 * @private
 * Will the resulting fetch call be a GET operation?
 * @param {object} target
 * @returns {boolean}
 */
function isGetRequest(target) {
    return target.options == null
        || target.options.method == null    // if method not specified, fetch assumes GET (source: MDN)
        || target.options.method === 'GET';
}


export default fetchJSON;
