import { css } from 'styled-components';

import Colours from '../definitions/Colours';
import Typography from '../definitions/Typography';


export default css`
    padding: 0 1px;

    font-weight: ${Typography.WEIGHTS.MEDIUM};
    text-decoration: none;
    color: ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};

    border-bottom: 1px solid ${props => props.variant === 'light'
        ? Colours.COCONUT
        : Colours.BLACKBERRY};
    outline: none;

    transition: color 300ms,
                border-bottom-color 300ms;

    &:hover,
    &:focus {
        color: ${Colours.BLUEBERRY};
        border-bottom: 1px solid ${Colours.BLUEBERRY};
    }

    &:active {
        color: ${Colours.BLUEBERRY_DARK};
        border-bottom: 1px solid ${Colours.BLUEBERRY_DARK};
		animation: none;
    }
`;
