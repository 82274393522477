export { default as SchemaOptions } from './definitions/SchemaOptions';
export { default as validateV3 } from './functions/validate';
export { default as extractV3Metadata } from './functions/extractMetadata';
export { default as migrateFromV2toV3 } from './functions/migrate';

// Base document
export { default as InsMotoDocument } from './models/InsMotoDocument';

// Domain models
export { default as Applicant } from './models/Applicant';

// Definitions
export {
    Address,
    Contact,
    GENDERS,
} from './definitions/Common';
export { InquiryTypeValues } from './definitions/InsCommon';
export { default as LicenceTypeValues, flatLicenceTypeValues } from './definitions/LicenceTypeValues';

export const VERSION = 3;
