import Config from '../definitions/Config';
import SOCIAL_PROVIDERS from '../definitions/SocialProviders';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/social-sign-in`;

// NOTE: profile-faas endpoint returns profile
/**
 * @param {string} providerToken ex) RS256 token returned from Google API
 * @param {string} socialProvider ex) google(= SOCIAL_PROVIDERS.GOOGLE)
 * @return {object} ex) { profile: { ... } }
 */
async function signInBySocialService({ providerToken, socialProvider = SOCIAL_PROVIDERS.GOOGLE, document = null, documentType = null }) {
    return (await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                socialProvider,
                providerToken,
                document,
                documentType,
            }),
            credentials: 'include',
        },
    })).data;
}

export default signInBySocialService;
export { API_ENDPOINT };
