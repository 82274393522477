const COLOURS = {
    // Neutrals

    COCONUT: '#ffffff',
    COCONUT_DARK: '#fbfffd',

    BLACKBERRY: '#000000',
    BLACKBERRY_LIGHT: '#393939',

    TRANSPARENT: 'transparent',

    // Primary

    BLUEBERRY_LIGHTEST: '#dbf9ff',
    BLUEBERRY_LIGHT: '#a4f1ff',
    BLUEBERRY: '#00b5d6',
    BLUEBERRY_DARK: '#00729e',
    BLUEBERRY_DARKEST: '#004f6e',

    MINT_LIGHTEST: '#f5fffa',
    MINT_LIGHT: '#e0fff5',
    MINT: '#9ffcdf',
    MINT_DARK: '#68e9c1',
    MINT_DARKEST: '#3ea592',

    LIME_LIGHTEST: '#d3f7e2',
    LIME_LIGHT: '#a7e8c2',
    LIME: '#4ab879',
    LIME_DARK: '#358255',
    LIME_DARKEST: '#1f5f39',

    // Secondary

    GRAPE_LIGHTEST: '#fbeeff',
    GRAPE_LIGHT: '#ecd1f4',
    GRAPE: '#b969d1',
    GRAPE_DARK: '#80209c',
    GRAPE_DARKEST: '#550a6b',

    YUZU_LIGHTEST: '#fff4da',
    YUZU_LIGHT: '#fde4a7',
    YUZU: '#ffcc50',
    YUZU_DARK: '#fcb60a',
    YUZU_DARKEST: '#e7a400',

    TANGERINE_LIGHTEST: '#ffe7c8',
    TANGERINE_LIGHT: '#ffbc68',
    TANGERINE: '#ff9300',
    TANGERINE_DARK: '#ea7000',
    TANGERINE_DARKEST: '#cd5b00',

    WATERMELON_LIGHTEST: '#ffedef',
    WATERMELON_LIGHT: '#ffc7cc',
    WATERMELON: '#ff989b',
    WATERMELON_DARK: '#e56b6f',
    WATERMELON_DARKEST: '#d2484c',

    STRAWBERRY_LIGHTEST: '#ffa69d',
    STRAWBERRY_LIGHT: '#ff6e59',
    STRAWBERRY: '#f6452c',
    STRAWBERRY_DARK: '#d82e15',
    STRAWBERRY_DARKEST: '#ab1b00',

    STONE_LIGHTEST: '#f5f9fa',
    STONE_LIGHT: '#e5eff1',
    STONE: '#c0ced3',
    STONE_DARK: '#a4b4ba',
    STONE_DARKEST: '#6a787c',
};

// ** COLOUR UTILITY CLASSES **

const FOREGROUND_PREFIX = 'rh-fg-';
const BACKGROUND_PREFIX = 'rh-bg-';
const BORDER_PREFIX = 'rh-border-color-';

// Generate sets of classes based on these.
// Also used to generate the global styles for each utility class.
const FOREGROUND_COLOUR_CLASS_MAP = Object.entries(COLOURS).map(([ key, value ]) => ({
    colorName: key,
    value: value,
    className: FOREGROUND_PREFIX + key.toLowerCase().replace(/_/g, '-'),
}));

const BACKGROUND_COLOUR_CLASS_MAP = Object.entries(COLOURS).map(([ key, value ]) => ({
    colorName: key,
    value: value,
    className: BACKGROUND_PREFIX + key.toLowerCase().replace(/_/g, '-'),
}));

const BORDER_COLOUR_CLASS_MAP = Object.entries(COLOURS).map(([ key, value ]) => ({
    colorName: key,
    value: value,
    className: BORDER_PREFIX + key.toLowerCase().replace(/_/g, '-'),
}));

const COLOUR_FOREGROUND_CLASSES = [
    ...FOREGROUND_COLOUR_CLASS_MAP.map(colourObj => {
        return colourObj.className;
    }),
];

const COLOUR_BACKGROUND_CLASSES = [
    ...BACKGROUND_COLOUR_CLASS_MAP.map(colourObj => {
        return colourObj.className;
    }),
];

const COLOUR_BORDER_CLASSES = [
    ...BORDER_COLOUR_CLASS_MAP.map(colourObj => {
        return colourObj.className;
    }),
];

export default COLOURS;
export {
    FOREGROUND_COLOUR_CLASS_MAP,
    BACKGROUND_COLOUR_CLASS_MAP,
    BORDER_COLOUR_CLASS_MAP,
    COLOUR_FOREGROUND_CLASSES,
    COLOUR_BACKGROUND_CLASSES,
    COLOUR_BORDER_CLASSES,
};
