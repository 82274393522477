/* eslint-disable import/no-commonjs */
// This file is used exclusively within getEnvironment.js, hence the use of the commonjs export

// This file is used within the Renewal Table Bonus experiment https://ratehub.atlassian.net/browse/EVR-3699
// it is temporary until the experiment is over and/or deemed successful. If successful this data will be placed within the CMS
const MTG_SWITCH_BONUS = [
    {
        provider: 'canwise',
        value: 800,
        text: {
            en: 'Not a cashback amount, transfer fees are covered.  Conditions apply',
            fr: 'Il ne s\'agit pas d\'une remise en argent, les frais de transfert sont couverts. Des conditions s\'appliquent',
        },
    },
    {
        provider: 'big-6-bank',
        value: 4100,
        text: {
            en: 'Maximum based on mortgage amount. Conditions apply',
            fr: 'Maximum basé sur le montant de l\'hypothèque. Des conditions s\'appliquent',
        },
    },
    {
        provider: 'td-bank',
        value: 4100,
        text: {
            en: 'Maximum based on mortgage amount. Conditions apply',
            fr: 'Maximum basé sur le montant de l\'hypothèque. Des conditions s\'appliquent',
        },
    },
    {
        provider: 'scotiabank',
        value: 800,
        text: {
            en: 'Not a cashback amount, transfer fees are covered.  Conditions apply',
            fr: 'Il ne s\'agit pas d\'une remise en argent, les frais de transfert sont couverts. Des conditions s\'appliquent',
        },
    },
    {
        provider: 'bank-of-montreal',
        value: 4100,
        text: {
            en: 'Maximum based on mortgage amount. Conditions apply',
            fr: 'Maximum basé sur le montant de l\'hypothèque. Des conditions s\'appliquent',
        },
    },
    {
        provider: 'rbc-royal-bank',
        value: 3500,
        text: {
            en: 'Maximum based on mortgage amount. Conditions apply',
            fr: 'Maximum basé sur le montant de l\'hypothèque. Des conditions s\'appliquent',
        },
    },
    {
        provider: 'cibc',
        value: 3500,
        text: {
            en: 'Maximum based on mortgage amount. Conditions apply',
            fr: 'Maximum basé sur le montant de l\'hypothèque. Des conditions s\'appliquent',
        },
    },
];

module.exports = MTG_SWITCH_BONUS;
