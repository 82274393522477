import { types } from 'mobx-state-tree';

import { StreetTypeEnum } from '../definitions/StreetTypes';
import { StreetDirectionEnum } from '../definitions/StreetDirections';
import PROVINCES, { ProvinceEnum } from '../definitions/Provinces';
import COUNTRIES, { CountryEnum } from '../definitions/Countries';


const Address = types.model('Address', {
    streetNumber: types.maybe(types.string),
    streetName: types.maybe(types.string),
    streetType: types.maybe(StreetTypeEnum),
    streetDirection: types.maybe(StreetDirectionEnum),
    unitNumber: types.maybe(types.string),
    city: types.maybe(types.string),
    province: types.maybe(ProvinceEnum),
    postalCode: types.maybe(types.string),
    country: types.optional(CountryEnum, COUNTRIES.CANADA),
}).preProcessSnapshot(snapshot => {
    if (!snapshot) {
        return;
    }

    const {
        streetNumber,
        streetName,
        streetType,
        streetDirection,
        unitNumber,
        city,
        province,
        postalCode,
        country,
        ...otherProps
    } = snapshot;

    // Non-ideal, Have to convert nulls to undefined due to discrepancy in use of types.maybe vs types.maybeNull
    return {
        streetNumber: streetNumber !== null ? streetNumber : undefined,
        streetName: streetName !== null ? streetName : undefined,
        streetType: streetType !== null ? streetType : undefined,
        streetDirection: streetDirection !== null ? streetDirection : undefined,
        unitNumber: unitNumber !== null ? unitNumber : undefined,
        city: city !== null ? city : undefined,
        province: province !== null ? getCorrectProvinceCode(province) : undefined,
        postalCode: postalCode !== null ? postalCode : undefined,
        country: country !== null ? country : undefined,
        ...otherProps,
    };
}).views(self => ({
    // Base information needed for an address to be complete
    get isComplete() {
        const validAddressFields = [ 'streetNumber', 'streetName', 'city', 'province', 'postalCode' ];

        return validAddressFields.every(fieldName => self[fieldName] != null && self[fieldName] !== '');
    },
}));

/**
 * Legacy systems are using the wrong province code for Yukon which fails MST schema.
 * 
 * @param {string} provinceCode 
 * @returns {string}
 */
function getCorrectProvinceCode(provinceCode) {
    // TODO: @abhayvatoo to remove this function once we are fully switched over to new API
    if (provinceCode === 'YK') {
        return PROVINCES.YUKON;
    }

    return provinceCode;
}

export default Address;