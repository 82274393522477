import React, { 
    useEffect,
    useState, 
    useContext,
    createContext,
} from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';

import scrollToTop from '../functions/scrollToTop';


const Context = createContext();

function BannerProvider({ children }) {
    const state = useLocalState();

    return (
        <Context.Provider value={state}>
            {children}
        </Context.Provider>
    );
}

BannerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useLocalState() {
    const [ state ] = useState(() => observable({
        callerName: null,
        isVisible: false,
        children: null,
        isSticky: null,
        
        // Since hide can occur via the timeout, we provide
        //   a callback in case any clean up is needed
        _onHide: null,

        _timer: null,

        /**
         * 
         * @param {object} options
         * @param {React.ReactNode | React.ElementType} options.children - can be a render function that takes no arguments
         * @param {string} options.callerName - name of the caller is required; so that only the caller can hide the banner; if another caller opens a banner, it will overwrite the current banner however
         * @param {number} [options.duration] - if not provided, the banner will persist until dismissed
         * @param {boolean} [options.isSticky] - if true, the banner will be sticky
         * @param {function} [options.onHide] - callback when banner is hidden
         */
        show({ children, callerName, duration, isSticky, onHide }) {
            // Clean up previous banner state
            state.clear();

            if (!children) {
                throw new Error('[BannerProvider] children are required to render the banner');
            }

            state.isVisible = true;
            state.callerName = callerName;

            // With a component that uses size-me we have to use a render function
            //  to delay the rendering of the JSX until its inside a component.
            //  Otherwise, size-me will throw since there's no valid parent elements.
            // So we're just going to always use render functions for children
            state.children = typeof children !== 'function' ? () => children : children;
            
            state.isSticky = isSticky;
            state._onHide = onHide;

            if (duration != null) {
                state._timer = setTimeout(() => state.hide(callerName), duration);
            }

            // REQUIREMENT: If we're visible and not sticky, scroll to the 
            //  top of the page so the banner is visible to the user
            if (!isSticky) {
                scrollToTop(false);
            }
        },
        /**
         * Hide the banner.
         * 
         * @param {string} callerName required so that only the caller can hide its banner
         */
        hide(callerName) {
            if (state.callerName !== callerName) {
                return;
            }

            state.isVisible = false;

            if (typeof state._onHide === 'function') {
                state._onHide();
                state._onHide = null;
            }
        },
        /**
         * Clear the banner.
         * Clear is separated for cases where we use an animated container;
         * We cannot fade out if children is null, so we have to clear them after the animation
         * 
         * @param {string} callerName required so that only the caller can clear its banner
         */
        clear(callerName) {
            if (state.callerName !== callerName) {
                return; // can't clear if we're not the caller
            }
        
            state.children = null;
            state.isSticky = null;
            state.callerName = null;
            
            if (state._timer) {
                clearTimeout(state._timer);
                state._timer = null;
            }
        },
    }));

    // When we unmount, clear the internal state
    //  and specifically clear any timers that might be running
    useEffect(() => {
        return state.clear;
    }, [ state ]);

    return state;
}

function useBannerContext() {
    return useContext(Context);
}

export { useBannerContext };
export default BannerProvider;
