// TODO Verify the inquiries node and what we need for events.
export default {
    'application.applicant.contact': {
        'read': true,
        'write': true,
        'mutate': true,
        'model': 'Contact',
        'profileSync': {
            'toProfile': {
                'enable': true,
                'overwrite': true,
                'properties': [ 'email', 'firstName', 'lastName', 'dob', 'phone' ],
            },
            'fromProfile': {
                'enable': true,
                'overwrite': false,
                'properties': [ 'email', 'firstName', 'lastName', 'dob', 'phone' ],
            },
            'profilePath': '$',
        },
    },
};
