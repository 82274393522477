import React from 'react';
import dynamic from 'next/dynamic';


const DYNAMIC_LOADING_OPTIONS = {
    ssr: false,
    loading: () => <div />,
};

const IconArrowLazy = dynamic(
    () => import('./icons/IconArrow'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconAwardLazy = dynamic(
    () => import('./icons/IconAward'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconBarChartLazy = dynamic(
    () => import('./icons/IconBarChart'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconBellLazy = dynamic(
    () => import('./icons/IconBell'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconBookLazy = dynamic(
    () => import('./icons/IconBook'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconBriefcaseLazy = dynamic(
    () => import('./icons/IconBriefcase'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCalculatorLazy = dynamic(
    () => import('./icons/IconCalculator'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCalendarLazy = dynamic(
    () => import('./icons/IconCalendar'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCakeLazy = dynamic(
    () => import('./icons/IconCake'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCarLazy = dynamic(
    () => import('./icons/IconCar'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCartLazy = dynamic(
    () => import('./icons/IconCart'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCheckmarkLazy = dynamic(
    () => import('./icons/IconCheckmark'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconClockLazy = dynamic(
    () => import('./icons/IconClock'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconConversationLazy = dynamic(
    () => import('./icons/IconConversation'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconCreditCardLazy = dynamic(
    () => import('./icons/IconCreditCard'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconDiamondLazy = dynamic(
    () => import('./icons/IconDiamond'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconDollarLazy = dynamic(
    () => import('./icons/IconDollar'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconExclamationMarkLazy = dynamic(
    () => import('./icons/IconExclamationMark'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconEyeLazy = dynamic(
    () => import('./icons/IconEye'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconFiltersLazy = dynamic(
    () => import('./icons/IconFilters'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconGasTankLazy = dynamic(
    () => import('./icons/IconGasTank'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconGhostLazy = dynamic(
    () => import('./icons/IconGhost'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconGiftLazy = dynamic(
    () => import('./icons/IconGift'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconHeadsetLazy = dynamic(
    () => import('./icons/IconHeadset'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconHeartLazy = dynamic(
    () => import('./icons/IconHeart'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconHourglassLazy = dynamic(
    () => import('./icons/IconHourglass'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconHouseLazy = dynamic(
    () => import('./icons/IconHouse'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconKeyLazy = dynamic(
    () => import('./icons/IconKey'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconLifePreserverLazy = dynamic(
    () => import('./icons/IconLifePreserver'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconLightBulbLazy = dynamic(
    () => import('./icons/IconLightBulb'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconLightningLazy = dynamic(
    () => import('./icons/IconLightning'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconLockLazy = dynamic(
    () => import('./icons/IconLock'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconMailOpenLazy = dynamic(
    () => import('./icons/IconMailOpen'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconMapleLeafLazy = dynamic(
    () => import('./icons/IconMapleLeaf'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconMapPinLazy = dynamic(
    () => import('./icons/IconMapPin'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconMicrophoneLazy = dynamic(
    () => import('./icons/IconMicrophone'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconMinusLazy = dynamic(
    () => import('./icons/IconMinus'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconNoDollarLazy = dynamic(
    () => import('./icons/IconNoDollar'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconNoteLazy = dynamic(
    () => import('./icons/IconNote'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPaperLazy = dynamic(
    () => import('./icons/IconPaper'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPaperStackLazy = dynamic(
    () => import('./icons/IconPaperStack'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPeopleLazy = dynamic(
    () => import('./icons/IconPeople'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPersonLazy = dynamic(
    () => import('./icons/IconPerson'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPhoneLazy = dynamic(
    () => import('./icons/IconPhone'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPiggyLazy = dynamic(
    () => import('./icons/IconPiggy'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPlaneLazy = dynamic(
    () => import('./icons/IconPlane'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPlusLazy = dynamic(
    () => import('./icons/IconPlus'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconPodcastMicrophoneLazy = dynamic(
    () => import('./icons/IconPodcastMicrophone'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconQuestionMarkLazy = dynamic(
    () => import('./icons/IconQuestionMark'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconRatehubLazy = dynamic(
    () => import('./icons/IconRatehub'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconRefreshLazy = dynamic(
    () => import('./icons/IconRefresh'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconRobotLazy = dynamic(
    () => import('./icons/IconRobot'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconSearchLazy = dynamic(
    () => import('./icons/IconSearch'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconShareLazy = dynamic(
    () => import('./icons/IconShare'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconShieldLazy = dynamic(
    () => import('./icons/IconShield'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconSmileLazy = dynamic(
    () => import('./icons/IconSmile'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconSpeechBubbleLazy = dynamic(
    () => import('./icons/IconSpeechBubble'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconSpeedometerLazy = dynamic(
    () => import('./icons/IconSpeedometer'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconStarLazy = dynamic(
    () => import('./icons/IconStar'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconStudentLazy = dynamic(
    () => import('./icons/IconStudent'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconSuitcaseLazy = dynamic(
    () => import('./icons/IconSuitcase'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconThumbsDownLazy = dynamic(
    () => import('./icons/IconThumbsDown'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconThumbsUpLazy = dynamic(
    () => import('./icons/IconThumbsUp'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconTrendingLazy = dynamic(
    () => import('./icons/IconTrending'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconTrophyLazy = dynamic(
    () => import('./icons/IconTrophy'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconUmbrellaLazy = dynamic(
    () => import('./icons/IconUmbrella'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconUserLazy = dynamic(
    () => import('./icons/IconUser'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconWalletLazy = dynamic(
    () => import('./icons/IconWallet'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconWrenchLazy = dynamic(
    () => import('./icons/IconWrench'),
    DYNAMIC_LOADING_OPTIONS,
);
const IconXLazy = dynamic(
    () => import('./icons/IconX'),
    DYNAMIC_LOADING_OPTIONS,
);

export {
    IconArrowLazy,
    IconAwardLazy,
    IconBarChartLazy,
    IconBellLazy,
    IconBookLazy,
    IconBriefcaseLazy,
    IconCalculatorLazy,
    IconCalendarLazy,
    IconCakeLazy,
    IconCarLazy,
    IconCartLazy,
    IconCheckmarkLazy,
    IconClockLazy,
    IconConversationLazy,
    IconCreditCardLazy,
    IconDiamondLazy,
    IconDollarLazy,
    IconExclamationMarkLazy,
    IconEyeLazy,
    IconFiltersLazy,
    IconGasTankLazy,
    IconGhostLazy,
    IconGiftLazy,
    IconHeadsetLazy,
    IconHeartLazy,
    IconHourglassLazy,
    IconHouseLazy,
    IconKeyLazy,
    IconLifePreserverLazy,
    IconLightBulbLazy,
    IconLightningLazy,
    IconLockLazy,
    IconMailOpenLazy,
    IconMapleLeafLazy,
    IconMapPinLazy,
    IconMicrophoneLazy,
    IconMinusLazy,
    IconNoDollarLazy,
    IconNoteLazy,
    IconPaperLazy,
    IconPaperStackLazy,
    IconPeopleLazy,
    IconPersonLazy,
    IconPhoneLazy,
    IconPiggyLazy,
    IconPlaneLazy,
    IconPlusLazy,
    IconPodcastMicrophoneLazy,
    IconQuestionMarkLazy,
    IconRatehubLazy,
    IconRefreshLazy,
    IconRobotLazy,
    IconSearchLazy,
    IconShareLazy,
    IconShieldLazy,
    IconSmileLazy,
    IconSpeechBubbleLazy,
    IconSpeedometerLazy,
    IconStarLazy,
    IconStudentLazy,
    IconSuitcaseLazy,
    IconThumbsDownLazy,
    IconThumbsUpLazy,
    IconTrendingLazy,
    IconTrophyLazy,
    IconUmbrellaLazy,
    IconUserLazy,
    IconWalletLazy,
    IconWrenchLazy,
    IconXLazy,
};
