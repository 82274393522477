import queryString from 'query-string';


const DEFAULT_ARRAY_FORMAT = 'bracket';

/**
 * Returns the straight url or a stringified url from an object
 * @param {object || string} target
 * @returns {string}
 */
export default function getFormattedFetchUrl(target) {
    let url = target.url ?? target;

    if (target.params) {
        url += `?${queryString.stringify(target.params, {
            encode: target.encode ?? true,
            arrayFormat: target.arrayFormat || DEFAULT_ARRAY_FORMAT,
        })}`;
    }

    return url;
}
