import Config from '../definitions/Config';
import DOCUMENTS from '../definitions/Documents';
import fetchJSON from './fetchJSON';


/**
 * Check if the user's document should be associated with a verified profile; and needs the user to login.
 *
 * @param {object} params
 * @param {string} params.documentId
 * @param {string} params.documentType
 * @param {string} params.documentRedirect
 * @param {object} params.queryParams
 *
 * @returns {Promise<boolean>} Based on the action, directs application what to do next.
 * If action is `force-login`, then user should be blocked until they open the email that was sent.
 * Otherwise, the user should be allowed to continue.
 */
async function fetchShouldForceLogin({
    documentId,
    documentType,
    documentRedirect,
    queryParams,
}) {
    if (!Object.values(DOCUMENTS).map(doc => doc.TYPE).includes(documentType)) {
        throw new Error(`[fetchShouldForceLogin]: Invalid document type: "${documentType}"`);
    }

    const { data } = await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                documentId,
                documentType,
                documentRedirect,
                queryParams,
            }),
            credentials: 'include',
        },
    });

    return data.action === API_RESPONSES.FORCE_LOGIN;
}

// Only exported for use in mock function (not included in index.js)
export const API_RESPONSES = {
    FORCE_LOGIN: 'force-login',
    NO_ACTION_REQUIRED: 'no-action-required',
};

export const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/should-force-sign-in`;

export default fetchShouldForceLogin;
