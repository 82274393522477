export default {
    'journey.applicant' : {
        'read': true,
        'write': true,
        'mutate': true,
        'model': 'Contact',
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
            },
            'fromProfile' : {
                'enable': true,
                'overwrite': false,
            },
            'profilePath': '$',
        },
    },
};
