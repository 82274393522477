export { default as validateV1 } from './functions/validate';
export { default as extractV1Metadata } from './functions/extractMetadata';

// Base document
export { default as InsLeadFormDocument } from './models/InsLeadFormDocument';

// Definitions
export { default as SchemaOptions } from './definitions/SchemaOptions';
export { GENDERS } from './definitions/Common';
export { EventTypeValues } from './definitions/InsCommon';

export const VERSION = 1;
