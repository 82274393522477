import { types } from 'mobx-state-tree';


const GENDERS = {
    MALE: 'male',
    FEMALE: 'female',
    OTHERS: 'others',
};

const GENDERS_CODES = {
    [GENDERS.MALE]: 'M',
    [GENDERS.FEMALE]: 'F',
    [GENDERS.OTHERS]: 'O',
};

const GenderEnum = types.enumeration('Gender', Object.values(GENDERS));

export {
    GENDERS_CODES,
    GenderEnum,
};
export default GENDERS;
