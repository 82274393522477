import { types } from 'mobx-state-tree';


const STREET_DIRECTIONS = {
    NORTH:  'north',
    NORTHEAST: 'northeast',
    EAST:   'east',
    SOUTHEAST: 'southeast',
    SOUTH:  'south',
    SOUTHWEST: 'southwest',
    WEST:   'west',
    NORTHWEST: 'northwest',

    NORD:  'nord',
    NORDEST: 'nord-est',
    EST:   'est',
    SUDEST: 'sud-est',
    SUD:  'sud',
    SUDOUEST: 'sud-ouest',
    OUEST:   'ouest',
    NORDOUEST: 'nord-ouest',
};

const StreetDirectionEnum = types.enumeration('StreetDirection', Object.values(STREET_DIRECTIONS));

export {
    StreetDirectionEnum,
};
export default STREET_DIRECTIONS;
