import React from 'react';

import DefaultIntlFormatValues from '../definitions/DefaultIntlFormatValues';
import getComponentDisplayName from './getComponentDisplayName';
import getFormattedNumber from './getFormattedNumber';


/**
 * Convert a message prop to a string value.
 * @param {Object} value value to convert to raw text
 * @param {import('react-intl').IntlShape} intl react-intl object to convert text with
 * @returns {string}
 */
export default function messageToString(value, intl) {
    if (value == null || typeof value === 'string') {
        return value;
    }

    if (typeof value === 'number') {
        return value.toString();
    }

    // It's an intl message, and we can translate it.
    if (typeof value === 'object') {
        if (intl == null) {
            throw new RangeError('Called with an object to intl.formatMessage, but missing the intl object');
        }

        if ('id' in value) {
            // react-intl message descriptor.
            return intl.formatMessage(value, { ...DefaultIntlFormatValues });
        } else if ('descriptor' in value) {
            // Our custom message object.
            return intl.formatMessage(value.descriptor, { ...DefaultIntlFormatValues, ...value.values });
        } else if (React.isValidElement(value)) {
            // Expected to be a <FormattedMessage> or <FormattedNumber>

            // Verify it's a FormattedMessage/FormattedNumber.
            // As-of react-intl v5 all <FormattedMessage> are wrapped in React.memo.
            // In v4 it was wrapped in a React.Fragment, so we could just pull React.children(value) and do a type check.

            // NOTE TO DEVELOPER: this relies on implementation details of <FormattedMessage>.
            //    Outer-most type is React.element
            //    Inner type is React.memo, whose .type is the memoized FormattedMessage.
            // For FormattedNumber, there was only one .type
            const componentDisplayName = getComponentDisplayName(value.type);
            if (![
                'FormattedNumber',
                'MemoizedFormattedMessage',
            ].includes(componentDisplayName)) {
                throw new RangeError(`Cannot convert arbitrary JSX to string: “${value.type}” • [${componentDisplayName}]`);
            }

            if (getComponentDisplayName(value.type) === 'FormattedNumber') {
                // Pull the number and format from the props
                const { value: numberValue, format } = value.props;
                return getFormattedNumber(numberValue, format, intl);
            }

            // Pull the message from their props.
            const { values, ...messageDefinition } = value.props;

            // Resolve the message.
            return intl.formatMessage(messageDefinition, {
                ...DefaultIntlFormatValues,
                ...values,
            });
        } else if (Array.isArray(value) && value.some(item => React.isValidElement(item))) {
            // may never happen, but if it does it might be messageToString was called more than once for the same value
            throw new Error(`messageToString: Cannot convert React element array to string: ${value}`);
        }
    }

    // Likely a mistake has been made. Throw so our unit tests will catch it.
    throw new Error(`messageToString: Cannot convert value to string: “${JSON.stringify(value)}”`);
}
