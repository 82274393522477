import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/get-session`;

/**
 *
 * @returns {Promise<*>}
 */
async function fetchSession() {
    return (await fetchJSON({
        url: API_ENDPOINT,
        options: {
            headers: {
                'x-profile-schema-version': Config.PROFILE_SCHEMA_VERSION,
            },
            credentials: 'include',
        },
    })).data;
}

export default fetchSession;
export {
    API_ENDPOINT,
};
