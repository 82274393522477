import Cookies from 'js-cookie';

import cookieSettings from '../definitions/CookieSettings';
import fetchGeoLocationFromIp from './fetchGeoLocationFromIp';


const COOKIE_NAME = 'geo-location'; // used to cache geo-location


/**
 * Fetch full user city information from api by cookie or by geo ip lookup
*/
async function fetchUserCity(shouldSaveInCookie = true) {
    const cachedLocation = getCachedLocation();

    if (cachedLocation) {
        return getCityFromLocation(cachedLocation);
    }

    const location = await fetchGeoLocationFromIp();

    if (!location) {
        return null; // unable to geo-locate user
    }

    // Updates cookie location
    if (shouldSaveInCookie) {
        setCachedLocation(location);
    }

    return getCityFromLocation(location);
}

function getCityFromLocation(location) {
    if (!location) {
        return null;
    }

    return {
        name: location.city,
        province: {
            code: location.province,
        },
    };
}

/**
 * Get user city id from cookie
 */
function getCachedLocation() {
    try {
        const parsed = JSON.parse(Cookies.get(COOKIE_NAME));

        // make sure our cookie's contents are valid
        if (!parsed?.city || !parsed?.province) {
            Cookies.set(COOKIE_NAME, '', { expires: -1 }); // delete invalid cookie
            return null;
        }

        return parsed;
    } catch (error) {
        return null;
    }
}

/**
 * Set user location cookie to location object in JSON..
 */
function setCachedLocation(location) {
    Cookies.set(COOKIE_NAME, JSON.stringify(location), cookieSettings);
}

export default fetchUserCity;
export { COOKIE_NAME }; // exported for testing
