import { types } from 'mobx-state-tree';


const EmailAddress = types.snapshotProcessor(types.string, {
    preProcessor(snapshot) {
        return typeof snapshot === 'string'
            ? snapshot.toLowerCase() // email addresses must be case-insensitive
            : snapshot; // types.string will handle this
    },
});

export default EmailAddress;
