/**
 * Track an event in Heap
 */
function trackHeapEvent(eventName, options) {
    const heap = window.heap || window.parent.heap;

    if (!heap || !eventName) {
        return;
    }

    heap.track(eventName, options);
}

export default trackHeapEvent;
