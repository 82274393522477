import { types } from 'mobx-state-tree';


const STREET_TYPES = {
    ABBEY: 'abbey',
    ACRES: 'acres',
    ALLEE: 'allee',
    ALLEY: 'alley',
    AUTOROUTE: 'autoroute',
    AVENUE: 'avenue',
    BAY: 'bay',
    BEACH: 'beach',
    BEND: 'bend',
    BOULEVARD: 'boulevard',
    BYPASS: 'bypass',
    BYWAY: 'byway',
    CAMPUS: 'campus',
    CAPE: 'cape',
    CARRE: 'carre',
    CARREFOUR: 'carrefour',
    CENTRE: 'centre',
    CERCLE: 'cercle',
    CHASE: 'chase',
    CHEMIN: 'chemin',
    CIRCLE: 'circle',
    CIRCUIT: 'circuit',
    CLOSE: 'close',
    COMMON: 'common',
    CONCESSION: 'concession',
    CORNERS: 'corners',
    COTE: 'cote',
    COUR: 'cour',
    COURS: 'cours',
    COURT: 'court',
    COVE: 'cove',
    CRESCENT: 'crescent',
    CROISSANT: 'croissant',
    CROSSING: 'crossing',
    CULDESAC: 'culdesac',
    DALE: 'dale',
    DELL: 'dell',
    DIVERSION: 'diversion',
    DOWNS: 'downs',
    DRIVE: 'drive',
    ECHANGEUR: 'echangeur',
    END: 'end',
    ESPLANADE: 'esplanade',
    ESTATES: 'estates',
    EXPRESSWAY: 'expressway',
    EXTENSION: 'extension',
    FARM: 'farm',
    FIELD: 'field',
    FOREST: 'forest',
    FREEWAY: 'freeway',
    FRONT: 'front',
    GARDENS: 'gardens',
    GATE: 'gate',
    GLADE: 'glade',
    GLEN: 'glen',
    GREEN: 'green',
    GROUNDS: 'grounds',
    GROVE: 'grove',
    HARBOUR: 'harbour',
    HEATH: 'heath',
    HEIGHTS: 'heights',
    HIGHLANDS: 'highlands',
    HIGHWAY: 'highway',
    HILL: 'hill',
    HOLLOW: 'hollow',
    ILE: 'ile',
    IMPASSE: 'impasse',
    INLET: 'inlet',
    ISLAND: 'island',
    KEY: 'key',
    KNOLL: 'knoll',
    LANDING: 'landing',
    LANE: 'lane',
    LIMITS: 'limits',
    LINE: 'line',
    LINK: 'link',
    LOOKOUT: 'lookout',
    LOOP: 'loop',
    MALL: 'mall',
    MANOR: 'manor',
    MAZE: 'maze',
    MEADOW: 'meadow',
    MEWS: 'mews',
    MONTEE: 'montee',
    MOOR: 'moor',
    MOUNT: 'mount',
    MOUNTAIN: 'mountain',
    ORCHARD: 'orchard',
    PARADE: 'parade',
    PARC: 'parc',
    PARK: 'park',
    PARKWAY: 'parkway',
    PASSAGE: 'passage',
    PATH: 'path',
    PATHWAY: 'pathway',
    PINES: 'pines',
    PLACE: 'place',
    PLATEAU: 'plateau',
    PLAZA: 'plaza',
    POINT: 'point',
    POINTE: 'pointe',
    PORT: 'port',
    PRIVATE: 'private',
    PROMENADE: 'promenade',
    QUAI: 'quai',
    QUAY: 'quay',
    RAMP: 'ramp',
    RANG: 'rang',
    RANGE: 'range',
    RIDGE: 'ridge',
    RISE: 'rise',
    ROAD: 'road',
    RONDPOINT: 'rondpoint',
    ROUTE: 'route',
    ROW: 'row',
    RUE: 'rue',
    RUELLE: 'ruelle',
    RUN: 'run',
    SENTIER: 'sentier',
    SQUARE: 'square',
    STREET: 'street',
    SUBDIVISION: 'subdivision',
    TERRACE: 'terrace',
    TERRASSE: 'terrasse',
    THICKET: 'thicket',
    TOWERS: 'towers',
    TOWNLINE: 'townline',
    TRAIL: 'trail',
    TURNABOUT: 'turnabout',
    VALE: 'vale',
    VIA: 'via',
    VIEW: 'view',
    VILLAGE: 'village',
    VILLAS: 'villas',
    VISTA: 'vista',
    VOIE: 'voie',
    WALK: 'walk',
    WAY: 'way',
    WHARF: 'wharf',
    WOOD: 'wood',
    WYND: 'wynd',
};

const StreetTypeEnum = types.enumeration('StreetType', Object.values(STREET_TYPES));

export {
    StreetTypeEnum,
};
export default STREET_TYPES;
