import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/save-document`;

/**
 * @param { object } document
 * @param {string } documentType
 * @returns {Promise<{ profile?: any }>} profile without documents node inside
 */
async function pushDocument(document, documentType) {
    return (await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                document,
                documentType,
                docTokenRequested: false,
            }),
        },
    })).data ?? {}; // expecting response.data to be { profile: null | object }
}

export default pushDocument;
export {
    API_ENDPOINT,
};
