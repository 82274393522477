import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


/**
* Retrieve our location given our IP.
* @returns {Promise}
*/
export default async function fetchGeoLocationFromIp() {
    // Error handling occurs within fetchJSON
    const location = await fetchJSON(Config.IP_LOC_API_URL);

    if (
        !location
        || !location.city
        || !location.province
        // v2 of ip-to-loc api
        || location.country && location.country.iso_code !== 'CA'
    ) {
        return null;
    }

    return {
        city: location.city.names.en,
        province: location.province.code,
    };
}
