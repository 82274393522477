import {
    isArrayType,
    isFrozenType,
    isIdentifierType,
    isMapType,
    isModelType,
    isOptionalType,
    isPrimitiveType,
    isReferenceType,
    isRefinementType,
    isUnionType,
} from 'mobx-state-tree';


function convertMSTToJSONSchema(tree) {
    // Json Schema needs Array or Object as root
    if (!isArrayType(tree) && !isModelType(tree)) {
        throw new Error('Please use Array or Model for root');
    }
    try {
        return generateJSONSchema(tree).schema;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
    }
}

function generateJSONSchema(tree) {
    if (tree == null || tree.constructor == null) {
        throw new Error('Wrong input - it\'s not even class');
    }

    // NOTE: should check high-order wrappers before checking any real types
    //       this is to unwrap the utility layer before processing the real types
    //       ex) type.maybeNull(type.string) -> isUnionType: true, isPrimitiveType: true
    //           Before processing type.string, type.maybeNull should be processed
    //           In this example, Union type is the "high-order wrapper"
    //           as opposed to types.string, which does not have any child types
    // NOTE: the high-order wrappers = utility type + complex type
    //      Utility = types.optional, Union(types.union, types.maybe, types.maybeNull)
    //           types.enumeration, types.frozen, types.reference, types.refinement,
    //           types.snapshotProcessor
    //      Complex = types.model, types.array, types.map
    //      Simplest = Primitives(types.string, types.number, ...)
    //      Exception = types.literal --> actually utility type, but can be treated as Simplest due to its Class structure
    //                 types.identifier --> actually utility type, but can be treated as Simplest due to its Class structure
    if (isSnapshotProcessor(tree)) {
        return generateSnapshotProcessorTypeSchema(tree);
    }
    if (isStrictOptionalType(tree)) {
        return generateOptionalTypeSchema(tree);
    }
    if (isStrictUnionType(tree)) {
        return generateUnionTypeSchema(tree);
    }
    if (isReferenceType(tree)) {
        return generateIdentifierReferenceTypeSchema(tree);
    }
    if (isRefinementType(tree)) {
        return generateRefinementTypeSchema(tree);
    }
    if (isFrozenType(tree)) {
        return generateFrozenTypeSchema(tree);
    }

    // complex types
    if (isModelType(tree)) {
        return generateModelTypeSchema(tree);
    }
    if (isArrayType(tree)) {
        return generateArrayTypeSchema(tree);
    }
    if (isMapType(tree)) {
        return generateMapTypeSchema(tree);
    }

    // simple types
    if (isPrimitiveType(tree)) {
        return generatePrimitiveTypeSchema(tree);
    }
    if (isCustomType(tree)) { // our own custom types
        return generateCustomTypeSchema(tree);
    }
    if (isStrictLiteralType(tree)) {
        return generateLiteralTypeSchema(tree);
    }
    if (isIdentifierType(tree)) {
        return generateIdentifierTypeSchema(tree);
    }
    if (isCoreTypeNull(tree)) {
        return generateNullTypeSchema(tree);
    }
    if (isCoreTypeUndefined(tree)) {
        throw new Error('types.undefined can not be used with JSON schema, please use types.maybe');
    }
           
    throw new Error(`Not implemented for this tree: ${JSON.stringify(tree)}`);
}

function generateModelTypeSchema(tree) {
    const schema = { type: 'object' };
    // types.model('NameGoesHere', {}) --> how to use custom name
    // if you don't specify, it will be set to 'AnonymousModel'
    if (tree.name !== 'AnonymousModel') {
        schema.title = tree.name;
    }
    schema.properties = {};
    for (const propertyName in tree.properties) {
        const { schema: subTypeSchema, isRequired } = generateJSONSchema(tree.properties[propertyName]);
        schema.properties[propertyName] = subTypeSchema;
        if (isRequired !== false) {
            // unless it's 'false', consider it as required
            if (schema.required == null) {
                schema.required = [];
            }
            schema.required.push(propertyName);
        }
    }
    return { schema };
}

// MapType is structure where key and value are paired
// Problem is that "key" is arbitrary value that's not defined, so we can't define JSON schema
function generateMapTypeSchema(tree) {
    const schema = { type: 'object' };
    schema.description = tree.name;

    // Note that since MST v3 types.array and types.map are wrapped in types.optional by default,
    // with [] and {} set as their default values, respectively.
    schema.default = {};

    // we can define the type of "value" whereas we can't do for "key"
    // "additionalProperties" is to define type of "value" without defining "key"
    const { schema: subSchema } = generateJSONSchema(tree._subType);
    schema.additionalProperties = subSchema;

    return { schema };
}

/**
 * Below could be Union type
 * types.enumeration = Union(Literal, Literal, ...),
 * types.maybe = Union (SomeType, optionalUndefinedType)
 * types.maybeNull = Union(SomeType, optionalNullType)
 */
function generateUnionTypeSchema(tree) {
    let schema = null;

    if (tree._types.length === 0) {
        throw new Error('Union type should have 1 or more sub-types');
    } else {
        // types.enumeration is Union( LiteralType[] )
        const literalTypes = tree._types.filter(isStrictLiteralType);
        if (tree._types.length === literalTypes.length) {
            const type = getJSType(tree._types[0].value); // MST doesn't store the real type of Literal value
            schema = { type };
            schema.enum = [];
            for (const property of tree._types) {
                if (type !== getJSType(property.value)) {
                    // this is forbidden for MST types.enumeration to have several different types of values
                    throw new Error(`Different type in enum values: ${type}, ${getJSType(property.value)}`);
                }
                const { schema: subSchema } = generateLiteralTypeSchema(property);
                schema.enum.push(subSchema);
            }
            return { schema };
        }

        // types.maybe is Union( someType, undefined )
        const undefinedTypes = tree._types.filter(tree => isOptionalUndefined(tree) || isCoreTypeUndefined(tree));
        const someTypes = tree._types.filter(tree => !isOptionalUndefined(tree) && !isCoreTypeUndefined(tree));

        if (undefinedTypes.length > 1 || someTypes.length === 0) {
            throw new Error('impossible');
        }

        if (someTypes.length === 1 && undefinedTypes.length === 0) {
            const property = someTypes[0];
            const { schema: subSchema } = generateJSONSchema(property);
            return { schema: subSchema };
        } else if (someTypes.length === 1 && undefinedTypes.length === 1) {
            // this is equivalent to types.maybe
            const property = someTypes[0];
            const { schema: subSchema } = generateJSONSchema(property);
            return { schema: subSchema, isRequired: false };
        } else if (undefinedTypes.length === 1) {
            schema = {};
            schema.anyOf = [];
            // NOTE: avoid using oneOf
            // oneOf is to make sure data is valid against only one of them
            // ex) Union( ModelA, ModelB ), sometime an object can be valid both Models
            for (const property of someTypes) {
                const { schema: subSchema } = generateJSONSchema(property);
                schema.anyOf.push(subSchema);
            }
            return { schema, isRequired: false };
        } else {
            // undefinedTypes.length === 0
            schema = {};
            schema.anyOf = [];
            // NOTE: avoid using oneOf
            // oneOf is to make sure data is valid against only one of them
            // ex) Union( ModelA, ModelB ), sometime an object can be valid both Models
            for (const property of someTypes) {
                const { schema: subSchema } = generateJSONSchema(property);
                schema.anyOf.push(subSchema);
            }
            return { schema };
        }
    }
}
function generateOptionalTypeSchema(tree) {
    // MST can generate OptionalType<null> and OptionalType<undefined>
    // OptionalType<undefined> is only possible from usage of types.maybe at this time being
    if (isCoreTypeUndefined(tree._subtype)) {
        throw new Error('types.maybe mush have been dealt in generateUnionTypeSchema, check if MST introduces new feature');
    }
    // this is to prevent generating non-making-sense schema { type: 'null', default: null }
    if (isCoreTypeNull(tree._subtype)) {
        return { schema: { type: 'null' } };
    }

    const { schema } = generateJSONSchema(tree._subtype);
    schema.default = runIfFunction(tree._defaultValue);
    return { schema };
}
function generatePrimitiveTypeSchema(tree) {
    return { schema: { type: tree.name } };
}
function generateNullTypeSchema(tree) {
    return { schema: { type: tree.name } };
}
function generateArrayTypeSchema(tree) {
    const schema = { type: 'array' };
    const { schema: subSchema } = generateJSONSchema(tree._subType);
    schema.items = subSchema;
    // Note that since MST v3 types.array and types.map are wrapped in types.optional by default,
    // with [] and {} set as their default values, respectively.
    schema.default = [];
    return { schema };
}
function generateSnapshotProcessorTypeSchema(tree) {
    return generateJSONSchema(tree._subtype);
}
function generateLiteralTypeSchema(tree) {
    return { schema: tree.value };
}
// our own custom type
function generateCustomTypeSchema(tree) {
    // impossible to know the equivalent JS type to MST CustomType
    // although we can assume it's string if it's IsoDate
    const type
        = tree.name === 'IsoDate' || tree.name === 'IsoDateTimeWithOffset' || tree.name === 'momentDate'
            ? 'string'
            : [ 'number', 'string', 'boolean', 'object', 'array', 'null' ];

    return { schema: { type, description: tree.name } };
}
function generateIdentifierTypeSchema(tree) {
    // identifierType could be
    // - types.identifier (string)
    // - types.identifierNumber (number)
    return { schema: { type: tree.validType, description: tree.name } };
}
// for identifierReferenceType, you can use snapshot of the referenced schema or the reference id itself
function generateIdentifierReferenceTypeSchema(tree) {
    // get the schema of the reference
    const { schema: referencedSchema } = generateJSONSchema(tree.targetType);

    // types.reference() could store identifier as value in the document
    // Although usually it would be string or number,
    // custom-reference type can use many things for identifier
    const referenceIdSchema = {
        type: [ 'string', 'number', 'object', 'array' ],
        description: tree.name,
    };

    const schema = {
        anyOf: [
            referencedSchema,
            referenceIdSchema,
        ],
    };
    
    schema.description = '[ReferenceType]';
    
    if (tree.targetType.name !== 'AnonymousModel') {
        schema.title = tree.targetType.name;
    }
    return { schema };
}
function generateRefinementTypeSchema(tree) {
    const { schema: subSchema } = generateJSONSchema(tree._subtype);
    let description = 'refinement';
    if (typeof tree._predicate === 'function') {
        description = `refinement: ${tree._predicate.toString().substring(0, 20)}`;
    }
    subSchema.description = description;
    return { schema: subSchema };
}
// types.frozen(subType? | defaultValue?), if default value is given, it generates optional (frozen, defaultValue)
function generateFrozenTypeSchema(tree) {
    const schema = tree.subType // subType could be 'undefined' if defaultValue was used
        ? generateJSONSchema(tree.subType).schema
        : {};
    schema.description = 'Frozen';
    return { schema };
}

// strange behaviour of type-checking functions from MST
// ex) types.maybeNull(types.string) --> isUnionType: true, isOptionalType: true, isPrimitiveType: true
// ex) types.maybeNull(types.enumeration(NumberOfStoriesLegacy)) --> isUnionType: true, isOptionalType: true, isLiteralType: true
function isStrictUnionType(tree) {
    return isUnionType(tree) && !!tree._types;
}
function isStrictOptionalType(tree) {
    return isOptionalType(tree) && !!tree._subtype && tree.optionalValues !== undefined;
}
function isSnapshotProcessor(tree) {
    return !!tree._processors;
}
function isStrictLiteralType(tree) {
    // tree.flags is available only to the lowest level Class
    // check TypeFlags in file below
    // mobx-state-tree/packages/mobx-state-tree/src/core/type/type.ts
    // https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mobx-state-tree/src/core/type/type.ts
    return tree.flags === 1 << 4;
}
function isCoreTypeNull(tree) {
    // tree.flags is available only to the lowest level Class
    // check TypeFlags in file below
    // mobx-state-tree/packages/mobx-state-tree/src/core/type/type.ts
    // https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mobx-state-tree/src/core/type/type.ts
    return tree.flags === 1 << 15;
}
function isCoreTypeUndefined(tree) {
    // tree.flags is available only to the lowest level Class
    // check TypeFlags in file below
    // mobx-state-tree/packages/mobx-state-tree/src/core/type/type.ts
    // https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mobx-state-tree/src/core/type/type.ts
    return tree.flags === 1 << 16;
}
function isOptionalUndefined(tree) {
    return isStrictOptionalType(tree) && isCoreTypeUndefined(tree._subtype);
}
function isCustomType(tree) {
    // tree.flags is available only to the lowest level Class
    // check TypeFlags in file below
    // mobx-state-tree/packages/mobx-state-tree/src/core/type/type.ts
    // https://github.com/mobxjs/mobx-state-tree/blob/master/packages/mobx-state-tree/src/core/type/type.ts
    return tree.flags === 1 << 18;
}
// find types in JavaScript
function getJSType(value) {
    if (Array.isArray(value)) {
        return 'array';
    }
    const type = Object.prototype.toString.apply(value);

    switch (type) {
        case '[object String]':
            return 'string';
        case '[object Number]':
            return 'number';
        case '[object Boolean]':
            return 'boolean';
        case '[object Null]':
            return 'null';
        case '[object Undefined]':
            return 'undefined'; // this is problematic, since JSON schema doesn't allow 'undefined' type
        case '[object Function]':
            return 'function';
        default:
            return 'string';
    }
}

function runIfFunction(value) {
    return typeof value === 'function' ? value() : value;
}

export default convertMSTToJSONSchema;
export {
    isSnapshotProcessor,
    isStrictLiteralType,
    isStrictUnionType,
    isStrictOptionalType,
    isCoreTypeNull,
    isCoreTypeUndefined,
    isCustomType,
};