export default {
    'application.occupant' : {
        'read': true,
        'write': true,
        'mutate': true,
        'model': 'Contact',
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
                // question for dob is "What is the date of birth for the eldest homeowner?"
                // dob should be excluded from here
                'properties': [ 'email', 'phone', 'firstName', 'lastName' ],
            },
            'fromProfile' : {
                'enable': true,
                'overwrite': false,
                // question for dob is "What is the date of birth for the eldest homeowner?"
                // dob should be excluded from here
                'properties': [ 'email', 'phone', 'firstName', 'lastName' ],
            },
            'profilePath': '$',
        },
    },
};
