const FONT_NAME = 'Gordita';
const TEXT_PREFIX = 'rh-text';
const TITLE_PREFIX = 'rh-title';
const DISPLAY_PREFIX = 'rh-display';

const TEXT_SIZE_CLASSES = {
    TEXT_2XS: `${TEXT_PREFIX}-2xs`,
    TEXT_XS: `${TEXT_PREFIX}-xs`,
    TEXT_S: `${TEXT_PREFIX}-s`,
    TEXT_M: `${TEXT_PREFIX}-m`,
    TEXT_L: `${TEXT_PREFIX}-l`,
    TEXT_XL: `${TEXT_PREFIX}-xl`,
};

const TITLE_SIZE_CLASSES = {
    TITLE_3XS: `${TITLE_PREFIX}-3xs`,
    TITLE_2XS: `${TITLE_PREFIX}-2xs`,
    TITLE_XS: `${TITLE_PREFIX}-xs`,
    TITLE_S: `${TITLE_PREFIX}-s`,
    TITLE_M: `${TITLE_PREFIX}-m`,
    TITLE_L: `${TITLE_PREFIX}-l`,
    TITLE_2XL: `${TITLE_PREFIX}-2xl`,
    TITLE_3XL: `${TITLE_PREFIX}-3xl`,
    TITLE_4XL: `${TITLE_PREFIX}-4xl`,
};

const DISPLAY_CLASSES = {
    DISPLAY_BLOCK: `${DISPLAY_PREFIX}-block`,
    DISPLAY_INLINE_BLOCK: `${DISPLAY_PREFIX}-inline-block`,
    DISPLAY_NONE: `${DISPLAY_PREFIX}-none`,
};

const WEIGHT_CLASSES = {
    WEIGHT_REGULAR: 'weight-regular',
    WEIGHT_MEDIUM: 'weight-medium',
    WEIGHT_BOLD: 'weight-bold',
};

const TEXT_ALIGN_CLASSES = {
    TEXT_ALIGN_LEFT: 'rh-text-align-left',
    TEXT_ALIGN_CENTER: 'rh-text-align-center',
    TEXT_ALIGN_RIGHT: 'rh-text-align-right',
};

export {
    TEXT_PREFIX,
    TITLE_PREFIX,
    TEXT_SIZE_CLASSES,
    TITLE_SIZE_CLASSES,
    DISPLAY_CLASSES,
    WEIGHT_CLASSES,
};
export default {
    FONT_NAME,
    FONT_FAMILY_STACK: `'${FONT_NAME}', sans-serif`,

    WEIGHTS: {
        REGULAR: 400,
        MEDIUM: 500,
        BOLD: 700,
    },

    CLASSES: {
        SHOW_DOT: 'showDot',

        LEADING_XS: 'leading-xs',
        LEADING_S: 'leading-s',
        LEADING_M: 'leading-m',
        LEADING_L: 'leading-l',
        LEADING_XL: 'leading-xl',

        UPPERCASE: 'rh-text-uppercase',

        ...TEXT_SIZE_CLASSES,
        ...TITLE_SIZE_CLASSES,

        ...DISPLAY_CLASSES,

        ...WEIGHT_CLASSES,

        ...TEXT_ALIGN_CLASSES,

        RH_CONTENT: 'rh-content',

        TAG: 'rh-tag',
        ANCHOR_WITH_ICON: 'rh-anchor-with-icon',

        NO_WRAP: 'rh-no-wrap',

        LINE_THROUGH: 'rh-line-through',
    },
};
