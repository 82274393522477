import queryString from 'query-string';

import AFFILIATE_QUERY_PARAM_KEYS from '../definitions/AffiliateQueryParamKeys';

/**
 * Gets affiliate information present within our `window.location` query parameters.
 * @returns {object|null}
 */
export default function getAffiliateData() {
    if (typeof window !== 'object') {
        return null;
    }

    // Pull all the query parameters with affiliate information.
    const params = queryString.parse(window.location.search);

    // Create an object with all available query param key information
    const values = Object.values(AFFILIATE_QUERY_PARAM_KEYS).reduce((data, currentKey) => {
        if (params?.[currentKey]) {
            data[currentKey] = params[currentKey];
        }

        return data;
    }, {});

    // Make it easier to use externally; return null if we don't have anything.
    return Object.values(values).some(v => v)
        ? values
        : null;
}
