const MessageBannerStyle = {
    DEFAULT: 'default', // the style before [PRO-614] versatile Message Banner
    COMPACT: 'compact', // the style introduced from [PRO-614] versatile Message Banner
    // border: none
    // smaller padding on main container and CTA button
    // .title is no longer use weight:500 by default
    // and more
};

const MessageBannerVariants = {
    // Only four styles moving forward with Glamazon
    GENERAL: 'general',
    SUCCESS: 'success',
    ALERT: 'alert',
    ERROR: 'error',

    // Keep existing ones to not break current usage until refactor is done
    WARNING: 'warning',
    INFORMATIONAL: 'informational',
    NOTIFICATION: 'notification',
};

const MessageBannerCTAVariants = {
    PRIMARY_BUTTON: 'primaryButton',
    ALTERNATIVE_BUTTON: 'alternativeButton',
    PRIMARY_ANCHOR: 'primaryAnchor',
    ALTERNATIVE_ANCHOR: 'alternativeAnchor',
    ANCHOR_WITH_ICON: 'anchorWithIcon',
};
export {
    MessageBannerStyle,
    MessageBannerVariants,
    MessageBannerCTAVariants,
};