import DOCUMENTS from '../definitions/Documents';


/**
 * @param {string} type dot-delimited path ex) 'insurance.home'
 * @returns {Array<object>} array of document definition object ex) [ { TYPE: ..., ...} ]
 */
function getDocumentDefinitionByType(type) {
    const found = Object.values(DOCUMENTS).find(doc => doc.TYPE === type);

    if (found == null) {
        throw new RangeError(`Can not find the type in Document definitions: ${type}`);
    }

    return found;
}

export default getDocumentDefinitionByType;
