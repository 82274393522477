import { onPatch, onSnapshot, types } from 'mobx-state-tree';

import { LanguageEnum } from '../definitions/Languages';
import { GenderEnum } from '../definitions/Genders';
import partiallyUpdateModel from '../functions/partiallyUpdateModel';
import IsoDate from '../types/IsoDate';
import EmailAddress from '../types/EmailAddress';
import Address from './Address';


const VALIDATION_FIELDS = {
    name: 'name',
    phone: 'phone',
    email: 'email',
};

const Score = types.refinement(
    'Score',
    types.number,
    value => value >= 0 && value <= 1,
);

const ValidationScores = types
    .model('ValidationScores', {
        [VALIDATION_FIELDS.name]: types.maybe(Score),
        [VALIDATION_FIELDS.phone]: types.maybe(Score),
        [VALIDATION_FIELDS.email]: types.maybe(Score),
    })
    .actions(self => ({
        resetScore(type) {
            self[type] = undefined;
        },
    }));

const Contact = types
    .model('Contact', {
        firstName: types.maybe(types.string),
        middleName: types.maybe(types.string),
        lastName: types.maybe(types.string),
        email: types.maybe(EmailAddress),
        phone: types.maybe(types.string),
        phoneExt: types.maybe(types.string),
        secondaryPhone: types.maybe(types.string),
        secondaryPhoneExt: types.maybe(types.string),
        homeAddress: types.maybe(Address),
        dob: types.maybe(IsoDate),
        language: types.maybe(LanguageEnum),
        gender: types.maybe(GenderEnum),
        validationScores: types.maybe(ValidationScores),
    })
    .actions(self => ({
        afterCreate() {
            enableAutoValidationReset(self);
        },
        update(values) {
            partiallyUpdateModel(self, values);
        },
    }));

function enableAutoValidationReset(model) {
    let resetValidationFields = {
        [VALIDATION_FIELDS.name]: false,
        [VALIDATION_FIELDS.phone]: false,
        [VALIDATION_FIELDS.email]: false,
    };

    // State mutated in onPatch isn't saved, so we use flags 
    //  to let onSnapshot know what fields to reset
    onPatch(model, patch => {
        if (patch.path === '/firstName' || patch.path === '/lastName') {
            resetValidationFields[VALIDATION_FIELDS.name] = true;
        }

        if (patch.path === '/phone') {
            resetValidationFields[VALIDATION_FIELDS.phone] = true;
        }

        if (patch.path === '/email') {
            resetValidationFields[VALIDATION_FIELDS.email] = true;
        }
    });

    onSnapshot(model, () => {
        for (const [ field, shouldReset ] of Object.entries(resetValidationFields)) {
            if (shouldReset) {
                model.validationScores?.resetScore(field);
                resetValidationFields[field] = false;
            }
        }
    });
}

export default Contact;
