const BusinessUnitsEN = {
    MORTGAGES: 'mortgages',
    CREDIT_CARDS: 'credit-cards',
    BANKING: 'banking',
    INVESTING: 'investing',
    INSURANCE: 'insurance',
    CRYPTO: 'crypto',
    ALL: 'all',
};

const BusinessUnitsFR = {
    MORTGAGES: 'hypotheques',
    CREDIT_CARDS: 'cartes-de-credit',
    BANKING: 'comptes',
    INVESTING: 'placements',
    INSURANCE: 'assurance',
    CRYPTO: 'crypto', // TODO: update when it's defined
    ALL: 'tout',
};

export default BusinessUnitsEN;
export { BusinessUnitsFR };
