import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ANALYTICS_KEY, ANALYTICS_ATTRIBUTE_NAME } from '../definitions/Analytics';
import AdTypes from '../definitions/AdTypes';
import BusinessUnits from '../definitions/BusinessUnits';


const PageSettingsContext = React.createContext(createPageSettings());

function usePageSettings() {
    return useContext(PageSettingsContext);
}

function createPageSettings(values) {
    return {
        seoMeta: {},

        businessUnit: BusinessUnits.ALL,

        hasAdAboveMenu: false,

        adCounts: {
            [AdTypes.Leaderboard]: 0,
            [AdTypes.Bigbox]: 0,
        },

        ...values,
    };
}

function PageSettingsProvider({ value, className, children, hasAnalytics, ...otherProps }) {
    return (
        <PageSettingsContext.Provider
            value={createPageSettings(value)}
        >
            <Choose>
                {/* This condition was added specifically for Gutenberg editing. We don't want this extra div. */}
                <When condition={hasAnalytics}>
                    <div
                        ref={self => self && (self[ANALYTICS_KEY] = value)}
                        className={classNames(className, ANALYTICS_ATTRIBUTE_NAME)}
                        {...otherProps}
                    >
                        {children}
                    </div>
                </When>
                <Otherwise>
                    {children}
                </Otherwise>
            </Choose>
        </PageSettingsContext.Provider>
    );
}

PageSettingsProvider.propTypes = {
    value: PropTypes.object,
    className: PropTypes.string,
    hasAnalytics: PropTypes.bool,
    children: PropTypes.any,
};

PageSettingsProvider.defaultProps = {
    value: undefined,
    className: undefined,
    hasAnalytics: true,
    children: undefined,
};

export {
    PageSettingsProvider,
    usePageSettings,
};
