import queryString from 'query-string';

import getAffiliateDecoratedURL from './getAffiliateDecoratedURL';


/**
 * Navigate the browser to a new page, optionally in a new tab.
 *
 * @param {string} url URL of new page
 * @param {object} options
 * @param {boolean} [options.shouldClearUnload=true] clear window.onbeforeunload first?
 * @param {boolean} [options.isNewTab=false] open in new tab?
 * @param {boolean} [options.isRedirect=false] redirect to new page without adding to history?
 * @param {object} [options.queryParams=undefined] object of query params to append to url
 */

function navigateWindowTo(
    url,
    options = {
        shouldClearUnload: true,
        isNewTab: false,
        isRedirect: false,
        queryParams: undefined,
    },
) {
    if (typeof window === 'undefined') {
        throw new Error('[navigateWindowTo.js] Trying to navigate when window is undefined');
    }

    if (!options.isNewTab && options.shouldClearUnload) {
        // We want to make sure the pop-up doesn’t occur if set
        // since the user will be navigated to a new page
        window.onbeforeunload = null;
    }

    // append affiliate data to url if one is available (e.g. as a widget); otherwise, the url is unmodified
    url = getAffiliateDecoratedURL(url);

    if (options.queryParams) {
        const { url: parsedUrl, query } = queryString.parseUrl(url);

        // add properties within options.queryParams to URL’s search string
        Object.entries(options.queryParams).forEach(([ key, value ]) => {
            query[key] = value;
        });

        url = queryString.stringifyUrl({ url: parsedUrl, query });
    }

    if (options.isRedirect) {
        window.top.location.replace(url);
    } else if (options.isNewTab) {
        window.open(url, '_blank');
    } else {
        window.top.location.assign(url);
    }
}

export default navigateWindowTo;
