import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withSize } from 'react-sizeme';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import MessageBannerPropType from '../definitions/MessageBannerPropType';
import {
    MessageBannerStyle as MESSAGE_BANNER_STYLE,
    MessageBannerCTAVariants as MESSAGE_BANNER_CTA_VARIANTS,
} from '../definitions/MessageBannerDefinitions';

import Colours from '../definitions/Colours';
import Sizes from '../definitions/Sizes';
import MessagePropType from '../definitions/MessagePropType';
import noticeError from '../functions/noticeError';
import renderMessage from '../functions/renderMessage';
import getIconSizeClassName from '../functions/getIconSizeClassName';
import getBGColourClassName from '../functions/getBGColourClassName';
import getBorderColourClassName from '../functions/getBorderColourClassName';
import IconChooser from './IconChooser';
import PrimaryButton from './PrimaryButton';
import AlternateButton from './AlternateButton';
import AnchorWithIcon from './AnchorWithIcon';
import IconArrowRight from './icons/IconArrowRight';
import IconX from './icons/IconX';
import PrimaryAnchor from './PrimaryAnchor';
import AlternativeAnchor from './AlternateAnchor';
import { SIZES as PARAGRAPH_SIZES } from './Paragraph';


function MessageBanner({
    variant,
    messageBannerStyle,

    iconKey,
    iconSize,
    iconAlignment,

    title,
    message,
    messageSize,

    ctaVariant,
    ctaMessage,
    ctaDataName,
    onClick,
    href,

    closeButtonSize,
    onClose,

    className,
    size,

    ...otherProps
}) {
    const intl = useIntl();

    const themeColours = MESSAGE_BANNER_COLOURS[variant];

    // this is an either/or situation (and we hope we don’t add another enumeration within messageBannerStyle)
    const isStyleDefault = messageBannerStyle === MESSAGE_BANNER_STYLE.DEFAULT;
    const isStyleCompact = !isStyleDefault;

    const shouldHideIcon = isStyleCompact && size?.width < 400; // undefined < 400 --> false

    const iconAlignmentWithDefaultValue = iconAlignment ?? (isStyleCompact
        ? 'firstLine'
        : 'center');

    const iconSizeWithDefaultValue = iconSize ?? (isStyleCompact
        ? 'XS'
        : 'S');

    const messageClassName = getParagraphSizeClassName(messageSize);

    const hasCTAButton = !!ctaMessage || !!href;


    return (
        <MessageContainer
            themeColours={themeColours}
            isStyleDefault={isStyleDefault}
            isStyleCompact={isStyleCompact}

            hasTitle={!!title}
            shouldHideIcon={shouldHideIcon}
            hasIcon={!!iconKey}
            iconSizeRem={Sizes.ICONS[iconSizeWithDefaultValue]}
            iconAlignment={iconAlignmentWithDefaultValue}
            hasCTAButton={hasCTAButton}
            className={classNames(className, 'rh-position-relative rh-display-grid rh-box-sizing-border-box', {
                // padding-top/bottom 1rem is to support existing use cases
                'rh-py-1 rh-px-2 rh-border-width-1px rh-border-style-solid': isStyleDefault,
                'rh-p-1_25 rh-border-radius-2px': isStyleCompact,
                [getBGColourClassName(themeColours.backgroundColour)]: themeColours.backgroundColour,
                [getBorderColourClassName(themeColours.borderColour)]: isStyleDefault && themeColours.borderColour,
            })}
            {...otherProps}
        >
            <div className={classNames('container rh-display-flex rh-align-items-flex-start rh-opacity-0', {
                'rh-opacity-1': size?.width != null,
                'rh-mx-auto rh-my-0': isStyleDefault,
            })}
            >
                <div className="content rh-display-flex rh-flex-wrap rh-align-items-center rh-justify-content-center">
                    <div className={
                        classNames('icon-message rh-display-flex rh-flex-nowrap rh-align-items-flex-start', {
                            'rh-my-1 rh-mx-0': isStyleDefault,
                        })}
                    >
                        <If condition={iconKey}>
                            <div className={classNames('icon-container rh-position-relative', {
                                'rh-mr-1': isStyleDefault,
                                'rh-mr-0_75': isStyleCompact,
                                'rh-display-none': shouldHideIcon,
                            })}
                            >
                                {/* Having one-lined blank message paragraph is to set up the proper height
                                    to align icon with the "first line" of message paragraph
                                    (Icon container)     (Message paragraph)
                                    -------------------  ---------
                                    |Blank     |----| |  |
                                    |One-lined |icon| |  | First line
                                    |<p>       |----| |  |
                                    -------------------  | Second Line
                                                         |
                                                         -----------
                                */}
                                <p
                                    className={classNames('height-matcher', {
                                        'rh-text-l': title && isStyleDefault,
                                        'rh-text-m': title && isStyleCompact,
                                        [messageClassName]: !title,
                                    })}
                                >
                                    {'&nbsp;'}
                                </p>
                                <IconChooser
                                    className="icon rh-position-absolute"
                                    iconKey={iconKey}
                                    outlineWidth="0"
                                    strokeColour={Colours.COCONUT}
                                    fillColour={themeColours.iconFillColour}
                                    // DO NOT define width/height of icon in the class 'icon-container'
                                    // Setting up them in parent level won't align icon with message paragraph
                                    size={iconSizeWithDefaultValue}
                                />
                            </div>
                        </If>

                        <div className="message-wrapper rh-display-flex rh-align-items-flex-start rh-m-0">
                            {/* - the size `title` is not affected by the `messageSize` prop; perhaps it should be?
                                - and if it does, p.height-matcher styling must be changed
                            */}
                            <If condition={title}>
                                <p
                                    className={classNames('title rh-mt-0', {
                                        'rh-text-l weight-medium rh-mb-1': isStyleDefault,
                                        'rh-text-m rh-mb-0_5': isStyleCompact,
                                    })}
                                >
                                    {title}
                                </p>
                            </If>

                            <p
                                className={`message rh-m-0 ${messageClassName}`}
                                {...renderMessage(message, intl)}
                            />
                        </div>
                    </div>

                    <MessageBannerCTA
                        ctaVariant={ctaVariant}
                        ctaMessage={ctaMessage}
                        href={href}
                        onClick={onClick}
                        ctaDataName={ctaDataName}
                        isStyleCompact={isStyleCompact}
                    />
                </div>
            </div>

            <If condition={onClose}>
                <button
                    className="close-button rh-ml-auto rh-p-0 rh-outline-none rh-border-width-0"
                    type="button"
                    onClick={onClose}
                    data-name="message-banner-close"
                >
                    <span className="rh-visually-hidden">
                        <FormattedMessage
                            id="base-ui.MessageBanner.closeButton"
                            defaultMessage="Close banner"
                        />
                    </span>
                    <IconX
                        className={classNames('close-icon', getIconSizeClassName(closeButtonSize))}
                        strokeWidth="1px"
                        outlineWidth="0"
                    />
                </button>
            </If>
        </MessageContainer>
    );
}


MessageBanner.propTypes = MessageBannerPropType;

MessageBanner.defaultProps = {
    messageBannerStyle: MESSAGE_BANNER_STYLE.DEFAULT,
    variant: 'general',

    iconKey: undefined,
    iconSize: undefined,
    iconAlignment: undefined,

    title: undefined,

    messageSize: PARAGRAPH_SIZES.MEDIUM,

    ctaVariant: MESSAGE_BANNER_CTA_VARIANTS.PRIMARY_BUTTON,
    ctaMessage: undefined,
    ctaDataName: undefined,
    onClick: undefined,
    href: undefined,

    onClose: undefined,
    closeButtonSize: 'M',

    className: undefined,
    size: undefined,
};


/**
 * Borrowed from the internal logic within Paragraph.
 *
 * @param {string} messageSize one of PARAGRAPH_SIZES, e.g. 'medium'
 * @return {string} an rh-text-* CSS class name
 */
function getParagraphSizeClassName(messageSize) {
    switch (messageSize) {
        case PARAGRAPH_SIZES.SMALL: return 'rh-text-s';
        case PARAGRAPH_SIZES.MEDIUM: return 'rh-text-m';
        case PARAGRAPH_SIZES.NORMAL: return 'rh-text-l';
        case PARAGRAPH_SIZES.LARGE: return 'rh-text-xl';
        case PARAGRAPH_SIZES.HEADING: return 'rh-title-3xl';
        default: {
            noticeError(new RangeError(`[MessageBanner] unexpected messageSize: ${messageSize}`), {
                expectedValues: `[ “${Object.values(PARAGRAPH_SIZES).join('”, “')}” ]`,
            });
            return 'rh-text-m';
        }
    }
}

function MessageBannerCTA({
    ctaVariant,
    isStyleCompact,
    ctaMessage,
    href,
    onClick,
    ctaDataName,
}) {
    const commonProps = {
        className: classNames('cta-button', {
            'rh-my-1_5 rh-mx-0': !isStyleCompact,           // i.e. isStyleDefault
            'rh-py-0_75 rh-px-1_5 rh-m-0': isStyleCompact,
        }),
        ...isStyleCompact
            ? { size: 'small' }
            : undefined,
        message: ctaMessage,
        onClick: onClick,
        'data-name': ctaDataName,
    };
    const buttonProps = {
        ...commonProps,
        type: 'button',
    };
    const anchorProps = {
        ...commonProps,
        href: href,
    };


    return (
        <Choose>
            <When condition={!!ctaMessage}>
                <Choose>
                    <When condition={ctaVariant === MESSAGE_BANNER_CTA_VARIANTS.PRIMARY_BUTTON}>
                        <PrimaryButton {...buttonProps} />
                    </When>
                    <When condition={ctaVariant === MESSAGE_BANNER_CTA_VARIANTS.ALTERNATIVE_BUTTON}>
                        <AlternateButton {...buttonProps} />
                    </When>

                    <When condition={ctaVariant === MESSAGE_BANNER_CTA_VARIANTS.PRIMARY_ANCHOR}>
                        <PrimaryAnchor {...anchorProps} />
                    </When>
                    <When condition={ctaVariant === MESSAGE_BANNER_CTA_VARIANTS.ALTERNATIVE_ANCHOR}>
                        <AlternativeAnchor {...anchorProps} />
                    </When>

                    <When condition={ctaVariant === MESSAGE_BANNER_CTA_VARIANTS.ANCHOR_WITH_ICON}>
                        {/* when AnchorWithIcon becomes more capable its message text and button
                            should probably be dependent on the size of MessageBanner’s text */}
                        <AnchorWithIcon
                            href={href}
                            message={ctaMessage}
                            data-name={ctaDataName}
                            onClick={onClick}
                        />
                    </When>
                </Choose>
            </When>

            {/* we do not have a ctaMessage, but we have an href… */}
            <Otherwise>
                <If condition={!!href}>
                    <a
                        className="arrow-link rh-display-block rh-icon-s rh-cursor-pointer rh-outline-none"
                        href={href}
                        onClick={onClick}
                    >
                        <IconArrowRight
                            className={classNames('circled-arrow-right-button', getIconSizeClassName('S'))}
                            stroke={Colours.COCONUT}
                            fill={Colours.BLACKBERRY}
                            outlineWidth="0"
                        />
                    </a>
                </If>
            </Otherwise>
        </Choose>
    );
}

MessageBannerCTA.propTypes = {
    ctaVariant: PropTypes.oneOf(
        Object.values(MESSAGE_BANNER_CTA_VARIANTS),
    ).isRequired,
    isStyleCompact: PropTypes.bool.isRequired,
    ctaMessage: MessagePropType,
    href: PropTypes.string,
    onClick: PropTypes.func,
    ctaDataName: PropTypes.string,
};

MessageBannerCTA.defaultProps = {
    ctaMessage: undefined,
    href: undefined,
    onClick: undefined,
    ctaDataName: undefined,
};


const MESSAGE_BANNER_COLOURS = {
    general: {
        backgroundColour: Colours.BLUEBERRY_LIGHTEST,
        iconFillColour: Colours.BLUEBERRY_DARK,
        borderColour: Colours.BLUEBERRY_DARK,
    },
    success: {
        backgroundColour: Colours.MINT_LIGHT,
        iconFillColour: Colours.MINT_DARKEST,
        borderColour: Colours.MINT_DARKEST,
    },
    alert: {
        backgroundColour: Colours.YUZU_LIGHTEST,
        iconFillColour: Colours.YUZU_DARK,
        borderColour: Colours.YUZU_DARK,
    },
    error: {
        backgroundColour: Colours.WATERMELON_LIGHTEST,
        iconFillColour: Colours.STRAWBERRY_DARK,
        borderColour: Colours.STRAWBERRY_DARK,
    },

    // support current usages but modify colours
    warning: {
        backgroundColour: Colours.YUZU_LIGHTEST,
        iconFillColour: Colours.YUZU_DARK,
        borderColour: Colours.YUZU_DARK,
    },
    notification: {
        backgroundColour: Colours.BLUEBERRY_LIGHTEST,
        iconFillColour: Colours.BLUEBERRY_DARK,
        borderColour: Colours.BLUEBERRY_DARK,
    },
    informational: {
        backgroundColour: Colours.BLUEBERRY_LIGHTEST,
        iconFillColour: Colours.BLUEBERRY_DARK,
        borderColour: Colours.BLUEBERRY_DARK,
    },
};

const COMPACT_ICON_MARGIN_RIGHT = Sizes.SPACING.THREE_QUARTERS;

const MESSAGE_LINE_HEIGHT = Sizes.LINE_HEIGHT.M;

const MessageContainer = styled.aside`
    /* Using 3 columns here so the close button can be right aligned
        and the banner content can still be centred */
    grid-template-columns: 1fr auto 1fr;

    > .container {
        grid-column: 2;
        /* Size me adds an empty div so we need to specify row 1 or else
           the empty div will push this down */
        grid-row: 1;

        // This transition is to use withSize without deteriorating user experience
        // Mobile screen shows icon first and makes it disappear (bad UX)
        // Opacity transition will hide content till the screen sorts out
        // Opacity now set with utility classes - leaving this note here though
        transition: opacity 300ms ease-in;

        max-width: 1220px;

        > .content {
            ${({ hasCTAButton, isStyleDefault }) => hasCTAButton
                && isStyleDefault
                && `
                // default banner style already has additional vertical spacing built in; do not add more
                column-gap: ${Sizes.SPACING.TWO};
            `}

            ${({ hasCTAButton, isStyleCompact }) => hasCTAButton
                && isStyleCompact
                && `
                gap: ${Sizes.SPACING.ONE};
            `}

            // .content has excess padding-left to have CTA appear right under message when it's wrapped under .icon-message
            // --------------------------------
            // | icon(neg-margin)| message    |
            // --------------------------------
            // | content-padding | CTA button |
            // --------------------------------
            ${({ hasIcon, shouldHideIcon, isStyleCompact, iconSizeRem }) => hasIcon
                && !shouldHideIcon
                && isStyleCompact
                && iconSizeRem
                && `
                padding-left: calc(${iconSizeRem} + ${COMPACT_ICON_MARGIN_RIGHT});
            `}

            > .icon-message {
                max-width: 55em;

                // .content has excess padding-left to have CTA appear right under message when it's wrapped under .icon-message
                ${({ hasIcon, shouldHideIcon, iconSizeRem, isStyleCompact }) => hasIcon
                    && !shouldHideIcon
                    && isStyleCompact
                    && `
                    margin-left: calc((${iconSizeRem} + ${COMPACT_ICON_MARGIN_RIGHT}) * -1);
                `}

                > .icon-container {
                    position: relative;

                    flex: 0 0 auto;

                    align-self: ${({ iconAlignment }) => iconAlignment === 'firstLine' ? 'flex-start' : 'center'};
                    width: ${({ iconSizeRem }) => `${iconSizeRem};`};

                    // To support "first-line" alignment between .icon and .message
                    // the height of .icon-container should be the same with line-height of .message
                    // .height-matcher is p with the same size of message (also a p tag)
                    .height-matcher {
                        width: 0;
                        margin: 0;
                        opacity: 0;
                        line-height: ${MESSAGE_LINE_HEIGHT};
                    }

                    .icon {
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                > .message-wrapper {
                    flex-basis: 58rem;
                    min-width: ${Sizes.SPACING.SEVEN};

                    flex-direction: column;

                    > .message {
                        line-height: ${MESSAGE_LINE_HEIGHT};
                    }

                    > .title,
                    > .message {
                        // multi-line support, we do not want extra margin other than line-height
                        > p {
                            margin: 0;
                        }
                    }
                }
            }

            > .cta-button,
            > .arrow-link {
                flex: 0 1 auto;
                align-self: center; // CTA button should be vertically centered
                width: fit-content;
            }

            ${({ isStyleCompact }) => isStyleCompact && `
                > .cta-button {
                    font-size: 0.875rem;
                }
            `}

            > .arrow-link {
                &:hover,
                &:focus {
                    .circled-arrow-right-button {
                        fill: ${Colours.BLUEBERRY_LIGHT};
                    }
                }

                &:active {
                    .circled-arrow-right-button {
                        fill: ${Colours.BLUEBERRY};
                    }
                }
            }
        }
    }

    > .close-button {
        grid-column: 3;
        /* Size me adds an empty div so we need to specify row 1 or else
           the empty div will push this down */
        grid-row: 1;
        align-self: flex-start;

        > .close-icon {
            /* The icon is a bit tall due to space being taken up by the hidden outline.
               This negative margin just accounts for that space */
            margin-top: -0.5em;
            margin-right: -0.5em;

            transition: stroke 300ms;
        }

        &:hover,
        &:focus {
            > .close-icon {
                stroke: ${Colours.BLUEBERRY_DARK};
            }
        }

        &:active {
            > .close-icon {
                stroke: ${Colours.BLUEBERRY_DARKEST};
            }
        }
    }
`;

export default withSize({ noPlaceholder: true })(MessageBanner);
