import {
    IconArrowLazy,
    IconAwardLazy,
    IconBarChartLazy,
    IconBellLazy,
    IconBookLazy,
    IconBriefcaseLazy,
    IconCalculatorLazy,
    IconCalendarLazy,
    IconCakeLazy,
    IconCarLazy,
    IconCartLazy,
    IconCheckmarkLazy,
    IconClockLazy,
    IconConversationLazy,
    IconCreditCardLazy,
    IconDiamondLazy,
    IconDollarLazy,
    IconExclamationMarkLazy,
    IconEyeLazy,
    IconFiltersLazy,
    IconGasTankLazy,
    IconGhostLazy,
    IconGiftLazy,
    IconHeadsetLazy,
    IconHeartLazy,
    IconHourglassLazy,
    IconHouseLazy,
    IconKeyLazy,
    IconLifePreserverLazy,
    IconLightBulbLazy,
    IconLightningLazy,
    IconLockLazy,
    IconMailOpenLazy,
    IconMapleLeafLazy,
    IconMapPinLazy,
    IconMicrophoneLazy,
    IconMinusLazy,
    IconNoDollarLazy,
    IconNoteLazy,
    IconPaperLazy,
    IconPaperStackLazy,
    IconPeopleLazy,
    IconPersonLazy,
    IconPhoneLazy,
    IconPiggyLazy,
    IconPlaneLazy,
    IconPlusLazy,
    IconPodcastMicrophoneLazy,
    IconQuestionMarkLazy,
    IconRatehubLazy,
    IconRefreshLazy,
    IconRobotLazy,
    IconSearchLazy,
    IconShareLazy,
    IconShieldLazy,
    IconSmileLazy,
    IconSpeechBubbleLazy,
    IconSpeedometerLazy,
    IconStarLazy,
    IconStudentLazy,
    IconSuitcaseLazy,
    IconThumbsDownLazy,
    IconThumbsUpLazy,
    IconTrendingLazy,
    IconTrophyLazy,
    IconUmbrellaLazy,
    IconUserLazy,
    IconWalletLazy,
    IconWrenchLazy,
    IconXLazy,
} from '../components/lazyIcons';


export default {
    'none': null,
    'arrow-up': IconArrowLazy,
    'arrow-down': IconArrowLazy,
    'arrow-left': IconArrowLazy,
    'arrow-right': IconArrowLazy,
    'award': IconAwardLazy,
    'bar-chart': IconBarChartLazy,
    'bell': IconBellLazy,
    'book': IconBookLazy,
    'briefcase': IconBriefcaseLazy,
    'calculator': IconCalculatorLazy,
    'calendar': IconCalendarLazy,
    'cake': IconCakeLazy,
    'car': IconCarLazy,
    'cart': IconCartLazy,
    'checkmark': IconCheckmarkLazy,
    'clock': IconClockLazy,
    'conversation': IconConversationLazy,
    'credit-card': IconCreditCardLazy,
    'diamond': IconDiamondLazy,
    'dollar': IconDollarLazy,
    'exclamation-mark': IconExclamationMarkLazy,
    'eye': IconEyeLazy,
    'filters': IconFiltersLazy,
    'gas-tank': IconGasTankLazy,
    'ghost': IconGhostLazy,
    'gift': IconGiftLazy,
    'headset': IconHeadsetLazy,
    'heart': IconHeartLazy,
    'hourglass': IconHourglassLazy,
    'house': IconHouseLazy,
    'key': IconKeyLazy,
    'life-preserver': IconLifePreserverLazy,
    'light-bulb': IconLightBulbLazy,
    'lightning': IconLightningLazy,
    'lock': IconLockLazy,
    'mail-open': IconMailOpenLazy,
    'maple-leaf': IconMapleLeafLazy,
    'map-pin': IconMapPinLazy,
    'microphone': IconMicrophoneLazy,
    'minus': IconMinusLazy,
    'no-dollar': IconNoDollarLazy,
    'note': IconNoteLazy,
    'paper': IconPaperLazy,
    'paper-stack': IconPaperStackLazy,
    'people': IconPeopleLazy,
    'person': IconPersonLazy,
    'phone': IconPhoneLazy,
    'piggy': IconPiggyLazy,
    'plane': IconPlaneLazy,
    'plus': IconPlusLazy,
    'podcast-microphone': IconPodcastMicrophoneLazy,
    'question-mark': IconQuestionMarkLazy,
    'ratehub': IconRatehubLazy,
    'refresh': IconRefreshLazy,
    'robot': IconRobotLazy,
    'search': IconSearchLazy,
    'share': IconShareLazy,
    'shield': IconShieldLazy,
    'smile': IconSmileLazy,
    'speech-bubble': IconSpeechBubbleLazy,
    'speedometer': IconSpeedometerLazy,
    'star': IconStarLazy,
    'student': IconStudentLazy,
    'suitcase': IconSuitcaseLazy,
    'thumbs-down': IconThumbsDownLazy,
    'thumbs-up': IconThumbsUpLazy,
    'trending': IconTrendingLazy,
    'trophy': IconTrophyLazy,
    'umbrella': IconUmbrellaLazy,
    'user': IconUserLazy,
    'wallet': IconWalletLazy,
    'wrench': IconWrenchLazy,
    'x': IconXLazy,
};
