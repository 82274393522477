export { default as validateV2 } from './functions/validate';
export { default as extractV2Metadata } from './functions/extractMetadata';
export { default as migrateFromV1toV2 } from './functions/migrate';

// definitions
export { CREDIT_SCORES } from '../../common/stable';
export { default as SchemaOptions } from './definitions/SchemaOptions';
export { default as CREDIT_CARD_DOC_TYPES } from './definitions/CreditCardDocTypes';
export { default as REWARD_TYPES } from './definitions/RewardTypes';
export { default as SCENARIOS } from './definitions/Scenarios';
export { default as PRODUCT_TYPES } from './definitions/ProductTypes';
export { default as USER_CATEGORIES } from './definitions/UserCategories';
export { default as TRAVEL_FEATURES } from './definitions/TravelFeatures';
export { default as APPROVAL_LIKELIHOOD_SCORES } from './definitions/ApprovalLikelihoodScores';
export { default as SPENDING_CATEGORIES } from './definitions/SpendingCategories';
export { default as SPENDING_CATEGORY_DEFAULTS, TOTAL_DEFAULT_SPENDING } from './definitions/SpendingCategoryDefaults';
export { default as DEFAULT_CARD_PREFERENCES } from './definitions/DefaultCardPreferences';

export { EVENT_TYPES, EVENTS } from './models/Event';

// domain models
export { default as CreditCardDocument } from './models/CreditCardDocument';

export const VERSION = 2;
