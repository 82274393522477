export default function extractExperiments(experiments) {
    // each ACF is in the form { slug: string, variations: [Array] },
    return experiments
        .filter(experiment => experiment.acf)
        .map(experiment => ({
            slug: experiment.acf.slug,
            variations: experiment.acf.variations
                ? experiment.acf.variations.map((variation) =>
                    parseInt(variation.percent),
                )
                : 0, // if there are no variations, always use control
        }));
}
