import dayjs from 'dayjs';


/**
 * Used to validate inputs to date types.
 *
 * @param {string | number | Date | object} value
 * @returns {boolean} is parsable as a date
 */
export default function isValidDate(value) {
    return dayjs.isDayjs(value) || dayjs(value).isValid();
}
