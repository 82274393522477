import IconChooserSizes from '../definitions/IconChooserSizes';
import noticeError from './noticeError';


/**
 * Return an rh- class name that will provide a specific width and height.
 *
 * @param {string} iconSize T-shirt sizing from [ 3XS, 2XS, XS, S, M, L, XL, 2XL, 3XL, 4XL, 5XL, 6XL ]
 * @returns {undefined|string} unrecognized sizes give `undefined`, otherwise a string like 'rh-icon-xs'
 * @example className={classNames('close-icon', getIconSizeClassName(closeButtonSize))}
 */
function getIconSizeClassName(iconSize) {
    if (!iconSize) {
        return undefined;
    }

    if (!IconChooserSizes[iconSize]) {  // add .toUpperCase() if we wanted iconSize to be case-insensitive
        noticeError(new RangeError(`[getIconSizeClass] unexpected icon size “${iconSize}”.`), {
            expectedValues: `[ “${Object.keys(IconChooserSizes).filter(key => key !== 'FILL').join('”, “')}” ]`,
        });
        return undefined;
    }

    return `rh-icon-${iconSize.toLowerCase()}`;
}

export default getIconSizeClassName;
