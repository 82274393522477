import isServerSide from './isServerSide';
import timeoutAsPromise from './timeoutAsPromise';


/**
 * @private
 * [RECURSIVE]
 * Perform a number of re-fetch attempts against an endpoint
 * @param {string} url
 * @param {object} options
 * @param {number} attempts how many times we have tried so far
 * @returns {object}
 */
async function fetchWithRetry(url, options, attempts = 0) {
    // Sleep using a linear back-off.
    if (attempts > 0) {
        const timeoutDuration = getSleepMSForAttempt(attempts);
        await timeoutAsPromise(timeoutDuration);
    }

    // NOTE: increasing attempt here so we can start at attempt 1
    //  and better calculate when we've reached MAX_ATTEMPTS
    attempts++;

    try {
        const response = await fetch(url, options);

        // Retry the fetch if desired based on the response + attempts
        if (shouldRetryFetch(response, attempts)) {
            console.info(`fetchWithRetry: (attempt ${attempts}) received ${response.status} from ${url}`);  //eslint-disable-line no-console
            return fetchWithRetry(url, options, attempts);
        }

        return response;
    } catch (error) {
        // Retry the fetch if desired based on the error + attempts
        if (shouldRetryFetch(error, attempts)) {
            console.info(`fetchWithRetry: (attempt ${attempts}) caught "${error.message}" while fetching ${url}`);  //eslint-disable-line no-console
            return fetchWithRetry(url, options, attempts);
        }

        // Re-throw if we cannot retry so fetchJSON can handle the error
        throw error;
    }
}


/** 
 *  Based on either the error or response received from the fetch, evaluate whether we should retry.
 *  @param {Error|Response} value - EITHER an Error object thrown by fetch OR a Response object from fetch
 *  @param {number} attempts - total number of times the fetch has been attempted
 *  @returns {boolean} 
 */
function shouldRetryFetch(value, attempts) {
    return isServerSide() 
        ? shouldRetryServerSideFetch(value, attempts)
        : shouldRetryClientSideFetch(value, attempts);
}

// Server side specific retry criteria
const SERVER_SIDE_SLEEP_INTERVAL = 500;
const SERVER_SIDE_MAX_RETRIES = 5;

const SERVER_SIDE_RETRY_RESPONSE_CODES = [
    429,    // Too Many Requests. Retry after we wait a bit.
    502,    // Bad Gateway. Often signals a transient issue within our kubernetes cluster.
    520,    // Custom CMS error code, usually caused by too many requests.
];

const SERVER_SIDE_RETRY_ERROR_NAMES = [
    /* FetchError' is a node operational error in the fetch process
        and separate from our FetchError declared below.
        See the following docs for more information:  
        https://github.com/node-fetch/node-fetch/blob/main/docs/ERROR-HANDLING.md  */
    'FetchError',
];

/**
 * Based on our criteria for server side fetches and the response or error recevied from the fetch,
 *  evaluate whether or not we should retry.
 * @param {Error|Response} value - reponse from fetch or error object received
 * @param {number} attempts - total number of times the fetch has been attempted
 * @returns {Boolean}
 */
function shouldRetryServerSideFetch(value, attempts) {
    // Have we tried too many times?
    if (attempts > SERVER_SIDE_MAX_RETRIES) {
        return false;
    }
    
    // Only retry `fetch`-related errors of a specific type.
    if (value instanceof Error) {
        return SERVER_SIDE_RETRY_ERROR_NAMES.includes(value.name);
    }
    
    // Assume it's a `fetch` response object. Check the status code.
    return SERVER_SIDE_RETRY_RESPONSE_CODES.includes(value.status);
}


// Client side specific retry criteria
// We use a smaller interval and retries since the user has to actively wait for these retries
const CLIENT_SIDE_SLEEP_INTERVAL = 300;
const CLIENT_SIDE_MAX_RETRIES = 3;

const CLIENT_SIDE_RETRY_RESPONSE_CODES = [
    502,    // Bad Gateway. Often signals a transient issue within our kubernetes cluster.
];

/**
 * Based on our criteria for client side fetches and the response code recevied from the fetch,
 *  evaluate whether or not we should retry.
 * @param {Response} value - Response received from fetch
 * @param {number} attempts - total number of times the fetch has been attempted
 * @returns {Boolean}
 */
function shouldRetryClientSideFetch(value, attempts) {
    // Have we tried too many times?
    if (attempts > CLIENT_SIDE_MAX_RETRIES) {
        return false;
    }

    // Assume it's a `fetch` response object. Check the status code.
    return CLIENT_SIDE_RETRY_RESPONSE_CODES.includes(value.status);
}


/**
 * @public
 * Get the number of milliseconds to wait before placing another request
 * @param {number} attempt
 * @returns {number}
 */
function getSleepMSForAttempt(attempt) {
    return attempt * (isServerSide() ? SERVER_SIDE_SLEEP_INTERVAL : CLIENT_SIDE_SLEEP_INTERVAL);
}

export { SERVER_SIDE_MAX_RETRIES, CLIENT_SIDE_MAX_RETRIES }; // for testing
export { getSleepMSForAttempt }; // for testing
export default fetchWithRetry;