import DOCUMENTS from '../definitions/Documents';


/**
 * @param {string} path dot-delimited path ex) 'insurance.home'
 * @returns {object} document definition object ex) { TYPE: ..., }
 */
function getDocumentDefinitionByPath(path) {
    const found = Object.values(DOCUMENTS).find(doc => doc.PATH === path);

    if (found == null) {
        throw new RangeError(`Can not find the path in Documents definition: ${path}`);
    }

    return found;
}

export default getDocumentDefinitionByPath;
