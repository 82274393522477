import Config from '../definitions/Config';
import fetchJSON from './fetchJSON';


const API_ENDPOINT = `${Config.PROFILE_BFF_URL}/retrieve-documents`;

/**
 *
 * @param {{ id: string, documentType: string, schemaVersion?: string }[]} documentIdentifiers
 * @returns {Promise<*>}
 */
async function fetchSavedDocumentDetails(documentIdentifiers) {
    if (!documentIdentifiers || !documentIdentifiers.length) {
        throw new Error(`At least one document identifier is required to fetch document details. received: ${JSON.stringify(documentIdentifiers)}`);
    }

    return (await fetchJSON({
        url: API_ENDPOINT,
        options: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ documentIdentifiers }),
        },
    })).data;
}

export default fetchSavedDocumentDetails;
export {
    API_ENDPOINT,
};
