// Functionality for /src/components/HamburgerAnimationStatic.jsx
// Provided as a separate file to allow for static rendering.

import { ElementAttributes } from '@ratehub/base-ui';
import isBeginElementSupported from '../src/functions/isBeginElementSupported';


let isOpen = false;
let forwardsAnimations = [];
let reverseAnimations = [];
let hamburger = undefined;

function initializeHamburger() {
    const supportsBeginElement = isBeginElementSupported();

    // Potential feature: accept ID as a prop to allow init'ing multiple
    // HamburgerAnimationStatics on a single page. Low need for that now though.
    hamburger = document.querySelector(`#${ElementAttributes.PRIMARY_NAV_HAMBURGER_ID}`);

    if (supportsBeginElement && hamburger) {
        hamburger.addEventListener('click', handleHamburgerClick);

        const animations = hamburger.querySelectorAll('rect animateTransform');

        forwardsAnimations = Array.from(animations).filter(anim => anim.classList.contains('forwards'));
        reverseAnimations = Array.from(animations).filter(anim => anim.classList.contains('reverse'));
    }
}

function handleHamburgerClick() {
    if (!hamburger) {
        return;
    }

    if (isOpen) {
        // fire reverse animations
        reverseAnimations.forEach(anim => {
            anim.beginElement();
        });
    } else {
        // fire forewards animations
        forwardsAnimations.forEach(anim => {
            anim.beginElement();
        });
    }

    const labelClose = hamburger.querySelector('.label-close');
    const labelOpen = hamburger.querySelector('.label-open');

    if (labelClose && labelOpen) {
        labelClose.toggleAttribute('hidden');
        labelOpen.toggleAttribute('hidden');
    }

    isOpen = !isOpen;
}

export default initializeHamburger;
