/** Name of the query parameter we look for for affiliate ID */
const AFFILIATE_ID_QUERY_PARAM_KEY = 'aff_id';

/** 
 * Name of the query parameter which overrides the visitor_id 
 *  This will (within Cloudflare) cause a change of the visitor_id, which allows us to stitch together
 *  Heap sessions between MoneySense and Ratehub. */
const VISITOR_ID_OVERRIDE_QUERY_PARAM_KEY = 'aff_sub';

/**
 * URL for the conversion referrer (the page the user was on when they clicked the widget)
 */
const CONVERSION_REFERRER = 'conversion_referrer';

/**
 * Name of the custom URL key mapped in CMS to a custom url to allow apply redirect service to
 * redirect users to specified custom URL  */
const CUSTOM_APPLY_REDIRECT_SLUG = 'custom_apply_slug';

const AFFILIATE_QUERY_PARAM_KEYS = {
    affiliateId: AFFILIATE_ID_QUERY_PARAM_KEY,
    visitorIdOverride: VISITOR_ID_OVERRIDE_QUERY_PARAM_KEY,
    conversionReferrer: CONVERSION_REFERRER,
    customApplySlug: CUSTOM_APPLY_REDIRECT_SLUG,
};

export default AFFILIATE_QUERY_PARAM_KEYS;
export {
    AFFILIATE_ID_QUERY_PARAM_KEY,
    VISITOR_ID_OVERRIDE_QUERY_PARAM_KEY,
};