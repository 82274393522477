/**
 * Debounced.
 *
 * Implementation inspired by: https://github.com/mobxjs/mobx/issues/1956#issuecomment-534864621
 *
 * Simple debounce implementation; performs trailing debounce;
 * If called multiple times, will only call the debouncedFunction after `delay` ms after the last call.
 *
 * @param {function} fn
 * @param {number} delay in ms
 * @param {function} onCancel function that will be called if the debounced function is cancelled
 *
 * @returns {function} debounced function
 */
export default function debounce(fn, delay = 500, onCancel) {

    let activeTimeout;
    const result = (...args) => {
        result.cancel();
        activeTimeout = setTimeout(() => fn(...args), delay);
    };

    result.cancel = () => {
        clearTimeout(activeTimeout);
        onCancel?.();
    };

    return result;
}
