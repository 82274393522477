import getVisitorId from './getVisitorId';


// FUTURE: combine with getAffiliateDecoratedURL? They do similar things...
//         Current concern is some partners may want values which could conflict with our own affiliate tracking

/* Token to replace with the user's visitor_id */
const VISITOR_ID_REPLACEMENT_TOKEN = '%1$s';

/**
 * Get a copy of a URL with any special tokens replaced by their actual value. Tends to be client-side values.
 * @param {string} url
 * @returns {string}
 */
export default function getTokenReplacedURL(url) {
    if (!url) {
        return url;
    }

    // If we're on the client-side, inject their visitor ID.
    if (typeof window !== 'undefined' && url.includes(VISITOR_ID_REPLACEMENT_TOKEN)) {
        url = url.replace(VISITOR_ID_REPLACEMENT_TOKEN, getVisitorId() ?? '0');
    }

    return url;
}