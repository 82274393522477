import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconArrow({
    outlineWidth = '2px',
    stroke = Colours.BLACKBERRY,
    strokeWidth = '2px',
    fill = Colours.TRANSPARENT,
    direction = DIRECTIONS.LEFT,
    ...otherProps
}) {
    return (
        <IconArrow.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            direction={direction}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="29"
            />
            <path d="M38.78 37.3l-8 8-8-8M30.78 45.3v-28" />
        </IconArrow.Svg>
    );
}

const DIRECTIONS = {
    UP: 'up',
    DOWN: 'down',
    LEFT: 'left',
    RIGHT: 'right',
};

IconArrow.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),

    direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
};


IconArrow.Svg = styled.svg`
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: ${props => props.strokeWidth};
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    ${props => props.direction === DIRECTIONS.UP && `
        transform: rotate(180deg);
    `}
    ${props => props.direction === DIRECTIONS.DOWN && `
        transform: rotate(0deg);
    `}
    ${props => props.direction === DIRECTIONS.LEFT && `
        transform: rotate(90deg);
    `}
    ${props => props.direction === DIRECTIONS.RIGHT && `
        transform: rotate(270deg);
    `}

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconArrow;
