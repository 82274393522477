import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import MessagePropType from '../definitions/MessagePropType';
import renderMessage from '../functions/renderMessage';


function TextElement({ as, message, children, ...otherProps }) {
    const intl = useIntl();

    return (
        <Text
            as={as}
            {...renderMessage(message, intl, children)}
            {...otherProps}
        />
    );
}

// Moved Text outside function component to avoid creating it dynamically
// This avoids SCv5 warning when styled is called inside another component
const Text = styled.span``;

TextElement.propTypes = {
    message: MessagePropType,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    as: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]).isRequired,
};

TextElement.defaultProps = {
    message: undefined,
    children: undefined,
};

export default TextElement;
