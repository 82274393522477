
/**
 * Scroll the viewport to the top of the screen
 * @param {boolean} useSmoothScroll 
 */
export default function scrollToTop(useSmoothScroll = true) {
    window?.scrollTo({
        top: 0,
        behavior: useSmoothScroll ? 'smooth' : 'auto',
    });
}
