import { types } from 'mobx-state-tree';


const PROVINCES = {
    ALBERTA: 'AB',
    NORTHWEST_TERRITORIES: 'NT',
    ONTARIO: 'ON',
    PRINCE_EDWARD_ISLAND: 'PE',
    QUEBEC: 'QC',
    SASKATCHEWAN: 'SK',
    BRITISH_COLUMBIA: 'BC',
    MANITOBA: 'MB',
    NEW_BRUNSWICK: 'NB',
    NEWFOUNDLAND_AND_LABRADOR: 'NL',
    NOVA_SCOTIA: 'NS',
    NUNAVUT: 'NU',
    YUKON: 'YT',
};

const ProvinceEnum = types.enumeration('Province', Object.values(PROVINCES));

export {
    ProvinceEnum,
};
export default PROVINCES;
