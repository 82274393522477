import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Colours from '../../definitions/Colours';


function IconX({ outlineWidth, stroke, strokeWidth, fill, ...otherProps }) {
    return (
        <IconX.Svg
            viewBox="0 0 62 62"
            outlineWidth={outlineWidth}
            strokeColour={stroke}
            strokeWidth={strokeWidth}
            fillColour={fill}
            {...otherProps}
        >
            <circle
                cx="31"
                cy="31"
                r="29"
            />
            <path d="M21.58 20.92L41.5 40.84M41.5 20.92L21.58 40.84" />
        </IconX.Svg>
    );
}

IconX.propTypes = {
    outlineWidth: PropTypes.string,
    stroke: PropTypes.oneOf(
        Object.values(Colours),
    ),
    strokeWidth: PropTypes.string,
    fill: PropTypes.oneOf(
        Object.values(Colours),
    ),
};

IconX.defaultProps = {
    outlineWidth: '2px',
    stroke: Colours.BLACKBERRY,
    strokeWidth: '2px',
    fill: Colours.TRANSPARENT,
};

IconX.Svg = styled.svg`
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: ${props => props.strokeWidth};
    stroke: ${props => props.strokeColour};
    fill: ${props => props.fillColour};

    circle {
        stroke-width: ${props => props.outlineWidth};
    }

    transition:
        stroke 300ms ease,
        fill 300ms ease;
`;

export default IconX;
