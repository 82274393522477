import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { noticeError } from '@ratehub/base-ui';

/**
 * Error boundary component to track uncaught errors that get ignored because of
 * Next.js
 */
class ErrorBoundary extends Component { // Using Component because getDerivedStateFromError doesn't have an equal hook
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { error: error, hasError: true };
    }

    state = {
        error: null,
        hasError: false,
        isNewRelicReady: false,
    };

    componentDidMount() {
        // Note: Previously we had an event listener for unhandledrejection here
        //  New Relic handles this themselves with the script we have in _document.jsx
        //  and having both was causing alerts to fire for code that was not our own since
        //  the stack trace would lead to this listener, not the root of the issue.
        //  E.g. window.addEventListener('unhandledrejection', ...);

        // Waits for New Relic 'agent' field to be populated which indicates New Relic ready state
        // before logging
        let newRelicCheckCount;
        const newRelicReadyInterval = window.setInterval(() => {
            newRelicCheckCount += 1;

            if (newRelicCheckCount >= MAX_NEW_RELIC_CHECK_COUNT) {
                window.clearInterval(newRelicReadyInterval);
                throw new Error('[ErrorBoundary] New Relic loading has timeout');
            } else if (window.newrelic?.info?.agent) {
                window.clearInterval(newRelicReadyInterval);
                this.setState({ isNewRelicReady: true });
            }
        }, 500);
    }

    componentDidUpdate(_prevProps, prevState) {
        if (
            this.state.isNewRelicReady !== prevState.isNewRelicReady && this.state.hasError
            || (this.state.error !== prevState.error && this.state.isNewRelicReady)
        ) {
            noticeError(this.state.error);
        }

    }

    render() {
        if (this.state.hasError) {
            return (
                <h3>
                    {'An Unexpected error has occurred'}
                </h3>

            );
        }
        return this.props.children;
    }
}

const MAX_NEW_RELIC_CHECK_COUNT = 5;

ErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired,
};

export default ErrorBoundary;
