
const PRIMARY_BUTTON_VARIANTS = {
    BLUEBERRY_DARK: 'blueberry-dark',
    MINT_DARK: 'mint-dark',
    COCONUT: 'coconut',
};

export {
    // eslint-disable-next-line import/prefer-default-export
    PRIMARY_BUTTON_VARIANTS,
};
