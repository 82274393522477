export default {
    'journey.contact' : {
        'model': 'Contact', // registered in common/v1/definition/PropagationModels
        'profileSync': {
            'toProfile' : {
                'enable': true,
                'overwrite': true,
                // ok to propagate homeAddress to profiles because it is complete address
                'properties': [ 'firstName', 'lastName', 'phone', 'email', 'dob', 'homeAddress' ],
            },
            'fromProfile' : {
                'enable': true,
                // if doc already has, do not overwrite the value,
                // this option applies to each field, not model itself
                // ex) if firstName already exists, do not overwrite
                // ex) if lastName does not exist, write
                'overwrite': false,
                // note that homeAddress is not included in properties because profiles address might be incomplete
                'properties': [ 'firstName', 'lastName', 'phone', 'email', 'dob' ],
            },
            'profilePath': '$', // $ represent root of profile object
        },
    },
};
