export default {
    // ID rendered on outer-most primary nav container (<header> element)
    HEADER_CONTAINER_ID: 'headerContainer',

    // ID rendered on hamburger button used in primary navigation
    PRIMARY_NAV_HAMBURGER_ID: 'primaryNavHamburgerId',

    // ID rendered on leaderboard ad Portal shown above Header
    PORTAL_ID_LEADERBOARD_AD: 'portalLeaderboardAd',

    // Header Portal IDs
    HEADER_PORTAL_ID_CENTER: 'headerPortalCenter',
    HEADER_PORTAL_ID_LEFT: 'headerPortalLeft',
    HEADER_PORTAL_ID_RIGHT: 'headerPortalRight',

    HEADER_LOGO_FULL_CLASSNAME: 'headerLogoFull',
    HEADER_LOGO_LETTERMARK_CLASSNAME: 'headerLogoLettermark',

    HEADER_LANGUAGE_LINK_CLASS: 'languageAnchor',
    HEADER_LANGUAGE_LINK_HIDEABLE_CLASS: 'hide-language-link',

    // Added to language re-direct links to allow scripts to target them
    LANGUAGE_LINK_CLASS: 'staticLanguageLink',

    // Element key which has the calculator step number. Used to enable auto-scrolling.
    CALCULATOR_STEP_KEY: 'data-calculator-step',

    // Element used by the StickyBannerTrigger component to render the banner
    STICKY_BANNER_PORTAL_ID: 'sticky-banner-portal',
};
