import Cookies from 'js-cookie';
import qs from 'query-string';

import { VISITOR_ID_OVERRIDE_QUERY_PARAM_KEY } from '../definitions/AffiliateQueryParamKeys';


export const COOKIE_NAME = 'visitor_id';

/**
 * Read the "unique" identifier cookie value for this user.
 * This is written by our cloudflare worker.
 */
export default function getVisitorId() {
    // Cannot get visitor Id on the server
    if (typeof window === 'undefined') {
        return null;
    }

    const value = Cookies.get(COOKIE_NAME);

    // This cookie is assigned by our Cloudflare worker, and SHOULD always be there.
    if (value != null) {
        // Value is a base64 encoded binary string of a 64-bit integer (which is left as a string for ease-of-use).
        // Before being stored in the cookie, it's also supposed to be run through encodeURIComponent.
        // We have however found there are some cases which don't encode it.
        try {
            // NOTE: if the value isn't encoded, decodeURIComponent will just return the input as-is.
            return atob(decodeURIComponent(value));
        } catch (e) {
            // Write a debugging message to help us identify cases it fails
            // eslint-disable-next-line no-console
            console.warn(`Failed to decode ${COOKIE_NAME} cookie w/ value ${value}. ${e.message}`);
        }
    } else {
        // Help us identify cases where the cookie isn't found.
        // eslint-disable-next-line no-console
        console.debug(`${COOKIE_NAME} cookie was not found!`);
    }

    // We failed to read their visitor_id cookie (likely due to Safari cookie restrictions within an iframe, or their cookies are disabled completely).
    // FALLBACK: if they have an aff_sub query param, use that instead.
    const queryParams = qs.parse(window.location.search);

    return queryParams[VISITOR_ID_OVERRIDE_QUERY_PARAM_KEY] ?? null;
}
