import FORMATS from '../definitions/Formats';
import LOCALES from '../definitions/Locales';


const NUMBER_FORMATS = FORMATS.number;

/**
 *
 * @param {unknown} value
 * @param {keyof NUMBER_FORMATS} format
 * @param {import('react-intl').IntlShape} intl
 * @returns
 */
function getFormattedNumber(value, format, intl) {
    const selectedFormat = NUMBER_FORMATS[format];

    // Display empty placeholder if value is not a number, empty (null/undefined), or an invalid number (NaN)
    if (value == null || typeof value !== 'number' || Number.isNaN(value)) {
        return getEmptyPlaceholder(format, intl.locale);
    }

    return intl.formatNumber(value, selectedFormat);
}

/**
 * What to return in the case that the value is not a number.
 *
 * @param {keyof NUMBER_FORMATS} format
 * @param {keyof LOCALES} locale
 * @returns {string}
 */
function getEmptyPlaceholder(format, locale) {
    if (format.startsWith('CAD')) {
        return locale === LOCALES.FRENCH
            ? '– $'
            : '$-';
    }

    if (format.startsWith('percent')) {
        return locale === LOCALES.FRENCH
            ? '- %'
            : '-%';
    }

    // assume decimal (when format is not provided or is another format)
    return '–';
}
export { getEmptyPlaceholder as getEmptyNumberPlaceholder };
export default getFormattedNumber;