import Config from './Config';


export default {
    expires: 365,
    path: '/',
    domain: Config.COOKIE_DOMAIN, // Used to have a '.' at the start - leading dots in domain names (.example.com) are ignored https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
    sameSite: 'none',
    secure: true,
};
