import { PRIMARY_BUTTON_VARIANTS } from './ButtonVariants';

// @TODO: Alternate Button classes can be defined here too when we convert
// those to atomic.

const PRIMARY_BUTTON_CLASSES = {
    [PRIMARY_BUTTON_VARIANTS.BLUEBERRY_DARK]: {
        enabled: [
            'rh-fg-coconut',

            // default bg is off-palette, set in styled components
            'hover--rh-bg-blueberry-dark',
            'active--rh-bg-blueberry-darkest',
            'focus--rh-bg-blueberry-dark',

            // default border-color is off-palette, set in styled components
            'hover--rh-border-color-blueberry-dark',
            'active--rh-border-color-blueberry-darkest',
            'focus--rh-border-color-blueberry-dark',
        ],
        disabled: [
            'rh-fg-stone-darkest',
            'rh-bg-stone',
            'rh-border-color-stone',
        ],
    },
    [PRIMARY_BUTTON_VARIANTS.MINT_DARK]: {
        enabled: [
            'rh-fg-blackberry',

            'rh-bg-mint-dark',
            'hover--rh-bg-mint',
            'focus--rh-bg-mint',
            'active--rh-bg-mint-darkest',

            'rh-border-color-mint-dark',
            'hover--rh-border-color-mint',
            'active--rh-border-color-mint-darkest',
            'focus--rh-border-color-mint',
        ],
        disabled: [
            'rh-fg-blackberry',
            'rh-bg-mint',
            'rh-border-color-mint',
        ],
    },
    [PRIMARY_BUTTON_VARIANTS.COCONUT]: {
        enabled: [
            'rh-fg-blueberry-darkest',

            'rh-bg-coconut',
            'hover--rh-bg-blueberry-lightest',
            'active--rh-bg-blueberry-light',
            'focus--rh-bg-blueberry-lightest',

            'rh-border-color-coconut',
            'hover--rh-border-color-blueberry-lightest',
            'active--rh-border-color-blueberry-light',
            'focus--rh-border-color-blueberry-lightest',
        ],
        disabled: [
            'rh-fg-stone-lightest',
            'rh-bg-stone-darkest',
            'rh-border-color-stone-darkest',
        ],
    },
};

export {
    // eslint-disable-next-line import/prefer-default-export
    PRIMARY_BUTTON_CLASSES,
};
