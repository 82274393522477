

/**
 * @public
 * Compute a numeric value semi-unique to this experiment.
 * @param {Object} experiment 
 * @param {string} experiment.slug
 * @returns {number}
 */
export default function getExperimentHash(experiment) {
    return experiment.slug.split('').reduce(
        (result, char) => result + char.charCodeAt(0), 
        0,
    );
}
