import Cookies from 'js-cookie';

import cookieSettings from '../definitions/CookieSettings';


const CODE_NAME = 'lang';

/**
 * Set user language cookie to a two-letter code
 */
export default function setLanguageCode(lang) {
    Cookies.set(CODE_NAME, lang, cookieSettings);
}
