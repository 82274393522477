import React from 'react';
import PropTypes from 'prop-types';

import Config from '../definitions/Config';
import { SessionStoreContext } from '../hooks/useSessionStore';
import useSessionStoreState from '../hooks/useSessionStoreState';


function SessionStoreProvider({ 
    children,
    shouldFetchOnLoad = Config.ENABLE_SESSION_STORE_PROVIDER, // don't fetch on load if we're not using the provider
}) {
    const store = useSessionStoreState({
        shouldFetchOnLoad,
    });

    return (
        <SessionStoreContext.Provider value={Config.ENABLE_SESSION_STORE_PROVIDER ? store : null}>
            {children}
        </SessionStoreContext.Provider>
    );
}

SessionStoreProvider.propTypes = {
    children: PropTypes.any.isRequired,
    shouldFetchOnLoad: PropTypes.bool,
};

export default SessionStoreProvider;
