let firstPartyCookieStatus = null;

function areFirstPartyCookiesEnabled() {
    if (firstPartyCookieStatus != null) {
        return firstPartyCookieStatus;
    }
    else if (typeof window !== 'undefined') {
        // Try/catch is for the case where document.cookie is undefined (likely due to bots)
        try {
            document.cookie = 'firstparty=test; SameSite=Strict; Secure;';
            firstPartyCookieStatus = document.cookie.indexOf('firstparty') != -1;
            document.cookie = 'firstparty=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        catch (err) {
            // If there's a problem accessing document.cookie, then assume cookies are disabled
            firstPartyCookieStatus = false;
        }

        return firstPartyCookieStatus;
    }
    return null;
}

export default areFirstPartyCookiesEnabled;
