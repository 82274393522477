import { BACKGROUND_COLOUR_CLASS_MAP } from '../definitions/Colours';

/**
* @param {string} bgColour background colour as a hex value

* @returns {string} .rh-bg-* colour utility class
*/
function getBGColourClassName(bgColour) {
    const matchingColourObject = BACKGROUND_COLOUR_CLASS_MAP
        .find(colourObject => colourObject.value === bgColour);

    return matchingColourObject?.className || '';
}

export default getBGColourClassName;
