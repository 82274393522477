import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { desaturate, lighten } from 'polished';

import Colours from '../definitions/Colours';
import { PRIMARY_BUTTON_VARIANTS } from '../definitions/ButtonVariants';
import { PRIMARY_BUTTON_CLASSES } from '../definitions/ButtonClasses';
import MessagePropType from '../definitions/MessagePropType';
import getTokenReplacedURL from '../functions/getTokenReplacedURL';
import renderMessage from '../functions/renderMessage';


function PrimaryAnchor({
    className,
    href,
    message,
    children,
    size = 'large',
    variant = 'blueberry-dark',
    disabled,
    shouldLowerCaseMessage = true,
    ...otherProps
}) {
    const intl = useIntl();

    const computedDisabledVariant = disabled ? 'disabled' : 'enabled';

    return (
        <Container
            className={classNames(
                variant,
                ...DEFAULT_CLASSES,
                ...SIZE_CLASSES[size] ?? [],
                ...PRIMARY_BUTTON_CLASSES[variant][computedDisabledVariant] ?? [],
                {
                    'rh-opacity-0_5 rh-cursor-default': disabled,
                    'rh-opacity-1': variant === 'coconut' && disabled,
                },
                className,
            )}
            href={disabled ? undefined : getTokenReplacedURL(href)}
            size={size}
            variant={variant}
            disabled={disabled}
            aria-disabled={disabled}

            {...renderMessage(message, intl, children, { shouldLowerCaseMessage })}
            {...otherProps}
        />
    );
}

PrimaryAnchor.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    message: MessagePropType,
    children: PropTypes.node,
    size: PropTypes.oneOf([
        'large',
        'medium',
        'small',
    ]),
    variant: PropTypes.oneOf(Object.values(PRIMARY_BUTTON_VARIANTS)),
    disabled: PropTypes.bool,
    shouldLowerCaseMessage: PropTypes.bool,
};


const DEFAULT_CLASSES = [
    'rh-display-flex',
    'rh-width-100p',
    'rh-box-sizing-border-box',

    'rh-border-width-2px',
    'rh-border-style-solid',

    'rh-border-radius-8px',
    'rh-text-decoration-none',
    'rh-text-align-center',
    'rh-outline-none',

    'rh-align-items-center',
    'rh-justify-content-center',
];

const SIZE_CLASSES = {
    'large': [
        'rh-min-height-4',
        'rh-px-1_5',
        'rh-py-0_75',
        'rh-font-size-m',
        'weight-medium',
    ],
    'medium': [
        'rh-min-height-3',
        'rh-px-1_5',
        'rh-py-0_75',
        'rh-font-size-s',
        'weight-medium',
    ],
    'small': [
        'rh-min-height-2_5',
        'rh-px-1',
        'rh-py-0_5',
        'rh-font-size-s',
        'weight-medium',
    ],
};

const TRANSITION_DURATION = '300ms';

const Container = styled.a`
    transition:
        color ${TRANSITION_DURATION},
        background-color ${TRANSITION_DURATION},
        border-color ${TRANSITION_DURATION};

    ${props => !props.disabled && `
        /* Default bg colour & border for this variant is custom, so we need
        to put it here, not in utility classes. */
        &.blueberry-dark:not(:hover, :active, :focus) {
            background-color: ${desaturate(0.299, lighten(0.1647, Colours.BLUEBERRY_DARKEST))};
            border-color: ${desaturate(0.299, lighten(0.1647, Colours.BLUEBERRY_DARKEST))};
        }

        &:active {
            box-shadow: none;
        }
    `}
`;

export default observer(PrimaryAnchor);
