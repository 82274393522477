import { desaturate, lighten } from 'polished';
import Colours from './Colours';


const PRIMARY_STATE_COLOURS = {
    'blueberry-dark': {
        DEFAULT: desaturate(.299, lighten(.1647, Colours.BLUEBERRY_DARKEST)),
        HOVER: Colours.BLUEBERRY_DARK,
        ACTIVE: Colours.BLUEBERRY_DARKEST,
        DISABLED: Colours.STONE,

        FONT: Colours.COCONUT,
        FONT_DISABLED: Colours.STONE_DARKEST,
    },
    'mint-dark': {
        DEFAULT: Colours.MINT_DARK,
        HOVER: Colours.MINT,
        ACTIVE: Colours.MINT_DARKEST,
        DISABLED: Colours.MINT,

        FONT: Colours.BLACKBERRY,
    },
    'coconut': {
        DEFAULT: Colours.COCONUT,
        HOVER: Colours.BLUEBERRY_LIGHTEST,
        ACTIVE: Colours.BLUEBERRY_LIGHT,
        DISABLED: Colours.STONE_DARKEST,

        FONT: Colours.BLUEBERRY_DARKEST,
        FONT_DISABLED: Colours.STONE_LIGHTEST, // has a different font colour when disabled.
    },
};

/* We are currently in a multi-PR process of getting rid of all the code
references to old deprecated variants (blueberry, dark & light). Until that is
complete, this maps uses of those old variants to look like the new, desired
ones.

Please delete when all wordpress-blocks & components using the deprecated
variants have been updated to use variants from PRIMARY_STATE_COLOURS. */
PRIMARY_STATE_COLOURS.blueberry = PRIMARY_STATE_COLOURS['blueberry-dark'];
PRIMARY_STATE_COLOURS.dark = PRIMARY_STATE_COLOURS['blueberry-dark'];
PRIMARY_STATE_COLOURS.light = PRIMARY_STATE_COLOURS.coconut;


/*** SECONDARY buttons and anchors ***/

const SECONDARY_STATE_COLOURS = {
    'dark': {
        DEFAULT: Colours.BLUEBERRY_DARK,
        HOVER: Colours.BLUEBERRY,
        ACTIVE: Colours.BLUEBERRY_DARKEST,
        DISABLED: Colours.STONE,
    },
    'light': {
        DEFAULT: Colours.COCONUT,
        HOVER: Colours.BLUEBERRY,
        ACTIVE: Colours.BLUEBERRY_LIGHT,
        DISABLED: Colours.STONE_DARKEST,
    },
    'blueberry-dark': {
        DEFAULT: Colours.BLUEBERRY_DARK,
        HOVER: Colours.BLUEBERRY_DARKEST,
        ACTIVE: desaturate(0.6, Colours.BLUEBERRY_DARKEST),
        DISABLED: Colours.BLUEBERRY_DARK,
        FONT: Colours.COCONUT,
    },
};

export default {
    PRIMARY: PRIMARY_STATE_COLOURS,
    SECONDARY: SECONDARY_STATE_COLOURS,
};
export {
    PRIMARY_STATE_COLOURS,
    SECONDARY_STATE_COLOURS,
};
