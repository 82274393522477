import { VERSION as INS_AUTO_VERSION, SchemaOptions as INS_AUTO_SCHEMA_OPTIONS } from '@ratehub/documents/ins-auto-document/stable';
import { VERSION as INS_HOME_VERSION, SchemaOptions as INS_HOME_SCHEMA_OPTIONS } from '@ratehub/documents/ins-home-document/stable';
import { VERSION as INS_LIFE_VERSION, SchemaOptions as INS_LIFE_SCHEMA_OPTIONS } from '@ratehub/documents/ins-life-document/stable';
import { VERSION as INS_MOTO_VERSION, SchemaOptions as INS_MOTO_SCHEMA_OPTIONS } from '@ratehub/documents/ins-moto-document/stable';
import { VERSION as INS_CONDO_VERSION, SchemaOptions as INS_CONDO_SCHEMA_OPTIONS } from '@ratehub/documents/ins-condo-document/stable';
import { VERSION as INS_LEAD_FORM_VERSION, SchemaOptions as INS_LEAD_FORM_OPTIONS } from '@ratehub/documents/ins-lead-form-document/stable';
import { VERSION as MTG_JOURNEY_VERSION, SchemaOptions as MTG_JOURNEY_SCHEMA_OPTIONS } from '@ratehub/documents/mtg-journey-document/stable';
import { VERSION as CC_JOURNEY_VERSION, SchemaOptions as CC_JOURNEY_SCHEMA_OPTIONS } from '@ratehub/documents/cc-journey-document/stable';
import { VERSION as DEPOSIT_JOURNEY_VERSION, SchemaOptions as DEPOSIT_JOURNEY_SCHEMA_OPTIONS } from '@ratehub/documents/dep-journey-document/stable';
import { VERSION as LOANS_JOURNEY_VERSION, SchemaOptions as LOANS_JOURNEY_SCHEMA_OPTIONS } from '@ratehub/documents/loans-journey-document/stable';


/**
 * Type:DocPath has 1:1 relationship except 'mtg-application' type
 */
const DOCUMENTS = {
    INSURANCE_AUTO: {
        TYPE: 'ins-auto-application',
        PATH: 'insurance.auto',
        SCHEMA_VERSION: INS_AUTO_VERSION,
        SCHEMA_OPTIONS: INS_AUTO_SCHEMA_OPTIONS,
    },
    INSURANCE_HOME: {
        TYPE: 'ins-home-application',
        PATH: 'insurance.home',
        SCHEMA_VERSION: INS_HOME_VERSION,
        SCHEMA_OPTIONS: INS_HOME_SCHEMA_OPTIONS,
    },
    INSURANCE_LIFE: {
        TYPE: 'ins-life-application',
        PATH: 'insurance.life',
        SCHEMA_VERSION: INS_LIFE_VERSION,
        SCHEMA_OPTIONS: INS_LIFE_SCHEMA_OPTIONS,
    },
    INSURANCE_MOTO: {
        TYPE: 'ins-moto-application',
        PATH: 'insurance.moto',
        SCHEMA_VERSION: INS_MOTO_VERSION,
        SCHEMA_OPTIONS: INS_MOTO_SCHEMA_OPTIONS,
    },
    INSURANCE_CONDO: {
        TYPE: 'ins-condo-application',
        PATH: 'insurance.condo',
        SCHEMA_VERSION: INS_CONDO_VERSION,
        SCHEMA_OPTIONS: INS_CONDO_SCHEMA_OPTIONS,
    },
    INSURANCE_LEAD_FORM: {
        TYPE: 'ins-lead-form',
        PATH: 'insurance.leadForm',
        SCHEMA_VERSION: INS_LEAD_FORM_VERSION,
        SCHEMA_OPTIONS: INS_LEAD_FORM_OPTIONS,
    },
    MORTGAGE_JOURNEY: {
        TYPE: 'mtg-application',
        PATH: 'mortgages.journey',
        SCHEMA_VERSION: MTG_JOURNEY_VERSION,
        SCHEMA_OPTIONS: MTG_JOURNEY_SCHEMA_OPTIONS,
    },
    CREDIT_CARD_JOURNEY: {
        TYPE: 'cc-journey',
        PATH: 'creditCards.journey',
        SCHEMA_VERSION: CC_JOURNEY_VERSION,
        SCHEMA_OPTIONS: CC_JOURNEY_SCHEMA_OPTIONS,
    },
    DEPOSIT_JOURNEY: {
        TYPE: 'dep-journey',
        PATH: 'deposits.journey',
        SCHEMA_VERSION: DEPOSIT_JOURNEY_VERSION,
        SCHEMA_OPTIONS: DEPOSIT_JOURNEY_SCHEMA_OPTIONS,
    },
    LOAN_JOURNEY: {
        TYPE: 'loans-journey',
        PATH: 'loans.journey',
        SCHEMA_VERSION: LOANS_JOURNEY_VERSION,
        SCHEMA_OPTIONS: LOANS_JOURNEY_SCHEMA_OPTIONS,
    },
};

export default DOCUMENTS;
