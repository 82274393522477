
import { Locales } from '@ratehub/base-ui';
import SiteSettings from '../src/definitions/SiteSettings';


// Hard-coded button label strings (intl unavailable in this context)
const LINK_BLOCK_LIST_TOGGLE_BUTTON_LABELS = {
    [Locales.ENGLISH]: {
        more: 'view more',
        less: 'view less',
    },
    [Locales.FRENCH]: {
        more: 'montre plus',
        less: 'montrer moins',
    },
};
const LINK_BLOCK_LIST_VIEW_MORE_TRANSITION_DURATION = 500; // matches default from LinkBlockList

function initializePopularContentMenu() {
    const popularContentMenuContainer = document.querySelector(`#${SiteSettings.POPULAR_CONTENT_CONTAINER_ID}`);

    if (!popularContentMenuContainer) {
        return null;
    }

    if (popularContentMenuContainer) {
        const toggleButtons = popularContentMenuContainer.querySelectorAll(`.${SiteSettings.TOGGLE_BUTTON_LINK_BLOCK_LIST}`);

        toggleButtons.forEach(toggleButton => {
            toggleButton.addEventListener('click', handleViewMoreLess);
        });
    }
}

// Expand / collapse individual Popular Content menu link lists
function handleViewMoreLess(evt) {
    const linkBlockList = evt.target.closest(`.${SiteSettings.LINK_BLOCK_LIST_CONTAINER_CLASSNAME}`);

    if (linkBlockList) {
        const expandableContainer = linkBlockList.querySelector(`.${SiteSettings.LINK_BLOCK_LIST_EXPANDABLE_CONTAINER_CLASSNAME}`);

        if (expandableContainer) {
            // Consider the menu expanded if fade-enter-done is on element. This will not be
            // present on first load, which is fine because that also indicates isExpanded === false
            const isExpanded = expandableContainer.classList.contains('fade-enter-done');

            const locale = getDocumentLocale();

            if (isExpanded) {
                evt.target.innerHTML = LINK_BLOCK_LIST_TOGGLE_BUTTON_LABELS[locale].more;
                fadeOutAndCollapse(expandableContainer);
            } else {
                evt.target.innerHTML = LINK_BLOCK_LIST_TOGGLE_BUTTON_LABELS[locale].less;
                fadeInAndExpand(expandableContainer);
            }
        }
    }
}

function getDocumentLocale() {
    const locale = `${document.documentElement.lang}-CA`;

    return locale === Locales.ENGLISH || locale === Locales.FRENCH
        ? locale
        : Locales.ENGLISH;
}

// fadeOutAndCollapse() and fadeInAndExpand():
// Mimic the className adding/removing behaviour of the CSSTransition library.

function fadeOutAndCollapse(expandableContainer) {
    expandableContainer.classList.remove('fade-enter', 'fade-enter-active', 'fade-enter-done');
    expandableContainer.classList.add('fade-exit');

    setTimeout(() => {
        expandableContainer.classList.add('fade-exit-active');
    }, 50);

    setTimeout(() => {
        expandableContainer.classList.remove('fade-exit', 'fade-exit-active');
        expandableContainer.classList.add('fade-exit-done');
    }, LINK_BLOCK_LIST_VIEW_MORE_TRANSITION_DURATION);
}

function fadeInAndExpand(expandableContainer) {
    expandableContainer.classList.remove('fade-exit', 'fade-exit-active', 'fade-exit-done');
    expandableContainer.classList.add('fade-enter');

    setTimeout(() => {
        expandableContainer.classList.add('fade-enter-active');
    }, 50);

    setTimeout(() => {
        expandableContainer.classList.remove('fade-enter');
        // Would typically remove .fade-enter-active here too but removing
        // it invokes some unwanted display toggling in AnimatedFadeContainer.
        // We'll remove it at the start of fadeOutAndCollapse().
        expandableContainer.classList.add('fade-enter-done');
    }, LINK_BLOCK_LIST_VIEW_MORE_TRANSITION_DURATION);
}

export default initializePopularContentMenu;
