import Config from '../definitions/Config';


const entries = [];
const MAXIMUM_ENTRY_COUNT = 20;


/**
 * Record relevant tracing information. Will be automatically included in any noticeError calls.
 * @param {string} message 
 */
export default function noticeErrorSaveEvent(message, ...additionalValues) {
    // Prefer a simplified message.
    if (Object.keys(additionalValues).length > 0) {
        entries.push({ message, additionalValues });
    } else {
        entries.push(message);
    }

    // Limit to a certain amount of only the most recent entries.
    if (entries.length > MAXIMUM_ENTRY_COUNT) {
        entries.shift();
    }

    // Log to the console if configured to do so.
    if (Config.ENABLE_NOTICE_ERROR_SAVE_EVENT) {
        // eslint-disable-next-line no-console
        console.info(message, additionalValues);
    }
}

/** Get the most recent noticeErrorSaveEvent calls which have been made. */
export function getAllEvents() {
    return entries;
}
