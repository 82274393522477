import _merge from 'lodash.merge';
import _set from 'lodash.set';
import _get from 'lodash.get';
import {
    getSnapshot,
    getType,

    typecheck,

    applySnapshot,
} from 'mobx-state-tree';


/**
 * Update MST model using partial deep updates.
 * 
 * step 1: create clone for objects which we want to mutate
 * step 2: convert undefined to null
 * step 3: clear all such arrays which we want to update
 * step 4: call lodash's merge to create the new snapshot object
 * step 5: convert all undefined and null values to right type as per model schema (revert step 2 where needed)
 * step 6: apply snapshot to model
 * 
 * @param {*} model 
 * @param {*} values
 */
function partiallyUpdateModel(model, values) {
    // Since we're going to mutate these, we need to make a copy (to avoid mutating the callers object)
    // NOTE: getSnapshot returns a read-only copy of state; clone it into a mutable copy.
    const copyOfValues = JSON.parse(JSON.stringify(values));
    const snapshotOfModel = JSON.parse(JSON.stringify(getSnapshot(model)));

    // Properties that resolve to undefined will be skipped when using lodash's merge
    // so to overcome this we're converting all undefined to null
    getPathsWithValue(values, (value) => value === undefined).forEach(path => {
        _set(copyOfValues, path, null);
    });

    // For each entry which is being explicitly set to be an array, clear the model snapshot.
    // REASON: lodash.merge will COMBINE the arrays; we want to SET the array to our change value.
    getPathsWithValue(values, (value) => Array.isArray(value)).forEach(path => {
        if (Array.isArray(_get(snapshotOfModel, path))) {
            _set(snapshotOfModel, path, []);
        }
    });

    return performMerge(snapshotOfModel, copyOfValues, model);
}

function performMerge(snapshot, changes, model) {
    // Use _.merge to create a new snapshot, created by applying the changes onto the snapshot.
    const newSnapshot = _merge({}, snapshot, changes);

    // We want to traverse the schema; some model nodes may be currently empty.
    const schema = getType(model);

    // For every entry which is being emptied (null or undefined), verify it's the right one.
    getPathsWithValue(newSnapshot, (value) => value == null).forEach(path => {
        const field = getPathIntoMobxStateTreeType(schema, path);

        // Cannot find the schema; guess it's probably OK?
        // This tends to happen when we're passed changes which aren't actually part of the model, which get ignored by MST.
        if (field == null) {
            console.error(`[partiallyUpdateModel.performMerge] Could not find "${path}" in the schema`);
            return;
        }

        // Swap any incorrect empty values.
        if (!tryTypeCheck(field, null) && tryTypeCheck(field, undefined)) {
            _set(newSnapshot, path, undefined);
        }
        else if (!tryTypeCheck(field, undefined) && tryTypeCheck(field, null)) {
            _set(newSnapshot, path, null);
        }
    });

    return applySnapshot(model, newSnapshot);
}

/**
 * @private
 * Recursively scan an object, and get all paths with a given value
 * @param {any} entry
 * @param {function} checkFn lambda to check for a match
 * @param {string} pathPrefix The path to prefix any entries with
 * @returns {[string]}
 */
function getPathsWithValue(entry, checkFn, pathPrefix = '') {
    if (checkFn(entry)) {
        return [ pathPrefix ];
    }
    else if (Array.isArray(entry)) {
        // Check each entry in the array.
        return entry.reduce((matchingPaths, current, index) => {
            const pathToIndex = `${pathPrefix}[${index}]`;

            matchingPaths.push(...getPathsWithValue(current, checkFn, pathToIndex));
            return matchingPaths;
        }, []);
    }
    else if (entry != null && typeof entry === 'object') {
        // Check each key of the object.
        return Object.entries(entry).reduce((matchingPaths, [ key, current ]) => {
            const pathToKey = `${pathPrefix}${pathPrefix.length > 0 ? '.' : ''}${key}`;

            matchingPaths.push(...getPathsWithValue(current, checkFn, pathToKey));
            return matchingPaths;
        }, []);
    }
    else {
        return [];
    }
}

/**
 * @private
 * Given a mobx-state-tree type, get the type for a path into that type.
 * @param {object} schema mobx-state-tree type definition
 * @param {string} path relative path from the root of the type to retrieve
 * @returns {object|null} 
 */
function getPathIntoMobxStateTreeType(schema, path) {
    const [ key, ...remaining ] = path.split('.');

    // Locate this key.
    // NOTE: remove any indicies; we're traversing the TYPE, not instances of
    const field = getPropertyFromType(schema, getKeyWithoutIndexer(key));

    // Have we reached the destination?
    if (remaining.length === 0) {
        return field;
    }

    // Need to move down a level.
    return field
        ? getPathIntoMobxStateTreeType(field, remaining.join('.'))
        : null;
}

/**
 * @private
 * Retrieve the MST type definition for a key of a model type
 * @param {object} type MST type definition to retrieve the underlying property type from 
 * @param {string} key
 * @return {object}
 */
function getPropertyFromType(type, key) {
    // Check their top-level properties for the key
    if (type.properties && key in type.properties) {
        return type.properties[key];
    }

    // For wrapped types (types.optional, types.snapshotProcessor, etc), we have to check their subtypes.
    const subtypes = type.getSubTypes();

    // Typically these are types.union and types.optional.
    if (Array.isArray(subtypes)) {
        // Find the first match within ANY of their subtypes.
        // NOTE: this approach could be problematic if we use a union of a model with the same key of different types...
        for (const subtype of subtypes) {
            const found = getPropertyFromType(subtype, key);
            if (found) {
                return found;
            }
        }
    }

    else if (subtypes != null && typeof subtypes === 'object') {
        // Single entry; search it.
        return getPropertyFromType(subtypes, key);
    }

    // ISSUE: for some reason, types.array will always have .getSubTypes() be empty...
    // WORKAROUND: manually inspect the subtype object.
    // NOTE: the single case above is still needed. It fails otherwise?
    else if (type._subType != null && typeof type._subType === 'object') {
        // ASSUMPTION: if the key is a number, they're wanting to update a specific element of this array.
        // Just return the sub-type of the types.array
        // Example: if the type is types.array(SomeModel), we want to return SomeModel.
        if (Number.isInteger(Number(key))) {
            return type._subType;
        }

        // Recurse on their sub-type
        return getPropertyFromType(type._subType, key);
    }

    // Unsure what to do.
    return null;
}

/**
 * @private
 * Remove any indexers from a key
 * @param {string} value 
 * @return {string}
 */
function getKeyWithoutIndexer(value) {
    return value.split([ '[' ])[0];
}

/**
 * @private
 * A wrapper around the mobx-state-tree typeCheck function to return a boolean instead of failing by throw
 */
function tryTypeCheck(type, value) {
    try {
        typecheck(type, value);
        return true;
    } catch {
        return false;
    }
}

export default partiallyUpdateModel;
