import { observable, toJS, when } from 'mobx';
import { computedFn } from 'mobx-utils';

import calculateExperimentSegment from '../functions/calculateExperimentSegment';
import ExperimentSegments from '../definitions/ExperimentSegments';
import Config from '../definitions/Config';


const Experiments = observable.map();
const ExperimentDefinitions = observable.map();

// **** SPECIAL REQUEST FROM QA/PM *****
// REQUIREMENT: a way to programmatically retrieve how they've been segmented.
// SOLUTION: mount the map to the window; they can then inspect it via the console.
if (typeof window !== 'undefined') {
    Object.defineProperty(window, '__rhExperiments', {
        get: () => {
            return Array.from(Experiments.keys()).map(slug => ({
                slug,
                segment: Experiments.get(slug),
                ...getExperimentDefinition(slug),
            }));
        },
    });
}

/**
 * Get an experiment segment. Will be cached to avoid unnecessary computations.
 *
 * @param {(Object|string)} experiment
 * @returns {number|null} segment
 */
const getExperimentSegment = computedFn(experiment => {
    // SPECIAL CASE: if experiments are disabled, always return control.
    if (!Config.ENABLE_EXPERIMENTS) {
        return ExperimentSegments.CONTROL;
    }

    // because there may be an externally added segment override not present in the arg, we always
    // call getExperimentDefinition() to make sure the overrides are involved when resolving the experiment
    if (typeof experiment === 'string') {
        experiment = getExperimentDefinition(experiment);

        if (!experiment) {
            // We don't have enough to make an assignment
            return null;
        }
    } else {
        experiment = getExperimentDefinition(experiment.slug) ?? experiment;
    }

    // Cache definition to enable retrieving by slug
    if (!ExperimentDefinitions.has(experiment.slug)) {
        defineExperiment(experiment);
    }

    // Return cached experiment rather than re-calculating
    if (Experiments.has(experiment.slug)) {
        return Experiments.get(experiment.slug);
    }

    // Lazily set experiment so that both client and server return `null` on first invocation
    // Otherwise the client and server will render differently based on the experiment segment
    Promise.resolve(calculateExperimentSegment(experiment)).then(segment =>
        segment !== null ? Experiments.set(experiment.slug, segment) : null,
    );

    return null;
});

/**
 * Allows you to `await` for segments. Probably not needed but TBD if this is deprecated.
 *
 * @param {(Object|string)} experiment
 * @returns {Promise<number>} segment
 */
async function fetchExperimentSegment(experiment) {
    let assigned = null;

    // Wait until we have a segment assigned
    await when(() => {
        assigned = getExperimentSegment(experiment);
        return assigned != null;
    });

    return assigned;
}

/**
 * More or less fetchExperimentSegment but synchronous
 *
 * @param {(Object|string)} experiment
 * @returns {number|null} segment
 */
function getExperimentSegmentSynchronously(experiment) {
    // SPECIAL CASE: if experiments are disabled, always return control.
    if (!Config.ENABLE_EXPERIMENTS) {
        return ExperimentSegments.CONTROL;
    }

    // because there may be an externally added segment override not present in the arg, we always
    // call getExperimentDefinition() to make sure the overrides are involved when resolving the experiment
    if (typeof experiment === 'string') {
        experiment = getExperimentDefinition(experiment);

        if (!experiment) {
            // We don't have enough to make an assignment
            return null;
        }
    } else {
        experiment = getExperimentDefinition(experiment.slug) ?? experiment;
    }

    // Cache definition to enable retrieving by slug
    if (!ExperimentDefinitions.has(experiment.slug)) {
        defineExperiment(experiment);
    }

    // Return cached experiment rather than re-calculating
    if (Experiments.has(experiment.slug)) {
        return Experiments.get(experiment.slug);
    }

    const segment = calculateExperimentSegment(experiment);

    // Only write valid segment assignments to our cache.
    if (segment != null) {
        Experiments.set(experiment.slug, segment);
    }

    return segment;
}

/**
 * Add a new experiment to our list
 * @param {Object} experiment
 * @param {string} experiment.slug
 * @param {number} experiment.segmentOverride
 */
function defineExperiment(experiment) {
    ExperimentDefinitions.set(experiment.slug, experiment);
}

/**
 * Retrieve the definition for an experiment
 * @param {string} slug
 * @returns {Object}
 */
function getExperimentDefinition(slug) {
    return ExperimentDefinitions.has(slug) ? toJS(ExperimentDefinitions.get(slug)) : null;
}

/**
 * @todo rename this to setExperimentSegment
 * Assign a segment to an experiment
 * @param {(Object|string)} experiment
 * @param {number} segment
 */
function setTestExperiment(experiment, segment) {
    const slug = typeof experiment === 'string' ? experiment : experiment.slug;

    Experiments.set(slug, segment);
}

export {
    getExperimentDefinition,
    getExperimentSegment,
    fetchExperimentSegment,
    getExperimentSegmentSynchronously,
    Experiments,
    ExperimentDefinitions, // Exported for tests
    defineExperiment,
    setTestExperiment,
};
